import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import {
  BbButton, BbNav, BbNavItem, BbPane, BbPaneGroup,
} from '@browsbox-ui';
import {
  isVideoUrl, CONTENT_COMPONENT_TYPE, getLinkType, completeUrl,
} from '@liswood-tache/browsbox-static';
import MediaManagerContainer from './MediaManagerContainer';
import ModalFooter from '../Modals/ModalFooter';
import ModalHeader from '../Modals/ModalHeader';
import MediaPickerImage from './MediaPickerImage';
import MediaPickerVideo from './MediaPickerVideo';
import { cropMedia, getMedia } from '../../actions/media';
import MEDIA_TYPES from './MediaTypes';
import { convertUriToHyperlink, validate as isValidLink } from '../Modals/LinkTools';
import { LINK_TYPE_EXTERNAL } from '../Modals/Links';
import i18n from '../../internationalization/i18n';
import Modal from '../Modals/Modal';

export const mediaDefaultProps = {
  id: false,
  url: '',
  alt: '',
  tooltip: '',
  hyperlink: '',
};

const propTypes = {
  contentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]), // Section data
  contentMedia: PropTypes.object, // Section data about selected image/video
  icon: PropTypes.string, // modal property
  onCancel: PropTypes.func.isRequired, // modal property
  onClose: PropTypes.func.isRequired, // modal property
  onOk: PropTypes.func.isRequired, // modal property
  submitButtonText: PropTypes.string, // modal property
  title: PropTypes.string, // modal property
  url: PropTypes.string, // Media data
  alt: PropTypes.string, // Media data
  hyperlink: PropTypes.string, // Media data
  tooltip: PropTypes.string, // Media data
  type: PropTypes.oneOf(Object.values(MEDIA_TYPES)), // Media data
  getMedia: PropTypes.func.isRequired, // Redux action creator
  mediaManagerContentType: PropTypes.string.isRequired, // Filter "media manager" types
  showMetaData: PropTypes.bool, // Filter "media manager" types
  showMenuItems: PropTypes.bool, // Filter "media manager" types
};

const defaultProps = {
  type: MEDIA_TYPES.image,
  contentId: null,
  contentMedia: {},
  icon: 'fas fa-images',
  submitButtonText: null,
  title: null,
  showMetaData: false,
  showMenuItems: true,
  ...mediaDefaultProps,
};

const bbPanelHeaderStyles = {
  position: 'inherit',
};

class MediaPickerModal extends Component {
  static handleError(error) {
    const message = get(error, 'response.data.message', 'File upload error');
    const errorCode = get(error, 'response.data.code', '');
    window.alert(`${errorCode} ${message}`); // eslint-disable-line no-alert
  }

  static mediaIdFromMediaUrl(url) {
    if (!url) { return false; }
    const value = url.split('/');
    if (value.length > 2) {
      const idNumber = parseInt(value.slice(-2, -1), 10);
      return Number.isNaN(idNumber) ? false : idNumber;
    }
    return false;
  }

  static onItemClick(evt) {
    evt.preventDefault();
  }

  static getMenuItems() {
    return [
      { id: MEDIA_TYPES.image, name: i18n.t('CONTENT.image'), icon: '#icon-menu-image' },
      { id: MEDIA_TYPES.video, name: i18n.t('CONTENT.video'), icon: '#icon-menu-video' },
    ];
  }

  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onDelete = this.onDelete.bind(this);
    this.showMediaManager = this.showMediaManager.bind(this);
    this.onMediaManagerSelect = this.onMediaManagerSelect.bind(this);
    this.onMediaChange = this.onMediaChange.bind(this);
    this.setActiveMenuItem = this.setActiveMenuItem.bind(this);
    this.validate = this.validate.bind(this);
    this.getTitle = this.getTitle.bind(this);

    const {
      url, alt, tooltip, type, hyperlink,
    } = props;
    const isVideo = isVideoUrl(url);
    const id = isVideo ? false : MediaPickerModal.mediaIdFromMediaUrl(url);
    const hyperlinkType = getLinkType(hyperlink);

    this.state = {
      hasSelection: url !== '', // media selection is changed
      hasChange: false, // tooltip or alt is changed
      showMediaManager: false,
      id,
      imageUrl: isVideo ? '' : url,
      alt,
      tooltip,
      hyperlink,
      hyperlinkType,
      hyperlinkValidationMessage: '',
      type,
      activeMenuItem: isVideo ? MEDIA_TYPES.video : MEDIA_TYPES.image,
      videoUrl: isVideo ? url : '',
    };
  }

  componentDidMount() {
    const { id } = this.state;
    if (typeof id === 'number') {
      this.props.getMedia({ id });
    }
  }

  // Handle image value changes ( tooltip, alt, ... )
  // The name of the input element should match a state property
  onMediaChange(name, value) {
    if (name === 'videoUrl') {
      const isValidVideoUrl = isVideoUrl(value);
      this.setState({
        [name]: value,
        hasChange: isValidVideoUrl,
        hasSelection: isValidVideoUrl,
      });
    } else {
      this.setState({
        [name]: value,
        hasChange: true,
      });
    }
  }

  onMediaManagerSelect(mediaItem) {
    const { contentMedia } = this.props;
    const isRequestingLarge = [CONTENT_COMPONENT_TYPE.sliderImage, 'section'].includes(contentMedia?.type);
    const imageUrl = isRequestingLarge ? mediaItem.urlLarge : mediaItem.url;

    this.setState({
      showMediaManager: false,
      hasSelection: true,
      id: mediaItem.id,
      imageUrl,
    });
  }

  onDelete() {
    this.setState({
      hasSelection: true,
      id: false,
      imageUrl: '',
      tooltip: '',
      alt: '',
    });
  }

  onOk() {
    this.validate()
      .then(() => {
        const {
          contentId,
        } = this.props;
        const {
          imageUrl,
          videoUrl,
          id,
          alt,
          tooltip,
          type,
          activeMenuItem,
          hyperlink,
          hyperlinkType,
        } = this.state;
        const mediaItem = {
          mediaId: id,
          url: activeMenuItem === MEDIA_TYPES.video ? videoUrl : imageUrl,
          alt,
          tooltip,
          type,
          hyperlink: convertUriToHyperlink(hyperlink, hyperlinkType),
        };
        this.props.onOk(contentId, mediaItem);
      });
  }

  onCancel(evt) {
    if (evt) {
      evt.preventDefault();
    }
    if (this.state.showMediaManager) {
      this.setState({ showMediaManager: false });
    } else {
      this.props.onCancel();
    }
  }

  onClose(evt) {
    if (evt) {
      evt.preventDefault();
    }
    if (this.state.showMediaManager) {
      this.setState({ showMediaManager: false });
    } else {
      this.props.onClose();
    }
  }

  setActiveMenuItem(item) {
    if (item === MEDIA_TYPES.video) {
      this.setState((prev) => {
        const isValidVideoUrl = isVideoUrl(prev.videoUrl);
        return {
          hasChange: isValidVideoUrl,
          hasSelection: isValidVideoUrl,
        };
      });
    }

    this.setState((prev) => ({
      hasSelection: prev.imageUrl !== '',
      activeMenuItem: item,
    }));
  }

  getTitle() {
    return this.props.title || i18n.t('CONTENT.addMedia');
  }

  validate() {
    return new Promise((resolve, reject) => {
      const {
        hyperlink,
        hyperlinkType,
      } = this.state;

      if (hyperlink) {
        const link = { type: hyperlinkType, value: hyperlink };
        if (link.type === LINK_TYPE_EXTERNAL && isValidLink(link) !== true) {
          this.setState({
            hyperlinkValidationMessage: isValidLink(link),
          }, reject);
        } else if (link.type === LINK_TYPE_EXTERNAL) {
          this.setState({
            hyperlink: completeUrl(link.value),
          }, resolve);
        } else {
          resolve();
        }
      } else {
        resolve();
      }
    });
  }

  showMediaManager() {
    this.setState({ showMediaManager: true });
  }

  renderMediaManager() {
    const {
      icon,
    } = this.props;
    const {
      id,
      imageUrl,
    } = this.state;

    const title = i18n.t('CONTENT.manageFiles');

    return (
      <Modal
        onClose={this.onClose}
        closeOnEsc={false}
        closeOnBackdropClick={false}
        modalClassName="o-bb-modal--large o-bb-modal--mediamanager"
      >
        <ModalHeader icon={icon} title={title} />
        <MediaManagerContainer
          onCancel={this.onCancel}
          onOk={this.onMediaManagerSelect}
          submitButtonText={i18n.t('CONTENT.insert')}
          mediaUrl={imageUrl}
          mediaId={id}
        />
      </Modal>
    );
  }

  renderMediaPanel(menuItem) {
    const {
      imageUrl,
      alt,
      tooltip,
      videoUrl,
      hyperlink,
      hyperlinkType,
      hyperlinkValidationMessage,
    } = this.state;
    const {
      contentMedia,
      contentId,
    } = this.props;

    if (menuItem.id === MEDIA_TYPES.video) {
      return (
        <MediaPickerVideo
          onChange={this.onMediaChange}
          url={videoUrl}
        />
      );
    }

    // contentMedia is the content section or content component requesting the media picker,
    // not the type of media we want.
    const showMetaData = [
      CONTENT_COMPONENT_TYPE.image,
      CONTENT_COMPONENT_TYPE.sliderImage,
      CONTENT_COMPONENT_TYPE.galleryImage,
    ].indexOf(contentMedia?.type) !== -1 && contentId !== null;
    const canDelete = ['section', 'column'].includes(contentMedia?.type);

    const hideMetaLinks = [
      CONTENT_COMPONENT_TYPE.sliderImage,
    ].indexOf(contentMedia?.type) !== -1;

    return (
      <MediaPickerImage
        onChange={this.onMediaChange}
        onDelete={this.onDelete}
        onUpload={this.showMediaManager}
        url={imageUrl}
        alt={alt}
        tooltip={tooltip}
        hyperlink={hyperlink}
        hyperlinkType={hyperlinkType}
        showMetaData={this.props.showMetaData || showMetaData}
        showMetaLinks={!hideMetaLinks}
        hyperlinkValidationMessage={hyperlinkValidationMessage}
        canDelete={canDelete}
      />
    );
  }

  renderMediaPicker() {
    const {
      icon,
      contentMedia,
      mediaManagerContentType,
      showMenuItems,
    } = this.props;
    const {
      hasSelection,
      hasChange,
      activeMenuItem,
    } = this.state;

    const title = this.getTitle();
    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.save');
    const isMediaForSection = ['section', 'column'].includes(contentMedia?.type);
    const menuItems = MediaPickerModal.getMenuItems();
    const visibleMenuItems = menuItems.filter((menuItem) => {
      if (menuItem.id === MEDIA_TYPES.video) {
        const imageType = mediaManagerContentType || CONTENT_COMPONENT_TYPE.image;
        const disabledVideos = [
          CONTENT_COMPONENT_TYPE.sliderImage,
          CONTENT_COMPONENT_TYPE.galleryImage,
        ];

        return !isMediaForSection && !disabledVideos.includes(imageType);
      }

      return !isMediaForSection || (isMediaForSection && menuItem.id !== MEDIA_TYPES.video);
    });

    return (
      <Modal
        onClose={this.onClose}
        closeOnEsc={false}
        closeOnBackdropClick={false}
        modalClassName="o-bb-modal o-bb-modal-mediapicker"
      >
        <ModalHeader icon={icon} title={title} />
        <div className="o-bb-modal__content">
          {showMenuItems && visibleMenuItems.length > 1 && (
            <div className="c-bb-media-manager__panel" style={bbPanelHeaderStyles}>
              <BbNav>
                {visibleMenuItems.map(menuItem => (
                  <BbNavItem
                    key={menuItem.id}
                    active={menuItem.id === activeMenuItem}
                    onClick={() => this.setActiveMenuItem(menuItem.id)}
                  >
                    {menuItem.name}
                  </BbNavItem>
                ))}
              </BbNav>
            </div>
          )}
          <BbPaneGroup className="bb-panel__content" style={bbPanelHeaderStyles}>
            {visibleMenuItems.map(menuItem => (
              <BbPane
                key={menuItem.id}
                active={menuItem.id === activeMenuItem}
              >
                {this.renderMediaPanel(menuItem)}
              </BbPane>
            ))}
          </BbPaneGroup>
        </div>
        <Modal.Context>
          {({ closeModal }) => (
            <ModalFooter>
              <BbButton className="c-bb-button--primary" disabled={!hasSelection && !hasChange} onClick={this.onOk} text={submitButtonText} />
              <BbButton className="c-bb-button--secondary" onClick={closeModal} text={i18n.t('CONTENT.cancel')} />
            </ModalFooter>
          )}
        </Modal.Context>
      </Modal>
    );
  }

  render() {
    const {
      showMediaManager,
    } = this.state;

    if (showMediaManager) {
      return this.renderMediaManager();
    }

    return this.renderMediaPicker();
  }
}

MediaPickerModal.propTypes = propTypes;
MediaPickerModal.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const {
    mediaManagerContentType,
  } = state.content;

  return {
    mediaManagerContentType: mediaManagerContentType || ownProps.mediaManagerContentType,
  };
};

const mapDispatchToProps = {
  cropMedia,
  getMedia,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(MediaPickerModal);
