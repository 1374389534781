import React, { Component } from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import flatten from 'lodash/flatten';
import map from 'lodash/map';
import { contentPropType, CONTENT_COMPONENT_TYPE } from '@liswood-tache/browsbox-static';
import BrowsboxContentList from './ContentList';
import { deleteContent, deleteComponent } from '../../actions/contentDelete';
import {
  setContentOption,
  setColumnOption,
  clearContentOption,
  clearColumnOption,
  setComponentOption,
  clearComponentOption,
} from '../../actions/contentConfig';
import { loadContent, closeContentModals } from '../../actions/content';
import { addComponent } from '../../actions/contentAdd';
import { closeMediaManager } from '../../actions/mediaManager';
import ConfirmationModal from '../Modals/ConfirmationModal';
import ContentLayoutModal from './ContentLayoutModal';
import MediaPickerModal, { mediaDefaultProps } from '../MediaManager/MediaPickerModal';
import MEDIA_TYPES from '../MediaManager/MediaTypes';
import MediaManagerModal from '../MediaManager/MediaManagerModal';
import { getComponentById } from '../../utils/entities';
import SourceEditor from '../Editor/SourceEditor';
import GoogleMapsEditorModal from '../Modals/GoogleMapsEditorModal';
import LinkPickerModal from '../Modals/LinkPickerModal';
import { closeLinkPicker } from '../../actions/links';
import {
  saveCssEditorContent,
  saveJsEditorContent,
  openCodeEditorSaveConfirmation,
  closeCodeEditorSaveConfirmation,
} from '../../actions/codeEditor';
import { getCurrentPage } from '../../actions/pages';
import i18n from '../../internationalization/i18n';
import { isStaticPage } from '../../tools/page-utils';
import PromptModal from '../Modals/PromptModal';
import {
  closeRemoveSavedSection,
  closeRenameSavedSection, closeSaveSection, removeSavedSection, renameSavedSection, saveSection,
} from '../../actions/savedSections';
import Spinner from '../Spinner';
import FooterContainer from './Footer/FooterContainer';

const propTypes = {
  addComponent: PropTypes.func.isRequired,
  clearComponentOption: PropTypes.func.isRequired,
  clearContentOption: PropTypes.func.isRequired,
  clearColumnOption: PropTypes.func.isRequired,
  closeContentModals: PropTypes.func.isRequired,
  closeLinkPicker: PropTypes.func.isRequired,
  closeMediaManager: PropTypes.func.isRequired,
  acceptableMainBlockTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  acceptableFooterBlockTypes: PropTypes.arrayOf(PropTypes.string).isRequired,
  content: PropTypes.arrayOf(contentPropType).isRequired,
  contentEditorContentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  deleteComponent: PropTypes.func.isRequired,
  deleteContent: PropTypes.func.isRequired,
  deleteContentProps: PropTypes.oneOfType([PropTypes.bool, PropTypes.object]),
  googleMapsEditorContentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  googleMapsEditorComponentId: PropTypes.PropTypes.number,
  headerHtml: PropTypes.string.isRequired,
  isContentEditorOpen: PropTypes.bool,
  isContentLayoutModalOpen: PropTypes.bool,
  isDeleteContentModalOpen: PropTypes.bool,
  isSaveSectionModalOpen: PropTypes.bool,
  isRenameSavedSectionModalOpen: PropTypes.bool,
  isRemoveSavedSectionModalOpen: PropTypes.bool,
  isGoogleMapsModalOpen: PropTypes.bool,
  isLinkPickerModalOpen: PropTypes.bool.isRequired,
  isMediaManagerModalOpen: PropTypes.bool,
  isMediaPickerModalOpen: PropTypes.bool,
  isCodeEditorConfirmModalOpen: PropTypes.bool.isRequired,
  saveSectionProps: PropTypes.object,
  renameSavedSectionProps: PropTypes.object,
  removeSavedSectionProps: PropTypes.object,
  layoutModalContentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  linkPickerValue: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]).isRequired,
  loadContent: PropTypes.func.isRequired,
  mediaManagerContentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  mediaPickerContentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]),
  mediaPickerMedia: PropTypes.object,
  pageid: PropTypes.number.isRequired,
  setComponentOption: PropTypes.func.isRequired,
  setContentOption: PropTypes.func.isRequired,
  setColumnOption: PropTypes.func.isRequired,
  isCodeEditorOpen: PropTypes.bool,
  codeEditorLanguage: PropTypes.string,
  codeEditorContent: PropTypes.string,
  saveCssEditorContent: PropTypes.func.isRequired,
  saveJsEditorContent: PropTypes.func.isRequired,
  isAddingContent: PropTypes.bool.isRequired,
  isOpen: PropTypes.bool.isRequired,
  getCurrentPage: PropTypes.func.isRequired,
  openCodeEditorSaveConfirmation: PropTypes.func.isRequired,
  closeCodeEditorSaveConfirmation: PropTypes.func.isRequired,
  closeSaveSection: PropTypes.func.isRequired,
  closeRenameSavedSection: PropTypes.func.isRequired,
  closeRemoveSavedSection: PropTypes.func.isRequired,
  renameSavedSection: PropTypes.func.isRequired,
  removeSavedSection: PropTypes.func.isRequired,
};

const defaultProps = {
  contentEditorContentId: null,
  deleteContentProps: false,
  googleMapsEditorContentId: null,
  googleMapsEditorComponentId: null,
  isContentEditorOpen: false,
  isContentLayoutModalOpen: false,
  isDeleteContentModalOpen: false,
  isSaveSectionModalOpen: false,
  isRenameSavedSectionModalOpen: false,
  isRemoveSavedSectionModalOpen: false,
  isGoogleMapsModalOpen: false,
  isMediaPickerModalOpen: false,
  isMediaManagerModalOpen: false,
  saveSectionProps: null,
  renameSavedSectionProps: null,
  removeSavedSectionProps: null,
  layoutModalContentId: null,
  mediaPickerContentId: null,
  mediaPickerMedia: null,
  mediaManagerContentId: null,
  isCodeEditorOpen: false,
  codeEditorLanguage: null,
  codeEditorContent: null,
  clearCodeEditorContent: null,
};

class BrowsboxContent extends Component {
  static setHeaderAndFooter(header) {
    if (header) {
      const headerElement = window.document.getElementById('pageHeader');
      if (headerElement) {
        headerElement.innerHTML = header;
      }
    }
  }

  /**
   * Could not get `node-sass` to import so used this CDN to compile SASS -> CSS for adding specificity to CSS
   *
   * @param content
   */
  static compileCssContent(content) {
    const contentSass = `.o-content { ${content} }`;

    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = '//cdnjs.cloudflare.com/ajax/libs/less.js/1.7.0/less.min.js';
    script.onload = () => {
      const parser = new (less.Parser); // eslint-disable-line
      return parser.parse(contentSass, (err, tree) => {
        if (err) { return console.error(err); } // eslint-disable-line
        const css = tree.toCSS();
        const customCss = document.getElementById('c-custom-css') || (() => {
          const element = document.createElement('style');
          element.id = 'c-custom-css';
          document.head.appendChild(element);

          return element;
        })();

        if (customCss) {
          customCss.innerText = css;
        } else {
          console.warn('Can not find element #c-custom-css for append css: ', css); // eslint-disable-line
        }
        return true;
      });
    };

    document.head.appendChild(script);
  }

  constructor(props) {
    super(props);
    this.onDeleteConfirmation = this.onDeleteConfirmation.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.onLayoutChange = this.onLayoutChange.bind(this);
    this.onGoogleMapsChange = this.onGoogleMapsChange.bind(this);
    this.onLinkChange = this.onLinkChange.bind(this);
    this.onMediaPickerChange = this.onMediaPickerChange.bind(this);
    this.onMediaManagerChange = this.onMediaManagerChange.bind(this);
    this.onSourceEditorChange = this.onSourceEditorChange.bind(this);
    this.onCodeEditorChange = this.onCodeEditorChange.bind(this);
    this.saveCodeEditorChange = this.saveCodeEditorChange.bind(this);
    this.saveSectionChange = this.saveSectionChange.bind(this);
    this.renameSavedSectionChange = this.renameSavedSectionChange.bind(this);
    this.removeSavedSectionChange = this.removeSavedSectionChange.bind(this);
    this.closeCodeEditorConfirmation = this.closeCodeEditorConfirmation.bind(this);
    this.closeSaveSectionModal = this.closeSaveSectionModal.bind(this);
    this.closeRenameSavedSectionModal = this.closeRenameSavedSectionModal.bind(this);
    this.closeRemoveSavedSectionModal = this.closeRemoveSavedSectionModal.bind(this);
    // contentComponentUpdated is used to fix BrowsboxContentList not detecting changes
    // to components inside content.columns.components (change is to deep for react
    // to notice changes to).
    this.state = {
      isEmpty: false,
      contentComponentUpdated: false,
    };
  }

  componentDidMount() {
    if (isStaticPage()) {
      return;
    }

    this.props.loadContent(this.props.pageid)
      .then(() => {
        const isEmpty = this.props.content
          .filter(({ type }) => this.props.acceptableMainBlockTypes.includes(type)).length === 0;
        this.setState({ isEmpty });
      });
    this.props.getCurrentPage();
  }

  componentDidUpdate(prevProps, prevState) {
    const isEmpty = this.props.content
      .filter(({ type }) => this.props.acceptableMainBlockTypes.includes(type)).length === 0;
    if (isEmpty !== prevState.isEmpty) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ isEmpty });
    }

    if (this.props.headerHtml !== prevProps.headerHtml) {
      BrowsboxContent.setHeaderAndFooter(this.props.headerHtml);
    }
  }

  onLayoutChange(contentId, value) {
    this.props.setContentOption({
      option: 'layoutClasses',
      value,
      contentId,
    });
    this.closeModal();
  }

  // Put LinkPickerModal result into Redux store
  onLinkChange(value) {
    this.props.closeLinkPicker(value);
  }

  // Update component with values from GoogleMapsEditorModal
  onGoogleMapsChange(contentId, value) {
    Object.keys(value).forEach(option => this.props.setComponentOption({
      option,
      value: value[option],
      contentId,
    }));
    this.setState({ contentComponentUpdated: (new Date()).getTime() });
    this.closeModal();
  }

  // On confirmation of delete section/component modal
  onDeleteConfirmation() {
    const { deleteContentProps } = this.props;
    // Delete section
    if (deleteContentProps.type === 'section') {
      this.props.deleteContent(deleteContentProps);
    // Delete component
    } else {
      this.props.deleteComponent(deleteContentProps);
    }
    this.closeModal();
    this.setState({ contentComponentUpdated: (new Date()).getTime() });
  }

  // Use media picker result to update Section of Section Component options
  onMediaPickerChange(id, mediaItem) {
    const { url } = mediaItem;
    const { mediaPickerMedia } = this.props;
    // Update Section background option
    if (mediaPickerMedia.type === 'section') {
      const option = 'backgroundImage';
      if (url) {
        this.props.setContentOption({
          option,
          value: url,
          contentId: id,
        });
      } else {
        this.props.clearContentOption({
          option: 'backgroundImage',
          contentId: id,
        });
      }
    // Update Column image
    } else if (mediaPickerMedia.type === 'column') {
      const option = 'backgroundImage';

      const { contentId, columnId } = id;

      if (url) {
        this.props.setColumnOption({
          option,
          value: url,
          contentId,
          columnId,
        });
      } else {
        this.props.clearColumnOption({
          option: 'backgroundImage',
          contentId,
          columnId,
        });
      }
    // Update Section Component image
    } else {
      Object.keys(mediaItem).forEach((key) => {
        const value = mediaItem[key];
        if (key === 'type') { return; }
        if (value) {
          this.props.setComponentOption({
            option: key,
            value,
            contentId: id,
          });
        } else {
          this.props.clearComponentOption({
            option: key,
            contentId: id,
          });
        }
      });
      this.setState({ contentComponentUpdated: (new Date()).getTime() });
    }
    this.closeModal();
  }

  onMediaManagerChange(contentId, contentType, mediaItem) {
    this.props.closeMediaManager({ ...mediaItem });
    // Add Image component to Gallery section
    if (['gallery', 'slider'].indexOf(contentType) !== -1) {
      const { url, id } = mediaItem;
      this.props.addComponent({ contentId, contentType, options: { url, mediaId: id } });
    }
  }

  onSourceEditorChange(content, contentId) {
    this.props.setComponentOption({
      option: 'content',
      value: content,
      contentId,
    });
    this.setState({ contentComponentUpdated: (new Date()).getTime() });
  }

  onCodeEditorChange(content) {
    if (this.props.codeEditorLanguage === 'css') {
      this.props.saveCssEditorContent({ value: content })
        .then(BrowsboxContent.compileCssContent(content));
    } else if (this.props.codeEditorLanguage === 'js') {
      this.codeEditorContents = content;
      this.props.openCodeEditorSaveConfirmation();
    }
  }

  // Close LayoutModal if canceled or closed
  closeLayoutModal = () => {
    if (this.props.isAddingContent) {
      this.props.deleteContent({ id: this.props.layoutModalContentId });
    }
    this.props.closeContentModals();
  };

  validateNameNotInUse = (sections, name) => {
    if (sections.some(section => String(section.name).toLowerCase().trim() === String(name).toLowerCase().trim())) {
      return i18n.t('VALIDATION.name_already_taken');
    }

    return null;
  };

  validateNewSectionName = (value) => {
    const { savedSections = {} } = this.props;

    return this.validateNameNotInUse(Object.values(savedSections), value);
  };

  validateUpdatedSectionName = (value) => {
    const { savedSections = {}, renameSavedSectionProps } = this.props;

    return this.validateNameNotInUse(
      Object.values(savedSections).filter(item => item.id !== renameSavedSectionProps.id),
      value,
    );
  };

  closeCodeEditorConfirmation() {
    this.props.closeCodeEditorSaveConfirmation();
  }

  closeSaveSectionModal() {
    this.props.closeSaveSection();
  }

  closeRenameSavedSectionModal() {
    this.props.closeRenameSavedSection();
  }

  closeRemoveSavedSectionModal() {
    this.props.closeRemoveSavedSection();
  }

  closeModal() {
    this.props.closeContentModals();
  }

  saveSectionChange({ value }) {
    this.props.saveSection({
      name: value,
      ...this.props.saveSectionProps,
    })
      .then(() => this.closeSaveSectionModal());
  }

  renameSavedSectionChange({ value }) {
    this.props.renameSavedSection({
      name: value,
    }, this.props.renameSavedSectionProps.id)
      .then(() => this.closeRenameSavedSectionModal());
  }

  removeSavedSectionChange() {
    this.props.removeSavedSection(this.props.removeSavedSectionProps.id)
      .then(() => this.closeRemoveSavedSectionModal());
  }

  saveCodeEditorChange() {
    const content = this.codeEditorContents;
    this.props.saveJsEditorContent({ value: content });
    this.props.closeCodeEditorSaveConfirmation();
  }

  renderCodeEditor() {
    if (this.props.isCodeEditorOpen && this.props.codeEditorContent !== null) {
      return (
        <SourceEditor
          content={this.props.codeEditorContent}
          type={this.props.codeEditorLanguage}
          onChange={this.onCodeEditorChange}
          onClose={this.closeModal}
        />
      );
    }
    return null;
  }

  renderSourceEditor() {
    const {
      content,
      contentEditorContentId,
      isContentEditorOpen,
    } = this.props;
    const contentItem = content.filter(c => c.id === contentEditorContentId).pop();
    if (!contentItem) {
      return null;
    }
    const columns = flatten(map(contentItem.columns, 'components'));
    const component = columns.filter(column => column.type === 'html').pop();
    const {
      content: componentContent,
      id: componentId,
    } = component;
    if (isContentEditorOpen) {
      return (
        <SourceEditor
          content={componentContent}
          contentId={componentId}
          type="html"
          onChange={this.onSourceEditorChange}
          onClose={this.closeModal}
        />
      );
    }
    return null;
  }

  renderModal() {
    const {
      content,
      googleMapsEditorContentId,
      googleMapsEditorComponentId,
      isContentLayoutModalOpen,
      isDeleteContentModalOpen,
      isSaveSectionModalOpen,
      isRenameSavedSectionModalOpen,
      isRemoveSavedSectionModalOpen,
      isGoogleMapsModalOpen,
      isMediaPickerModalOpen,
      isMediaManagerModalOpen,
      isCodeEditorConfirmModalOpen,
      layoutModalContentId,
      mediaPickerContentId,
      mediaPickerMedia,
      mediaManagerContentId,
      isLinkPickerModalOpen,
      linkPickerValue,
      renameSavedSectionProps,
      isSaveSectionLoading,
      isRenameSavedSectionLoading,
      isRemoveSavedSectionLoading,
    } = this.props;

    if (isDeleteContentModalOpen) {
      const { deleteContentProps: { type } } = this.props;
      let modalTitle;
      let modalMessage;

      switch (type) {
        case CONTENT_COMPONENT_TYPE.galleryImage:
        case CONTENT_COMPONENT_TYPE.image:
          modalTitle = i18n.t('CONTENT.deleteImage');
          modalMessage = i18n.t('CONTENT.deleteImageQuestion');
          break;
        case CONTENT_COMPONENT_TYPE.sliderImage:
          modalTitle = i18n.t('CONTENT.deleteSlide');
          modalMessage = i18n.t('CONTENT.deleteSlideQuestion');
          break;
        default:
          modalTitle = i18n.t('CONTENT.deleteContentTitle');
          modalMessage = i18n.t('CONTENT.deleteContentMessage');
      }
      return (
        <ConfirmationModal
          icon="icon-deleting"
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onOk={this.onDeleteConfirmation}
          submitButtonText={i18n.t('CONTENT.delete')}
          title={modalTitle}
        >
          <p>{modalMessage}</p>
        </ConfirmationModal>
      );
    }

    if (isSaveSectionModalOpen) {
      return (
        <PromptModal
          icon="icon-save"
          title={i18n.t('CONTENT.saveLayoutBlock')}
          label={i18n.t('CONTENT.name')}
          valueMaxLength={255}
          submitButtonText={i18n.t('CONTENT.save')}
          isLoading={isSaveSectionLoading}
          onCancel={this.closeSaveSectionModal}
          onClose={this.closeSaveSectionModal}
          onOk={this.saveSectionChange}
          validate={this.validateNewSectionName}
        />
      );
    }

    if (isRenameSavedSectionModalOpen) {
      return (
        <PromptModal
          icon="icon-edit"
          title={i18n.t('CONTENT.changeName')}
          label={i18n.t('CONTENT.name')}
          submitButtonText={i18n.t('CONTENT.save')}
          value={renameSavedSectionProps.name}
          valueMaxLength={255}
          isLoading={isRenameSavedSectionLoading}
          onCancel={this.closeRenameSavedSectionModal}
          onClose={this.closeRenameSavedSectionModal}
          onOk={this.renameSavedSectionChange}
          validate={this.validateUpdatedSectionName}
        />
      );
    }

    if (isRemoveSavedSectionModalOpen) {
      return (
        <ConfirmationModal
          icon="icon-deleting"
          onCancel={this.closeRemoveSavedSectionModal}
          onClose={this.closeRemoveSavedSectionModal}
          onOk={this.removeSavedSectionChange}
          submitButtonText={i18n.t('CONTENT.delete')}
          title={i18n.t('CONTENT.delete')}
        >
          {isRemoveSavedSectionLoading && <Spinner />}
          {!isRemoveSavedSectionLoading && <p>{i18n.t('CONTENT.deleteConfirmation')}</p>}
        </ConfirmationModal>
      );
    }

    if (isMediaManagerModalOpen) {
      return (
        <MediaManagerModal
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onOk={this.onMediaManagerChange}
          contentId={mediaManagerContentId}
        />
      );
    }

    if (isMediaPickerModalOpen) {
      // create media object from default media object values
      const media = { type: MEDIA_TYPES.image, ...mediaDefaultProps };
      // change section backgroundImage
      if (mediaPickerMedia.type === 'section') {
        const contentItem = content.filter(c => c.id === mediaPickerContentId).pop();
        if (!contentItem) {
          return null;
        }
        const { options: { backgroundImage: url } } = contentItem;
        Object.assign(media, {
          url,
          typeFilter: MEDIA_TYPES.image, // only show media of type image in media manager
        });
      } else if (mediaPickerMedia.type === 'column') {
        const { contentId, columnId } = mediaPickerContentId;
        const contentItem = content.filter(c => c.id === contentId).pop();
        if (!contentItem) {
          return null;
        }
        const column = contentItem.columns.filter(c => c.id === columnId).pop();
        if (!column) {
          return null;
        }
        const { options: { backgroundImage: url } } = column;
        Object.assign(media, {
          url,
          typeFilter: MEDIA_TYPES.image, // only show media of type image in media manager
        });
      // Change image src
      } else if (
        [CONTENT_COMPONENT_TYPE.image, CONTENT_COMPONENT_TYPE.sliderImage, CONTENT_COMPONENT_TYPE.galleryImage]
          .indexOf(mediaPickerMedia.type) !== -1
      ) {
        const component = getComponentById(content, mediaPickerContentId);
        if (!component) {
          return null;
        }
        const {
          url, alt, tooltip, hyperlink,
        } = component;
        Object.assign(media, {
          url, alt, tooltip, hyperlink,
        });
      }

      return (
        <MediaPickerModal
          iconUrl="/bundles/browsbox/Themes/Admin/images/media.svg"
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onOk={this.onMediaPickerChange}
          submitButtonText={i18n.t('CONTENT.add')}
          contentId={mediaPickerContentId}
          contentMedia={mediaPickerMedia}
          {...media}
        />
      );
    }
    if (isContentLayoutModalOpen) {
      return (
        <ContentLayoutModal
          onCancel={this.closeLayoutModal}
          onClose={this.closeLayoutModal}
          onOk={this.onLayoutChange}
          contentId={layoutModalContentId}
        />
      );
    }
    // Show Google Maps Modal
    if (isGoogleMapsModalOpen) {
      const contentItem = content.filter(c => c.id === googleMapsEditorContentId).pop();
      if (!contentItem) {
        return null;
      }
      const components = flatten(map(contentItem.columns, 'components'));
      const component = googleMapsEditorComponentId
        ? components.filter(column => column.id === googleMapsEditorComponentId).pop()
        : components.filter(column => column.type === 'googleMaps').pop();

      if (!component) { return null; }
      return (
        <GoogleMapsEditorModal
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onOk={this.onGoogleMapsChange}
          componentId={component.id}
          address={component.address}
          mapStyle={component.mapStyle}
          markerValue={component.markerValue}
        />
      );
    }

    if (isLinkPickerModalOpen) {
      return (
        <LinkPickerModal
          onCancel={this.closeModal}
          onClose={this.closeModal}
          onOk={this.onLinkChange}
          value={linkPickerValue}
        />
      );
    }

    if (isCodeEditorConfirmModalOpen) {
      return (
        <ConfirmationModal
          onCancel={this.closeCodeEditorConfirmation}
          onClose={this.closeCodeEditorConfirmation}
          onOk={this.saveCodeEditorChange}
          submitButtonText={i18n.t('CONTENT.save')}
          title={i18n.t('CONTENT.saveChanges')}
        >
          <p className="u-mb-l u-mt-l">{i18n.t('CONTENT.saveChangesQuestion')}</p>
        </ConfirmationModal>
      );
    }

    return null;
  }

  renderContent(content) {
    const {
      isEmpty,
      contentComponentUpdated,
    } = this.state;

    const {
      isOpen,
    } = this.props;

    return (
      <div className="o-content">
        {isOpen && (
        <BrowsboxContentList
          content={content}
          isEmpty={isEmpty}
          componentUpdate={contentComponentUpdated}
        />
        )}
      </div>
    );
  }

  renderFooter(content) {
    return <FooterContainer content={content} />;
  }

  renderSupport() {
    return (
      <div className="o-content">
        {this.renderModal()}
        {this.renderSourceEditor()}
        {this.renderCodeEditor()}
      </div>
    );
  }

  render() {
    const {
      content,
      acceptableMainBlockTypes,
      acceptableFooterBlockTypes,
    } = this.props;

    const mainContent = content.filter(({ type }) => acceptableMainBlockTypes.includes(type));
    const footerContent = content.filter(({ type }) => acceptableFooterBlockTypes.includes(type));

    return (
      <>
        {this.renderContent(mainContent)}
        {this.renderFooter(footerContent)}
        {this.renderSupport()}
      </>
    );
  }
}

BrowsboxContent.propTypes = propTypes;
BrowsboxContent.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    entities: {
      content,
    },
    pages: {
      headerHtml,
      settings: {
        pageid,
      },
    },
  } = state;
  const {
    contentEditorContentId,
    deleteContentProps,
    googleMapsEditorContentId,
    googleMapsEditorComponentId,
    isContentEditorOpen,
    isContentLayoutModalOpen,
    isDeleteContentModalOpen,
    isGoogleMapsModalOpen,
    isLinkPickerModalOpen,
    isMediaManagerModalOpen,
    isMediaPickerModalOpen,
    layoutModalContentId,
    linkPickerValue,
    mediaManagerContentId,
    mediaPickerContentId,
    mediaPickerMedia,
    isCodeEditorOpen,
    codeEditorLanguage,
    codeEditorContent,
    isAddingContent,
    isCodeEditorConfirmModalOpen,
  } = state.content;

  const {
    isSaveSectionModalOpen,
    isRenameSavedSectionModalOpen,
    isRemoveSavedSectionModalOpen,
    renameSavedSectionProps,
    removeSavedSectionProps,
    saveSectionProps,
    isSaveSectionLoading,
    isRenameSavedSectionLoading,
    isRemoveSavedSectionLoading,
    savedSections,
  } = state.savedSections;

  const acceptableMainBlockTypes = Object.entries(state.entities.modules)
    .filter(([, { acceptedContainers }]) => acceptedContainers.includes('content'))
    .map(([blockType]) => blockType);

  const acceptableFooterBlockTypes = Object.entries(state.entities.modules)
    .filter(([, { acceptedContainers }]) => acceptedContainers.includes('footer'))
    .map(([blockType]) => blockType);

  return {
    content,
    acceptableMainBlockTypes,
    acceptableFooterBlockTypes,
    contentEditorContentId,
    deleteContentProps,
    googleMapsEditorContentId,
    googleMapsEditorComponentId,
    isContentEditorOpen,
    isContentLayoutModalOpen,
    isDeleteContentModalOpen,
    isSaveSectionModalOpen,
    isRenameSavedSectionModalOpen,
    isRemoveSavedSectionModalOpen,
    isGoogleMapsModalOpen,
    isLinkPickerModalOpen,
    isMediaManagerModalOpen,
    isMediaPickerModalOpen,
    layoutModalContentId,
    linkPickerValue,
    mediaManagerContentId,
    mediaPickerContentId,
    mediaPickerMedia,
    pageid,
    headerHtml,
    isCodeEditorOpen,
    codeEditorLanguage,
    codeEditorContent,
    saveSectionProps,
    savedSections,
    renameSavedSectionProps,
    removeSavedSectionProps,
    isAddingContent,
    isCodeEditorConfirmModalOpen,
    isSaveSectionLoading,
    isRenameSavedSectionLoading,
    isRemoveSavedSectionLoading,
    isOpen: !(state.users.isOpen || state.generalSettings.isOpen || state.baseModule.isOpen),
  };
};

const mapDispatchToProps = {
  addComponent,
  clearComponentOption,
  clearContentOption,
  clearColumnOption,
  closeContentModals,
  closeLinkPicker,
  closeMediaManager,
  deleteComponent,
  deleteContent,
  loadContent,
  setComponentOption,
  setContentOption,
  setColumnOption,
  saveCssEditorContent,
  saveJsEditorContent,
  saveSection,
  renameSavedSection,
  removeSavedSection,
  getCurrentPage,
  openCodeEditorSaveConfirmation,
  closeCodeEditorSaveConfirmation,
  closeSaveSection,
  closeRenameSavedSection,
  closeRemoveSavedSection,
};

const BrowsboxContentContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxContent);

export default withNamespaces()(BrowsboxContentContainer);
