const CONTENT_COMPONENT_TYPE = {
  empty: 'empty',
  button: 'button',
  breadcrumbs: 'breadcrumbs',
  news: 'news',
  buttonSecondary: 'buttonSecondary',
  googleMaps: 'googleMaps',
  hr: 'hr',
  html: 'html',
  image: 'image',
  galleryImage: 'galleryImage',
  subtitle: 'subtitle',
  text: 'text',
  title: 'title',
  sliderImage: 'sliderImage',
  video: 'video',
  dynamic: 'dynamic',
  divider: 'divider',
  socialMedia: 'socialMedia',
};

export default CONTENT_COMPONENT_TYPE;
