import merge from 'lodash/merge';
import map from 'lodash/map';
import get from 'lodash/get';
import { normalize } from 'normalizr';
import cloneDeep from 'lodash/cloneDeep';
import { DND_CONTENT_MODULE } from '../components/DragAndDrop/dndTypes';
import {
  ADD_CONTENT,
  ADD_COMPONENT,
  ADD_COMPONENT_TO_COLUMN, INSERT_COMPONENT_TO_COLUMN, MOVE_COMPONENT_TO_COLUMN,
} from '../actions/contentAdd';
import {
  MOVE_CONTENT,
  MOVE_COMPONENT,
} from '../actions/contentMove';
import {
  DELETE_CONTENT,
  DELETE_COMPONENT,
} from '../actions/contentDelete';
import {
  DELETE_MEDIA,
} from '../actions/mediaDelete';
import {
  SET_MEDIA_OPTION,
} from '../actions/mediaConfig';
import {
  SHOW_CONTENT_COMPONENT_BY_TYPE,
  HIDE_CONTENT_COMPONENT_BY_TYPE,
} from '../actions/contentComponentToggle';
import {
  SET_CONTENT_OPTION,
  MERGE_CONTENT_OPTION,
  CLEAR_CONTENT_OPTION,
  SET_COMPONENT_OPTION,
  CLEAR_COMPONENT_OPTION, APPEND_COLUMN_COMPONENTS,
  SET_COLUMN_OPTION,
  CLEAR_COLUMN_OPTION, TRANSPOSE_COLUMNS,
} from '../actions/contentConfig';
import {
  PAGE_REORDER, PAGES_SUCCESS,
} from '../actions/pages';
import {
  SET_CONTENT_COLUMN_COUNT,
} from '../actions/contentColumns';
import { LANGUAGES_FETCH_SUCCESS } from '../actions/languages';
import { CLONE_CONTENT } from '../actions/contentClone';
import {
  MEDIA_FOLDERS_REQUEST,
  MEDIA_FOLDERS_SUCCESS,
  MEDIA_SUCCESS,
  MEDIA_UPLOAD_SUCCESS,
  deleteMediaFolder,
  updateMediaFolder,
  createMediaFolder,
  moveMediaFolder,
  moveMedia,
  MEDIA_FILE_SUCCESS,
} from '../actions/media';
import { CONTENT_SUCCESS } from '../actions/content';
import { MODULES_SUCCESS } from '../actions/modules';
import { PAGE_PROPS_SUCCESS } from '../actions/pageProperties';
import { WEBSITE_SETTINGS_FETCH_SUCCESS } from '../actions/website';
import { COMPONENTS_ARRAY_SCHEMA } from '../api/schema';
import {
  getNewId,
  getContentById,
  getColumnById,
  getComponentById,
  createNewColumn,
  resolveNewModuleComponents,
  getMediaById,
  getSectionForComponentById,
} from '../utils/entities';
import { move } from '../utils/array';
import { selectContentSections, selectFooterSections } from '../selectors/content';

export const initialState = {
  content: [],
  components: {},
  modules: {},
  pageOrder: [],
  pages: {},
  pageTypes: {
    0: {
      icon: 'page',
      title: 'Basispagina',
    },
  },
  media: {},
  mediaFolder: {},
  links: {},
  languages: [],
};

const entities = (state = initialState, action) => {
  switch (action.type) {
    case WEBSITE_SETTINGS_FETCH_SUCCESS: {
      const raw = get(action, 'response.enabledComponents', []);
      const entries = normalize(raw, COMPONENTS_ARRAY_SCHEMA);
      const components = get(entries, 'entities.components', {});

      return {
        ...state,
        components,
      };
    }
    case CONTENT_SUCCESS: {
      const { content } = action.response.entities;

      return {
        ...state,
        content,
      };
    }

    case MODULES_SUCCESS: {
      const { modules } = action.response.entities;

      return {
        ...state,
        modules,
      };
    }

    case MEDIA_FILE_SUCCESS:
    case MEDIA_SUCCESS: {
      const { media = {} } = action.response.entities;

      return {
        ...state,
        media: {
          ...state.media,
          ...media,
        },
      };
    }

    case PAGES_SUCCESS: {
      const { pages, pageOrder } = action.response.entities;

      return {
        ...state,
        pages,
        pageOrder,
      };
    }

    case PAGE_PROPS_SUCCESS: {
      if (action.response && action.response.entities) {
        const { pageProps = {} } = action.response.entities;

        return {
          ...state,
          pageProps,
        };
      }

      return state;
    }

    case LANGUAGES_FETCH_SUCCESS: {
      const languages = action.response || [];

      return {
        ...state,
        languages,
      };
    }

    case ADD_CONTENT: {
      const moduleDef = state.modules[action.value.type];
      if (!moduleDef || !moduleDef.columns) {
        return state;
      }
      const addModule = {
        // extra properties required by the sections api endpoint
        deletable: true,
        locked: false,
        editable: true,
        dynamic: false,
        full_width: false,
        // editor properties
        classes: moduleDef.classes,
        columns: cloneDeep(moduleDef.columns),
        options: { ...moduleDef.options },
        ...action.value,
        dndSource: DND_CONTENT_MODULE,
      };
      const moduleColumns = addModule.columns || [{}];
      addModule.columns = moduleColumns.map(column => ({
        ...column,
        id: getNewId(),
        components: resolveNewModuleComponents(addModule, column),
        options: {
          ...column.options,
          size: moduleDef?.options?.isColumnPositioningActive ? 12 : column?.options?.size,
        },
      }));
      const content = state.content.map(c => ({ ...c }));
      content.splice(addModule.index, 0, addModule);
      return {
        ...state,
        content,
      };
    }

    case MOVE_CONTENT: {
      const {
        originalIndex: fromIndex,
        index: toIndex,
      } = action.value;
      const contentSections = selectContentSections(state);
      const footerSections = selectFooterSections(state);

      // !! Note !!
      // Because only contentSections are sortable,
      // we have length and index missmatch between Redux state and local state in D&D components (ContentContainer),
      // so before we re-order the sections, first we have to exclude "footer" sections from the store.
      // See: https://browsbox.atlassian.net/browse/BB-1628
      const nextContentSections = move(contentSections, fromIndex, toIndex);

      return {
        ...state,
        // Now we can assemble all sections again
        content: [
          ...nextContentSections,
          ...footerSections,
        ],
      };
    }

    case DELETE_CONTENT: {
      const {
        id,
      } = action.value;
      const content = state.content.map(c => ({ ...c }));
      const originalContent = content.filter(c => c.id === id).shift();
      const contentIndex = content.indexOf(originalContent);
      if (contentIndex === -1) {
        return state;
      }
      content.splice(contentIndex, 1);
      return {
        ...state,
        content,
      };
    }

    case SHOW_CONTENT_COMPONENT_BY_TYPE:
    case HIDE_CONTENT_COMPONENT_BY_TYPE: {
      const {
        contentId,
        componentType,
      } = action.value;

      const content = state.content.map(c => ({ ...c }));
      const originalContent = content.filter(c => c.id === contentId).shift();
      const contentIndex = content.indexOf(originalContent);
      if (contentIndex === -1) {
        return state;
      }

      const setEnabled = (component) => {
        if (component.toggelable === false) {
          return component.enabled;
        }
        if (component.type === componentType) {
          if (action.type === SHOW_CONTENT_COMPONENT_BY_TYPE) {
            return true;
          }
          return false;
        }
        return component.enabled;
      };

      const newContent = { ...originalContent };
      const newContentColumns = newContent.columns.map((component) => {
        const newColumn = { ...component, enabled: setEnabled(component) };
        if (component.columns) {
          // eslint-disable-next-line no-param-reassign
          component.columns = component.columns.map(child => ({ ...child, enabled: setEnabled(child) }));
        }
        return newColumn;
      });
      newContent.columns = newContentColumns;
      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      return {
        ...state,
        content,
      };
    }

    case MERGE_CONTENT_OPTION:
    case SET_CONTENT_OPTION: {
      const {
        option,
        value,
        contentId,
        isRootOption, // option is outside of content.options object
      } = action.value;
      const {
        content,
        originalContent,
        newContent,
        contentIndex,
      } = getContentById(state, contentId);

      if (contentIndex === -1) {
        return state;
      }
      newContent.options = merge({}, originalContent.options);
      if (isRootOption === true) {
        newContent[option] = value;
      } else {
        newContent.options[option] = value;
      }
      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      return {
        ...state,
        content,
      };
    }

    case CLEAR_CONTENT_OPTION: {
      const {
        option,
        contentId,
      } = action.value;
      const {
        content,
        originalContent,
        newContent,
        contentIndex,
      } = getContentById(state, contentId);
      if (contentIndex === -1) {
        return state;
      }
      newContent.options = merge({}, originalContent.options);
      delete newContent.options[option];
      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      return {
        ...state,
        content,
      };
    }

    case SET_COMPONENT_OPTION: {
      const {
        option,
        value,
        contentId,
      } = action.value;
      const { content } = state;
      const component = getComponentById(content, contentId);
      const section = getSectionForComponentById(content, contentId);

      if (!component || !section) {
        return state;
      }

      const {
        newContent,
        contentIndex,
      } = getContentById(state, section.id);

      if (contentIndex === -1) {
        return state;
      }

      newContent.updatedAt = new Date();
      const newComponent = getComponentById([newContent], contentId);
      newComponent[option] = value;

      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      return {
        ...state,
        content,
      };
    }

    case CLEAR_COMPONENT_OPTION: {
      const {
        option,
        contentId,
      } = action.value;
      const { content } = state;
      const component = getComponentById(content, contentId);
      const section = getSectionForComponentById(content, contentId);

      if (!component || !section) {
        return state;
      }

      const {
        newContent,
        contentIndex,
      } = getContentById(state, section.id);

      if (contentIndex === -1) {
        return state;
      }

      const newComponent = getComponentById([newContent], contentId);
      delete newComponent[option];

      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      return {
        ...state,
        content,
      };
    }

    case SET_CONTENT_COLUMN_COUNT: {
      const {
        value: newColumnCount,
        contentId,
        contentType,
      } = action.value;

      const {
        content,
        newContent,
        contentIndex,
      } = getContentById(state, contentId);

      if (contentIndex === -1) {
        return state;
      }

      const moduleDef = state.modules[contentType];
      if (!moduleDef || !moduleDef.columns) {
        return state;
      }

      const currentColumnCount = newContent.columns.filter(c => c.visible !== false).length;

      // need at least one column, at most twelve
      if (newColumnCount < 1 || newColumnCount > 12) {
        return state;
      }

      // no change in number of columns
      if (newColumnCount === currentColumnCount) {
        return state;
      }

      // increase number of columns
      if (newColumnCount > currentColumnCount) {
        for (let i = currentColumnCount; i < newColumnCount; i += 1) {
          // show hidden column ?
          if (typeof newContent.columns[i] !== 'undefined') {
            newContent.columns[i].visible = true;
            // add new column
          } else {
            const column = cloneDeep(moduleDef.columns[0]);
            column.options.size = moduleDef.options.columnPositioningActive ? 1 : undefined;

            newContent.columns.push({
              ...column,
              id: getNewId(),
              components: column.components.map(component => ({ ...component, id: getNewId() })),
            });
          }
        }
        content.splice(contentIndex, 1);
        content.splice(contentIndex, 0, newContent);
      }

      // decrease number of columns
      if (newColumnCount < currentColumnCount) {
        newContent.columns = newContent.columns.map((column, idx) => ({
          ...column,
          visible: idx < newColumnCount,
          options: {
            ...column.options,
          },
        }));
        content.splice(contentIndex, 1);
        content.splice(contentIndex, 0, newContent);
      }

      if (moduleDef.options.columnPositioningActive) {
        // assign the default size to all the columns that don't have any
        newContent.columns = newContent.columns.map(
          (column) => {
            if (column.visible === false || column.options.size) {
              return column;
            }

            return {
              ...column,
              options: {
                ...column.options,
                size: Math.floor(12 / newColumnCount),
              },
            };
          },
        );

        let totalColumnsSize = newContent.columns
          .filter((column) => column.visible !== false)
          .reduce(
            (value, column) => value + parseInt(column.options.size, 10),
            0,
          );

        if (totalColumnsSize < 12) {
          // If we removed a column, the total columns size won't match twelve.
          // Let's increase the size of the last column by the missing size.
          const lastColumn = newContent.columns[newColumnCount - 1];

          lastColumn.options.size = parseInt(lastColumn.options.size, 10) + 12 - totalColumnsSize;
        } else if (totalColumnsSize > 12) {
          // If a new column was added, or an existing one just made visible, the total columns size might be way over
          // the required limit. In that case, we'll need to readjust the whole layout by grabbing the largest column
          // starting from the right and reducing its size, while keeping in mind its minimum required size.
          // We repeat the process if a single column can't be shrunk enough to make the total columns size match the
          // required limit.
          do {
            const [, widestColumnIndex] = newContent.columns
              .slice(0, currentColumnCount)
              .reduceRight(
                (aggregator, column, columnIndex) => {
                  const size = parseInt(column.options.size, 10);

                  return [
                    size > aggregator[0] ? size : aggregator[0],
                    size > aggregator[0] ? columnIndex : aggregator[1],
                  ];
                },
                [0, -1],
              );

            const delta = totalColumnsSize - 12;
            const oldSize = parseInt(newContent.columns[widestColumnIndex].options.size, 10);
            const newSize = Math.max(oldSize - delta, 1);
            newContent.columns[widestColumnIndex].options.size = newSize;

            totalColumnsSize -= oldSize - newSize;
          } while (totalColumnsSize > 12);
        }
      }

      return {
        ...state,
        content,
      };
    }

    // Copy module (aka blocks) component to existing section.
    // Example: add "image" to gallery or slider.
    // Use action.value.options will extend the new component properties
    // Example: url on new image
    case ADD_COMPONENT: {
      const {
        contentId,
        contentType,
        options,
      } = action.value;
      // Get block definition
      const moduleDef = state.modules[contentType];
      if (!moduleDef || !moduleDef.columns) {
        return state;
      }
      // New component from block definition
      const components = map(moduleDef.columns, 'components').pop();
      if (components.length === 0) {
        return state;
      }
      const newComponent = {
        id: getNewId(), ...components[0], ...options, blocks: undefined,
      };
      const { content, newContent, contentIndex } = getContentById(state, contentId);
      if (contentIndex === -1) {
        return state;
      }

      // Add new component to content
      if (!newContent.columns[0]) {
        newContent.columns = [
          createNewColumn(),
        ];
      }

      newContent.columns[0].components.push(newComponent);

      // Use updated content
      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      return {
        ...state,
        content,
      };
    }

    case ADD_COMPONENT_TO_COLUMN: {
      const {
        contentId,
        columnId,
        type,
      } = action.value;
      const { content, newContent, contentIndex } = getContentById(state, contentId);
      const column = newContent.columns.find(item => item.id === columnId);
      const component = state.components[type];

      if (component) {
        column.components.push({
          ...component,
          id: getNewId(),
          blocks: undefined,
        });
      }

      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);

      return {
        ...state,
        content,
      };
    }

    case INSERT_COMPONENT_TO_COLUMN: {
      const {
        contentId,
        columnId,
        componentId,
        type,
      } = action.value;
      const { content, newContent, contentIndex } = getContentById(state, contentId);
      const column = newContent.columns.find(item => item.id === columnId);
      const newComponentIndex = componentId
        ? column.components.findIndex(component => component.id === componentId) + 1
        : 0;
      const component = state.components[type];

      if (component) {
        const newComponents = [...column.components];

        newComponents.splice(newComponentIndex, 0, {
          ...component,
          id: getNewId(),
          blocks: undefined,
        });

        column.components = newComponents;
      }

      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);

      return {
        ...state,
        content,
      };
    }

    case MOVE_COMPONENT_TO_COLUMN: {
      const {
        contentId,
        sourceColumnId,
        targetColumnId,
        sourceComponentId,
        targetComponentId,
      } = action.value;

      const { content, newContent, contentIndex } = getContentById(state, contentId);

      const {
        newColumn: sourceColumn,
        columnIndex: sourceColumnIndex,
      } = getColumnById(newContent, sourceColumnId);

      const sourceComponents = [...sourceColumn.components];

      let targetColumn = sourceColumn;
      let targetColumnIndex = sourceColumnIndex;
      let targetComponents = sourceComponents;

      if (sourceColumnId !== targetColumnId) {
        const { newColumn, columnIndex } = getColumnById(newContent, targetColumnId);

        targetColumn = newColumn;
        targetColumnIndex = columnIndex;
        targetComponents = [...targetColumn.components];
      }

      const [sourceComponent] = sourceComponents.splice(
        sourceColumn.components.findIndex((c) => c.id === sourceComponentId),
        1,
      );

      const targetIndex = targetComponentId
        ? targetComponents.findIndex((c) => c.id === targetComponentId)
        : targetComponents.length;

      targetComponents.splice(
        targetIndex,
        0,
        sourceComponent,
      );

      newContent.columns[sourceColumnIndex].components = sourceComponents;
      newContent.columns[targetColumnIndex].components = targetComponents;

      content[contentIndex] = newContent;

      return {
        ...state,
        content,
      };
    }

    case CLONE_CONTENT: {
      const { id } = action.value;
      const { newContent, content, contentIndex } = getContentById(state, id);

      const clonedContent = {
        ...newContent,
        id: getNewId(),
        columns: newContent.columns.map(column => ({
          ...column,
          id: getNewId(),
          components: column.components.map(component => ({
            ...component,
            id: getNewId(),
          })),
        })),
      };

      content.splice(contentIndex + 1, 0, clonedContent);

      return {
        ...state,
        content,
      };
    }

    // Delete Component from Content (aka section)
    // Example: delete "image" to gallery or slider.
    case DELETE_COMPONENT: {
      const {
        id: componentId,
        columnId = null,
      } = action.value;
      // Find current Component
      const section = getSectionForComponentById(state.content, componentId);
      const component = getComponentById(state.content, componentId);
      if (!component || !section) {
        return state;
      }
      // Find current Content (aka section)
      const { id: contentId } = section;
      const { content, newContent, contentIndex } = getContentById(state, contentId);
      if (contentIndex === -1) {
        return state;
      }
      // Remove component from content ( aka section )
      const column = columnId ? newContent.columns.find(c => c.id === columnId) : newContent.columns[0];
      column.components = column.components.filter(c => c.id !== componentId);

      // Use updated content
      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      // Update state
      return {
        ...state,
        content,
      };
    }

    case MOVE_COMPONENT: {
      const {
        originalIndex: fromIndex,
        index: toIndex,
        id: componentId,
      } = action.value;
      // Find current Component
      const section = getSectionForComponentById(state.content, componentId);
      const component = getComponentById(state.content, componentId);
      if (!component || !section) {
        return state;
      }
      // Find current Content (aka section)
      const { id: contentId } = section;
      const { content, newContent, contentIndex } = getContentById(state, contentId);
      if (contentIndex === -1) {
        return state;
      }
      // Move component to the new position within the array of components
      const newComponents = newContent.columns[0].components;
      // splice will return the an array of the deleted elements, pop get the component
      const originalComponent = newComponents.splice(fromIndex, 1).pop();
      newComponents.splice(toIndex, 0, originalComponent);
      // Update components in content (aka sections)
      newContent.columns[0].components = newComponents;
      // Use updated content
      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);
      // Update state
      return {
        ...state,
        content,
      };
    }

    case moveMedia.SUCCESS: {
      const { id } = action.meta;
      const nextState = {
        ...state,
        media: {
          ...state.media,
        },
      };
      delete nextState.media[id];

      return nextState;
    }
    case DELETE_MEDIA: {
      const {
        id: mediaId,
      } = action.value;
      const {
        media,
      } = getMediaById(state, mediaId);

      if (!media) {
        return state;
      }

      delete media[mediaId];

      return {
        ...state,
        media,
      };
    }

    case SET_MEDIA_OPTION: {
      const {
        id: mediaId,
        option,
        value,
      } = action.value;

      const {
        media,
        newMedia,
      } = getMediaById(state, mediaId);

      if (!media) {
        return state;
      }

      newMedia[option] = value;
      media[mediaId] = newMedia;

      return {
        ...state,
        media,
      };
    }

    case PAGE_REORDER: {
      const { pageOrder, pages } = action.value;
      if (!pages || !pageOrder) {
        return state;
      }
      return {
        ...state,
        pageOrder,
        pages,
      };
    }

    case APPEND_COLUMN_COMPONENTS: {
      return {
        ...state,
        content: state.content.map(section => ({
          ...section,
          columns: section.columns.map((column) => {
            if (column.id !== action.value.column.id) {
              return column;
            }

            return {
              ...column,
              components: [
                ...column.components,
                ...action.value.newComponents.map(component => ({
                  ...component,
                  id: getNewId(),
                })),
              ],
            };
          }),
        })),
      };
    }

    case SET_COLUMN_OPTION: {
      const {
        contentId,
        columnId,
        option,
        value,
      } = action.value;

      const { content, newContent, contentIndex } = getContentById(state, contentId);

      if (contentIndex === -1) {
        return state;
      }

      const {
        newColumn, columnIndex, columns, originalColumn,
      } = getColumnById(newContent, columnId);

      newColumn.options = merge({}, originalColumn.options);
      newColumn.options[option] = value;

      columns.splice(columnIndex, 1);
      columns.splice(columnIndex, 0, newColumn);

      newContent.columns = columns;

      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);

      return {
        ...state,
        content,
      };
    }

    case CLEAR_COLUMN_OPTION: {
      const {
        contentId,
        columnId,
        option,
      } = action.value;

      const { content, newContent, contentIndex } = getContentById(state, contentId);

      if (contentIndex === -1) {
        return state;
      }

      const {
        newColumn, columnIndex, columns, originalColumn,
      } = getColumnById(newContent, columnId);

      newColumn.options = merge({}, originalColumn.options);
      delete newColumn.options[option];

      columns.splice(columnIndex, 1);
      columns.splice(columnIndex, 0, newColumn);

      newContent.columns = columns;

      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);

      return {
        ...state,
        content,
      };
    }

    case TRANSPOSE_COLUMNS: {
      const {
        contentId,
        columnId,
        otherColumnId,
      } = action.value;

      const { content, newContent, contentIndex } = getContentById(state, contentId);

      if (contentIndex === -1) {
        return state;
      }

      const columnIndex = newContent.columns.findIndex(({ id }) => id === columnId);
      const otherColumnIndex = newContent.columns.findIndex(({ id }) => id === otherColumnId);

      if (columnIndex === -1 || otherColumnIndex === -1) {
        return state;
      }

      const column = newContent.columns[columnIndex];
      newContent.columns[columnIndex] = newContent.columns[otherColumnIndex];
      newContent.columns[otherColumnIndex] = column;

      content.splice(contentIndex, 1);
      content.splice(contentIndex, 0, newContent);

      return {
        ...state,
        content,
      };
    }

    case MEDIA_UPLOAD_SUCCESS: {
      const mediaItem = {
        ...action.response,
        wasRecentlyCreated: true,
      };
      const mediaItems = { ...state.media };
      mediaItems[mediaItem.id] = mediaItem;

      return {
        ...state,
        media: mediaItems,
      };
    }
    case MEDIA_FOLDERS_REQUEST: {
      return {
        ...state,
        mediaFolder: {},
      };
    }
    case MEDIA_FOLDERS_SUCCESS: {
      if (action.response && action.response.entities) {
        const { mediaFolder = {} } = action.response.entities;

        return {
          ...state,
          mediaFolder,
        };
      }

      return state;
    }
    case createMediaFolder.SUCCESS:
    case updateMediaFolder.SUCCESS: {
      const { id } = action.response;

      return {
        ...state,
        mediaFolder: {
          ...state.mediaFolder,
          [id]: action.response,
        },
      };
    }
    case moveMediaFolder.SUCCESS:
    case deleteMediaFolder.SUCCESS: {
      const { id } = action.meta;
      const nextState = {
        ...state,
        mediaFolder: {
          ...state.mediaFolder,
        },
      };
      delete nextState.mediaFolder[id];

      return nextState;
    }
    default:
      return state;
  }
};

export default entities;
