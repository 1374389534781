import { put, takeLatest } from 'redux-saga/effects';
import { get } from 'lodash';
import {
  expandPage, PAGES_SUCCESS, PAGES_REQUEST, GET_CURRENT_PAGE_REQUEST,
} from '../../actions/pages';
import { getPageFooterHtml, getPageHeaderHtml } from '../../actions/pageHtml';

/**
 * Watches every page's success requests and expands current page
 *
 * @param action
 * @returns {IterableIterator<*|PutEffect<{id, type}>>}
 */
function* expandCurrentPage(action) {
  const { response } = action;
  const pages = get(response, 'entities.pages');
  const { pathname } = window.location;

  if (!pages) {
    return;
  }

  const activePage = Object.keys(pages)
    .map(key => pages[key])
    .filter(page => page.route === pathname)
    .pop();

  if (!activePage) {
    return;
  }

  yield put(expandPage(activePage.id));
}

function* fetchPageHeaderHtml() {
  yield put(getPageHeaderHtml());
}

function* fetchPageFooterHtml() {
  yield put(getPageFooterHtml());
}

export default function* watchSuccessPages() {
  yield takeLatest(PAGES_SUCCESS, expandCurrentPage);

  yield takeLatest(PAGES_REQUEST, fetchPageHeaderHtml);
  yield takeLatest(PAGES_REQUEST, fetchPageFooterHtml);

  // When running on webpack dev server, the header and footer are not available on the homepage.
  // This avoids pulling them in when running in the normal environment where they're available.
  if (process.env.NODE_ENV === 'development' && process.env.REACT_APP_HOST) {
    yield takeLatest(GET_CURRENT_PAGE_REQUEST, fetchPageHeaderHtml);
    yield takeLatest(GET_CURRENT_PAGE_REQUEST, fetchPageFooterHtml);
  }
}
