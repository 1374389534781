import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import {
  contentPropType,
  contentComponentPropType,
  contentLayoutProps,
  MIN_RATIO,
  MAX_RATIO,
  MIN_ROUNDED_HEIGHT,
  DEFAULT_RATIO,
  IMAGE_TYPE_REGULAR,
  IMAGE_TYPE_ROUNDED,
} from '@liswood-tache/browsbox-static';
import { setComponentOption, clearComponentOption } from '../../../../actions/contentConfig';
import Icon from '../../../Icon/Icon';

class ResizableContainer extends React.Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    children: PropTypes.any.isRequired,
    disabled: PropTypes.bool,
    content: contentPropType.isRequired,
    component: contentComponentPropType.isRequired,
    selectedLayout: contentLayoutProps,

    setComponentOption: PropTypes.func.isRequired,
    clearComponentOption: PropTypes.func.isRequired,

    onResizeStart: PropTypes.func,
    onResizeEnd: PropTypes.func,
  };

  static defaultProps = {
    disabled: false,
    selectedLayout: null,
    onResizeStart: () => {},
    onResizeEnd: () => {},
  };

  constructor(props) {
    super(props);
    this.containerRef = React.createRef();

    this.state = {
      active: false,
      startY: null,
      imageWidth: null,
      imageHeight: null,
      maxWidth: null,
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleDocumentClick);
    this.syncImageType();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.content?.options?.layoutClasses !== this.props.content?.options?.layoutClasses) {
      this.resetDimensions();
    }

    this.syncImageType();
  }

  componentWillUnmount() {
    document.removeEventListener('click', this.handleDocumentClick);
  }

  onResizeHandleMouseDown = (event) => {
    document.addEventListener('mousemove', this.onDocumentMouseMove);
    document.addEventListener('mouseup', this.onResizeHandleMouseUp);

    document.body.classList.add('--disabled-selection');
    const wrapper = this.containerRef.current;
    const width = wrapper.clientWidth;
    const height = wrapper.clientHeight;
    const maxWidth = wrapper.parentNode.clientWidth;

    this.props.onResizeStart();

    this.setState({
      startY: event.clientY,
      imageWidth: width,
      imageHeight: height,
      maxWidth,
    });
  };

  onResizeHandleMouseUp = () => {
    document.removeEventListener('mousemove', this.onDocumentMouseMove);
    document.removeEventListener('mouseup', this.onResizeHandleMouseUp);

    document.body.classList.remove('--disabled-selection');
    this.props.onResizeEnd();
  };

  onDocumentMouseMove = (event) => {
    const { imageWidth, imageHeight } = this.state;
    const nextHeight = imageHeight + (event.clientY - this.state.startY);

    this.dispatchNewDimensions(imageWidth, nextHeight);
  };

  syncImageType = () => {
    const { selectedLayout, component } = this.props;

    if (selectedLayout && component) {
      if (selectedLayout.imageType !== component.imageType) {
        this.dispatchImageType(selectedLayout.imageType);
      }
    }
  };

  handleDocumentClick = (event) => {
    const node = this.containerRef.current;

    if (!node) {
      return;
    }

    if (!node.contains(event.target)) {
      this.disableActiveState();
    }
  };

  enableActiveState = () => this.setState({ active: true });

  disableActiveState = () => this.setState({ active: false });

  preventDefault = event => event.preventDefault();

  isActive() {
    return this.state.active;
  }

  isEnabled() {
    if (this.props.disabled) {
      return false;
    }

    if (this.props.content && ['builder', 'footer-builder'].includes(this.props.content.type)) {
      return true;
    }

    if (!this.props.selectedLayout) {
      return false;
    }

    const { imageResizableEnabled } = this.props.selectedLayout;

    return imageResizableEnabled;
  }

  resolveImageType() {
    if (!this.props.selectedLayout) {
      return IMAGE_TYPE_REGULAR;
    }

    const { imageType } = this.props.selectedLayout;

    return imageType || IMAGE_TYPE_REGULAR;
  }

  resolveImageDimensions() {
    const { imageWidth, imageHeight } = this.props.component;

    if (imageWidth && imageHeight) {
      return {
        imageWidth,
        imageHeight,
      };
    }

    if (this.props.selectedLayout) {
      return {
        imageWidth: this.props.selectedLayout.imageWidth,
        imageHeight: this.props.selectedLayout.imageHeight,
      };
    }

    return {
      imageWidth: null,
      imageHeight: null,
    };
  }

  resetDimensions() {
    const { component } = this.props;

    this.props.clearComponentOption({
      option: 'imageWidth',
      contentId: component.id,
    });

    this.props.clearComponentOption({
      option: 'imageHeight',
      contentId: component.id,
    });

    this.props.clearComponentOption({
      option: 'imageType',
      contentId: component.id,
    });

    this.props.clearComponentOption({
      option: 'hasCustomSize',
      contentId: component.id,
    });

    this.props.clearComponentOption({
      option: 'cropperDataRaw',
      contentId: component.id,
    });
  }

  dispatchImageType(type) {
    this.props.setComponentOption({
      option: 'imageType',
      contentId: this.props.component.id,
      value: type,
    });
  }

  dispatchNewDimensions(width, height) {
    const type = this.resolveImageType();
    const ratio = height / width;

    if (ratio < MIN_RATIO || ratio > MAX_RATIO) {
      return;
    }

    if (height < MIN_ROUNDED_HEIGHT) {
      return;
    }

    if (type === IMAGE_TYPE_ROUNDED && this.state.maxWidth) {
      if (this.state.maxWidth < Math.max(width, height)) {
        return;
      }
    }

    this.props.setComponentOption({
      option: 'imageWidth',
      contentId: this.props.component.id,
      value: width,
    });

    this.props.setComponentOption({
      option: 'imageHeight',
      contentId: this.props.component.id,
      value: height,
    });
  }

  calculateContainerStyles() {
    const styles = {};
    const { imageWidth, imageHeight } = this.resolveImageDimensions();
    const imageType = this.resolveImageType();
    let ratio = DEFAULT_RATIO;

    if (imageWidth && imageHeight) {
      ratio = imageHeight / imageWidth;
    }

    if (imageType === IMAGE_TYPE_ROUNDED) {
      styles.width = `${imageHeight}px`;
      styles.paddingBottom = `${imageHeight}px`;
    } else {
      const paddingBottom = ratio * 100;
      styles.paddingBottom = `${paddingBottom}%`;
    }

    return styles;
  }

  renderResizableHandle() {
    if (!this.isEnabled()) {
      return null;
    }

    if (!this.isActive()) {
      return null;
    }

    return (
      <div
        onMouseDown={this.onResizeHandleMouseDown}
        className={classNames(
          'c-bb-resizable-container__resizable-handle',
          'c-bb-resizable-container__resizable-handle--y-resizable',
        )}
      >
        <Icon name="resize-vertical" />
      </div>
    );
  }

  render() {
    const { content } = this.props;
    const containerClasses = classNames(
      'c-bb-resizable-container',
      `c-bb-resizable-container--image-type-${this.resolveImageType()}`,
      { 'c-bb-resizable-container--active': this.isEnabled() && this.isActive() },
    );

    if (!content) {
      return null;
    }

    return (
      <div
        className={classNames(
          'c-image__container',
          'c-image__container--resizable',
        )}
        style={this.calculateContainerStyles()}
        onMouseEnter={this.enableActiveState}
        onMouseLeave={this.disableActiveState}
        onClick={this.preventDefault}
        ref={this.containerRef}
      >
        <div className={containerClasses}>
          { this.renderResizableHandle() }
        </div>
        {this.props.children}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { type, options } = ownProps.content;
  const module = state.entities.modules[type];

  return {
    selectedLayout: module && module.layouts.find(item => item.classes === options.layoutClasses),
  };
};

const mapDispatchToProps = {
  setComponentOption,
  clearComponentOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(ResizableContainer);
