/* eslint-disable jsx-a11y/mouse-events-have-key-events */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { DragSource, DropTarget } from 'react-dnd';
import { CONTENT_COMPONENT_TYPE, IMAGE_PLACEHOLDER_URL } from '@liswood-tache/browsbox-static';
import ContentComponentImage from './ContentComponentImage';
import { openDeleteContent } from '../../../actions/contentDelete';
import { DND_CONTENT_COMPONENT } from '../../DragAndDrop/dndTypes';
import { setComponentOption } from '../../../actions/contentConfig';
import { openMediaPicker } from '../../../actions/mediaManager';
import Icon from '../../Icon/Icon';
import ContentComponentTitle from './ContentComponentTitle';
import ContentComponentButton from './ContentComponentButton';

const containerStyles = {
  float: 'left',
  overflow: 'hidden',
  position: 'relative',
  display: 'inline-block',
  width: 'calc(100% / 3 - 64px)',
  height: 'auto',
  margin: '0 32px 32px 32px',
};

const dndSource = {
  beginDrag(props, monitor, component) {
    const {
      id, dndIndex, dndValidTarget, url,
    } = props;
    props.onBeginDrag(id);
    // eslint-disable-next-line react/no-find-dom-node
    const { width, height } = component.imageRef.getBoundingClientRect();

    return {
      id,
      dndIndex,
      dndValidTarget,
      originalIndex: dndIndex,
      imageUrl: url || IMAGE_PLACEHOLDER_URL,
      width,
      height,
    };
  },
  endDrag(props, monitor) {
    const item = monitor.getItem();
    props.onEndDrag(item);
  },
};

const dndTarget = {
  hover(props, monitor) {
    if (
      !props.dndValidTarget
      || props.dndValidTarget !== monitor.getItem().dndValidTarget
    ) {
      return;
    }
    const dragIndex = monitor.getItem().dndIndex;
    const hoverIndex = props.dndIndex;
    if (dragIndex === hoverIndex) {
      return;
    }
    props.moveItem(dragIndex, hoverIndex);
    monitor.getItem().dndIndex = hoverIndex; // eslint-disable-line no-param-reassign
  },
};

const dropTargetCollect = connectDnd => ({
  connectDropTarget: connectDnd.dropTarget(),
});

const dragSourceCollect = (connectDnd, monitor) => ({
  connectDragSource: connectDnd.dragSource(),
  connectDragPreview: connectDnd.dragPreview(),
  dndIsDragging: monitor.isDragging(),
});

const optionalComponentProp = PropTypes.oneOfType([PropTypes.bool, PropTypes.string]);

@DropTarget(DND_CONTENT_COMPONENT, dndTarget, dropTargetCollect)
@DragSource(DND_CONTENT_COMPONENT, dndSource, dragSourceCollect)
class ContentComponentSliderImage extends Component {
  static propTypes = {
    alt: PropTypes.string, // Section Component data
    buttonContent: optionalComponentProp, // Section Component data
    buttonHyperlink: optionalComponentProp, // Section Component data
    classes: PropTypes.string, // Section Component data,
    connectDragPreview: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    connectDragSource: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    connectDropTarget: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    containerClassName: PropTypes.string,
    dndAllowMove: PropTypes.bool,
    dndIndex: PropTypes.number, // eslint-disable-line react/no-unused-prop-types
    dndIsDragging: PropTypes.bool,
    dndValidTarget: PropTypes.string, // eslint-disable-line react/no-unused-prop-types
    id: PropTypes.number.isRequired, // Section Component data
    isDeletable: PropTypes.bool, // Show delete action
    moveItem: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    onBeginDrag: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    onEndDrag: PropTypes.func, // eslint-disable-line react/no-unused-prop-types
    openDeleteContent: PropTypes.func.isRequired, // Redux action creator
    openMediaPicker: PropTypes.func.isRequired,
    renderTarget: PropTypes.string.isRequired, // Render "default" or "preview" view
    setComponentOption: PropTypes.func.isRequired, // Redux action creator
    showEditView: PropTypes.bool, // Render as editable component (Redux store property)
    subtitle: optionalComponentProp, // Section Component data
    title: optionalComponentProp, // Section Component data
    tooltip: PropTypes.string, // Section Component data
    type: PropTypes.string, // Section Component data
    url: PropTypes.string, // Section Component data
    visible: PropTypes.oneOfType([
      PropTypes.bool,
      PropTypes.string,
      PropTypes.number,
    ]), // Section Component data
  };

  // Optional component properties (title, subtitle, buttonContent, buttonHyperlink, ... ) will
  // be false if they are not present in the module (aka blocks) definition
  static defaultProps = {
    alt: '',
    buttonContent: false,
    buttonHyperlink: false,
    classes: '',
    connectDragPreview: () => {},
    connectDragSource: () => {},
    connectDropTarget: () => {},
    containerClassName: '',
    dndAllowMove: true,
    dndIndex: 0,
    dndIsDragging: false,
    dndValidTarget: '',
    isDeletable: true,
    moveItem: () => {},
    onBeginDrag: () => {},
    onEndDrag: () => {},
    showEditView: true,
    subtitle: false,
    title: false,
    tooltip: '',
    type: CONTENT_COMPONENT_TYPE.galleryImage,
    url: '',
    visible: true,
  };

  static renderDraghandle() {
    return (
      <button type="button" className="button--mini">
        <Icon name="move" small />
      </button>
    );
  }

  constructor(props) {
    super(props);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onMouseOver = this.onMouseOver.bind(this);
    this.onMouseLeave = this.onMouseLeave.bind(this);
    this.onComponentContentChange = this.onComponentContentChange.bind(this);
    this.onMediaPickerClick = this.onMediaPickerClick.bind(this);
    this.setImageRef = this.setImageRef.bind(this);
    this.state = {
      isHovered: false,
    };
  }

  onComponentContentChange(componentProps, newContent) {
    const { type: option } = componentProps; // the component property to update (title, ... )
    const { id: contentId } = this.props; // The id of the component
    this.props.setComponentOption({
      option,
      value: newContent,
      contentId,
    });
  }

  onMouseOver() {
    this.setState({ isHovered: true });
  }

  onMouseLeave() {
    this.setState({ isHovered: false });
  }

  onDeleteClick(evt) {
    evt.preventDefault();
    const { id, type } = this.props;
    this.props.openDeleteContent({ id, type });
  }

  onMediaPickerClick(evt) {
    evt.preventDefault();
    const {
      id,
      type,
    } = this.props;
    const bbox = this.imageRef ? this.imageRef.getBoundingClientRect() : { width: 0, height: 0 };
    const { width, height } = bbox;
    this.props.openMediaPicker({
      id, type, width, height,
    });
  }

  setImageRef(ref) {
    if (ref) {
      this.imageRef = ref;
    }
  }

  renderImage() {
    const {
      alt,
      url,
      tooltip,
      id,
      containerClassName,
      renderTarget,
    } = this.props;
    const key = `${id}-image`;
    const component = {
      id, url, tooltip, alt,
    };
    return (
      <ContentComponentImage
        key={key}
        {...component}
        containerClassName={containerClassName}
        renderTarget={renderTarget}
        imageRef={this.setImageRef}
        type={CONTENT_COMPONENT_TYPE.sliderImage}
      />
    );
  }

  renderDelete() {
    return (
      <button type="button" onClick={this.onDeleteClick} className="button--mini">
        <Icon name="delete" />
      </button>
    );
  }

  renderImageManager() {
    return (
      <button type="button" onClick={this.onMediaPickerClick} className="button--mini">
        <Icon name="image" />
      </button>
    );
  }

  renderTitle() {
    const {
      title,
      id,
      renderTarget,
    } = this.props;
    if (title) {
      const component = {
        type: 'title', tag: 'h2', content: title, id,
      };

      return (
        <ContentComponentTitle
          {...component}
          onChange={this.onComponentContentChange}
          renderTarget={renderTarget}
        />
      );
    }

    return null;
  }

  renderSubTitle() {
    const {
      subtitle,
      id,
      renderTarget,
    } = this.props;
    if (subtitle) {
      const component = {
        type: 'title', tag: 'h3', content: subtitle, id,
      };

      return (
        <ContentComponentTitle
          {...component}
          onChange={this.onComponentContentChange}
          renderTarget={renderTarget}
        />
      );
    }
    return null;
  }

  renderButton() {
    const {
      buttonContent: content,
      buttonHyperlink: hyperlink,
      id,
      renderTarget,
    } = this.props;
    if (content) {
      const component = {
        type: 'button', content, hyperlink, id,
      };

      return (
        <ContentComponentButton
          {...component}
          onChange={this.onComponentContentChange}
          renderTarget={renderTarget}
        />
      );
    }
    return null;
  }

  renderEditView() {
    const {
      classes,
      connectDragPreview,
      connectDragSource,
      connectDropTarget,
      dndAllowMove,
      dndIsDragging,
      isDeletable,
    } = this.props;
    const {
      isHovered,
    } = this.state;
    const type = CONTENT_COMPONENT_TYPE.galleryImage;
    const componentClasses = classNames(
      classes,
      `bb-${type}`,
    );
    const opacity = dndIsDragging ? 0.6 : 1;
    const showDragger = dndAllowMove && isHovered;

    return (
      connectDropTarget(
        connectDragPreview(
          <div
            className={componentClasses}
            style={{ ...containerStyles, opacity }}
            onMouseLeave={this.onMouseLeave}
            onMouseOver={this.onMouseOver}
          >
            {this.renderImage()}
            {this.renderTitle()}
            {this.renderSubTitle()}
            {this.renderButton()}
            <div className="c-bb-image-config">
              {isDeletable && this.renderDelete()}
              {this.renderImageManager()}
              {showDragger && connectDragSource(ContentComponentSliderImage.renderDraghandle())}
            </div>
          </div>,
        ),
      )
    );
  }

  renderDefaultView() {
    const {
      classes,
    } = this.props;
    const type = CONTENT_COMPONENT_TYPE.galleryImage;
    const componentClasses = classNames(
      classes,
      `c-${type}`,
    );
    return (
      <div className={componentClasses}>
        {this.renderImage()}
        {this.renderTitle()}
        {this.renderSubTitle()}
        {this.renderButton()}
      </div>
    );
  }

  render() {
    const {
      showEditView,
    } = this.props;
    if (showEditView) {
      return this.renderEditView();
    }
    return this.renderDefaultView();
  }
}

const mapStateToProps = () => ({});
const mapDispatchToProps = {
  openDeleteContent,
  setComponentOption,
  openMediaPicker,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentComponentSliderImage);
