import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { isEqual } from 'lodash';
import { containerClass, contentComponentPropType } from '@liswood-tache/browsbox-static';
import ContentComponents from './ContentComponents';
import { moveComponent } from '../../actions/contentMove';

class BrowsboxSortableList extends Component {
  static propTypes = {
    children: PropTypes.node,
    components: PropTypes.arrayOf(contentComponentPropType),
    contentType: PropTypes.string,
    id: PropTypes.number.isRequired,
    moveComponent: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    type: PropTypes.string, // Override component.type to determine ContentComponents to add
    renderTarget: PropTypes.string.isRequired, // Render "default" or "preview" view
  };

  static defaultProps = {
    children: null,
    components: [],
    contentType: '',
    type: '',
  };

  constructor(props) {
    super(props);
    this.renderComponent = this.renderComponent.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.onBeginDrag = this.onBeginDrag.bind(this);
    this.onEndDrag = this.onEndDrag.bind(this);
    this.state = {
      components: props.components.map(c => ({ ...c })),
      isDragging: false,
    };
  }

  componentDidUpdate(prevProps) {
    const { components } = this.props;

    if (!this.state.isDragging && !isEqual(prevProps.components, components)) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        components: components.map(c => ({ ...c })),
      });
    }
  }

  onBeginDrag(id) {
    this.setState({
      isDragging: id,
    });
  }

  onEndDrag(item) {
    this.setState({
      isDragging: false,
    }, () => {
      if (item.index !== item.originalIndex) {
        const {
          id,
          dndIndex: index,
          originalIndex,
        } = item;
        this.props.moveComponent({ id, index, originalIndex });
      }
    });
  }

  moveItem(dragIndex, hoverIndex) {
    const { components } = this.state;
    const dragCard = components[dragIndex];

    this.setState((prev) => update(prev, {
      components: {
        $splice: [[dragIndex, 1], [hoverIndex, 0, dragCard]],
      },
    }));
  }

  renderComponent(component, idx, components) {
    const {
      onChange, type, id, contentType, renderTarget,
    } = this.props;
    // Only allow gallery images to be dropped into there own gallery section
    const dndValidTarget = `gallery-dnd-${id}`;
    const Tag = ContentComponents[type || component.type];
    const containerClassName = containerClass(contentType, component.type);
    if (Tag) {
      return (
        <Tag
          key={component.id}
          {...component}
          onChange={onChange}
          dndIndex={idx}
          dndValidTarget={dndValidTarget}
          dndAllowMove={components.length > 1} // need more then 1 item to move items in the list
          isDeletable={components.length > 1} // need more then 1 item to delete an item from the list
          moveItem={this.moveItem}
          onBeginDrag={this.onBeginDrag}
          onEndDrag={this.onEndDrag}
          containerClassName={containerClassName}
          renderTarget={renderTarget}
          showEditView
        />
      );
    }
    return <div key={component.id}>Missing component <b>{component.type}</b></div>;
  }

  render() {
    const {
      children,
    } = this.props;
    const {
      components,
    } = this.state;

    const containerStyles = {
      display: 'flex',
      flexFlow: 'row wrap',
    };

    return (
      <div style={containerStyles}>
        {components.map(this.renderComponent)}
        {children}
      </div>
    );
  }
}
const mapStateToProps = () => ({});
const mapDispatchToProps = {
  moveComponent,
};

export default connect(mapStateToProps, mapDispatchToProps)(BrowsboxSortableList);
