import { OPEN_BASE_MODULE_VIEW, CHANGE_BASE_MODULE_SCREEN } from '../actions/baseModule';
import {
  GENERAL_SETTINGS_CLOSE_VIEW, GENERAL_SETTINGS_FAILURE,
  GENERAL_SETTINGS_OPEN_VIEW,
  GENERAL_SETTINGS_REQUEST, GENERAL_SETTINGS_SUCCESS,
} from '../actions/generalSettings';
import { USERS_OPEN_VIEW } from '../actions/users';

const initialState = {
  isOpen: false,
  isLoading: true,
  generalSettings: {
    socialMedia: [],
  },
};

export default function generalSettings(state = initialState, action) {
  switch (action.type) {
    case GENERAL_SETTINGS_OPEN_VIEW: {
      return {
        ...state,
        isOpen: true,
      };
    }

    case OPEN_BASE_MODULE_VIEW:
    case CHANGE_BASE_MODULE_SCREEN:
    case USERS_OPEN_VIEW:
    case GENERAL_SETTINGS_CLOSE_VIEW: {
      return {
        ...state,
        isOpen: false,
      };
    }

    case GENERAL_SETTINGS_REQUEST:
      return {
        ...state,
        isLoading: true,
      };

    case GENERAL_SETTINGS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        generalSettings: action.response.generalSettings,
      };

    case GENERAL_SETTINGS_FAILURE:
      return {
        ...state,
        isLoading: false,
      };

    default:
      return state;
  }
}
