import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { DragSource } from 'react-dnd';
import { withNamespaces } from 'react-i18next';
import { Gateway } from '../Gateway';
import { DND_MENU_MODULE } from '../DragAndDrop/dndTypes';
import { getNewId } from '../../utils/entities';
import i18n from '../../internationalization/i18n';
import ScrollManager from '../../tools/window-scroll-manager';

const manager = new ScrollManager();

const dndSource = {
  beginDrag(props) {
    const id = getNewId();
    manager.init();
    return {
      id,
      dndType: props.dndType,
      dndSource: props.dndType,
      type: props.id,
    };
  },
  endDrag(props, monitor) {
    manager.destroy();
    const item = monitor.getItem();
    const droppedIntoContent = monitor.getDropResult() && monitor.getDropResult().isDroppedIntoContent;
    // Add Content item only if dropped on target
    if (typeof item.index !== 'undefined' && droppedIntoContent) {
      props.addContent(item, props);
    }
  },
};

const dropSourceCollect = (connectDnd, monitor) => ({
  connectDragSource: connectDnd.dragSource(),
  dndIsDragging: monitor.isDragging(),
  clientOffset: monitor.getClientOffset(),
});

@DragSource(DND_MENU_MODULE, dndSource, dropSourceCollect)

class BrowsboxModuleItem extends Component {
  static propTypes = {
    className: PropTypes.string,
    connectDragSource: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
    dndSource: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    dndType: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    id: PropTypes.string,
    label: PropTypes.string,
    img: PropTypes.string,
    index: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
    dndIsDragging: PropTypes.bool.isRequired,
    clientOffset: PropTypes.object, // eslint-disable-line
    addContent: PropTypes.func.isRequired, // eslint-disable-line react/no-unused-prop-types
  };

  static defaultProps = {
    className: '',
    id: '',
    label: null,
    img: '',
  };

  componentDidMount() {
    window.addEventListener('scroll', this.handleWindowScroll);
    window.addEventListener('dragstart', this.handleWindowDragStart, true);
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleWindowScroll);
    window.removeEventListener('dragstart', this.handleWindowDragStart, true);
  }

  handleWindowScroll = () => {
    this.forceUpdate();
  };

  handleWindowDragStart = (event) => {
    event.preventDefault();
  };

  renderDragPreview = () => {
    const {
      img, id, label, clientOffset,
    } = this.props;
    const name = i18n.t([`TYPES.${label || id}`, label || id]);

    if (!clientOffset) {
      return null;
    }

    const transform = `translate(calc(-50% + ${clientOffset.x}px), calc(-50% + ${clientOffset.y}px))`;

    return (
      <Gateway into="popup">
        <div className="c-bb-block-list__drag-preview">
          <div
            className="c-bb-block-list__drag-preview__item o-bb-module"
            style={{ transform }}
          >
            <img className="o-bb-module__icon" src={img} alt={name} data-module={id} />
            <span className="o-bb-module__text">{name}</span>
          </div>
        </div>
      </Gateway>
    );
  };

  render() {
    const {
      className,
      connectDragSource,
      dndIsDragging,
      id,
      label,
      img,
    } = this.props;

    const name = i18n.t([`TYPES.${label || id}`, label || id]);
    const classes = classNames(
      className,
      'o-bb-module',
    );

    const opacity = dndIsDragging ? 0.25 : 1;

    return connectDragSource(
      <li className="c-bb-block-list__item">
        {dndIsDragging && this.renderDragPreview()}
        <div className={classes} style={{ opacity }}>
          <img className="o-bb-module__icon" src={img} alt={name} data-module={id} data-count="1" />
          <span className="o-bb-module__text">{name}</span>
        </div>
      </li>,
    );
  }
}

export default withNamespaces()(BrowsboxModuleItem);
