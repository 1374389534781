import { createSelector } from 'reselect';
import flatten from 'lodash/flatten';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import i18n from '../internationalization/i18n';

export const selectBaseModuleItemById = (state, id) => {
  const item = state.baseModuleItems.entities[id];

  if (!item) {
    // eslint-disable-next-line no-console
    console.warn(`Item with ID: ${id} was not found in app state.`);
  }

  return item;
};

export const selectBaseModuleItemsList = (state) => {
  const { ids } = state.baseModuleItems;

  return ids.map(id => (selectBaseModuleItemById(state, id)));
};

export const getCurrentLang = state => state.baseModule.lang;

export const getDefaultLang = state => state.baseModule.langs.find(lng => lng.default);

export const getFilteredModuleList = (state) => {
  const { modulesList, lang } = state.baseModule;
  return modulesList.filter(mod => mod.language === lang);
};

export const getDefaultModuleList = (state) => {
  const { modulesList } = state.baseModule;
  const defaultLang = getDefaultLang(state);

  if (!defaultLang) {
    return [];
  }

  return modulesList.filter(mod => mod.language === defaultLang.code);
};

const resolveCorrectLanguage = (state) => {
  const uiLang = i18n.language;
  const websiteLanguages = state.baseModule.langs.map(item => item.code);

  // as per requirements in: https://browsbox.atlassian.net/browse/BB-1307
  // IF the current Browsbox language also exist in the Website languages
  // → then take the current Browsbox language for showing the labels.
  if (websiteLanguages.includes(uiLang)) {
    return uiLang;
  }

  // IF that language doesn’t exist in the Website language → take English
  if (websiteLanguages.includes('en')) {
    return 'en';
  }

  // IF English doesn’t exist in the Website languages → take the language you are inserting for (as it is now!)
  return state.baseModule.lang;
};

const translateFieldLabels = (state, module) => {
  const language = resolveCorrectLanguage(state);
  const translation = state.baseModule.modulesList.find(
    item => item.internal_name === module.internal_name && item.language === language,
  );

  if (translation && (translation !== module)) {
    return {
      ...module,
      fields: module.fields.map((field) => {
        const translatedField = translation.fields.find(item => item.internal_name === field.internal_name);

        if (translatedField) {
          return {
            ...field,
            label: translatedField.label,
          };
        }

        return field;
      }),
    };
  }

  return module;
};

const addInternalFieldsPostfix = module => ({
  ...module,
  fields: module.fields.map(field => ({
    ...field,
    label: `${field.label} [${field.internal_name}]`,
    originalLabel: field.label,
  })),
});

export const getModuleByName = (state, name, language = null) => {
  const lang = language || (getDefaultLang(state).code);
  const moduleInDefaultLang = state.baseModule.modulesList.find(
    item => item.internal_name === name && item.language === lang,
  );

  const module = moduleInDefaultLang || state.baseModule.modulesList.find(item => item.internal_name === name);

  return module
    ? addInternalFieldsPostfix(translateFieldLabels(state, module))
    : null;
};

const getModulesList = state => state.baseModule.modulesList;

export const getCurrentModuleFormInputs = currentModule => createSelector(
  [getModulesList],
  (modulesList) => {
    if (!currentModule) return null;
    const langModules = modulesList.filter(module => module.internal_name === currentModule.internal_name);
    const initialValues = {};

    initialValues.id = {};
    initialValues.internalName = currentModule.internal_name;
    initialValues.hasDetailPages = currentModule.has_detail_pages;
    initialValues.sortingEnabled = currentModule.sorting_enabled;
    initialValues.templateRouteTitle = currentModule.template_route_title;
    initialValues.templatePageTitle = currentModule.template_page_title;
    initialValues.templateMetaDescription = currentModule.template_meta_description;

    initialValues.fields = currentModule.fields.map(field => ({
      id: {},
      internalName: field.internal_name,
      label: {},
      type: field.type,
      options: Array.from(field.options || []).filter(Boolean).map(option => option?.label),
      settings: Array.isArray(field.settings) ? {} : field.settings,
    }));
    initialValues.categories = cloneDeep(currentModule.categories);
    initialValues.columns = currentModule.columns;

    const treverseCategories = (formCategories, moduleCategories, lang) => formCategories.map((item, index) => {
      const categoryField = { ...item };
      if (typeof categoryField.title === 'string') categoryField.title = {};

      if (!moduleCategories[index]) {
        return categoryField;
      }

      categoryField.title[lang] = moduleCategories[index].title;

      if (categoryField.image) {
        categoryField.image = {
          ...categoryField.image,
          alt: categoryField.image_alt,
          tooltip: categoryField.image_tooltip,
          hyperlink: categoryField.image_hyperlink,
        };
      }

      if (typeof categoryField.id !== 'object') {
        categoryField.id = {};
      }
      categoryField.id[lang] = moduleCategories[index].id;

      let categories = [];
      const children = get(categoryField, 'children.categories') || categoryField.children;
      if (children && children.length) {
        categories = treverseCategories(children, moduleCategories[index].children, lang);
      }

      categoryField.children = { categories };

      return categoryField;
    });

    langModules.forEach((module) => {
      initialValues.id[module.language] = module.id;
      initialValues[`label_${module.language}`] = module.label;

      initialValues.fields = initialValues.fields.map((item) => {
        const langField = module.fields.find(i => i.internal_name === item.internalName && i.type === item.type);
        const fieldItem = { ...item };
        fieldItem.label[module.language] = get(langField, 'label');
        fieldItem.id[module.language] = get(langField, 'id');

        return fieldItem;
      });
      initialValues.categories = initialValues.categories.map((item, index) => {
        const categoryField = { ...item };

        if (categoryField.image) {
          categoryField.image = {
            ...categoryField.image,
            alt: categoryField.image_alt,
            tooltip: categoryField.image_tooltip,
            hyperlink: categoryField.image_hyperlink,
          };
        }

        if (typeof categoryField.title === 'string') {
          categoryField.title = {};
        }
        categoryField.title[module.language] = get(module, `categories.${index}.title`);

        if (typeof categoryField.id !== 'object') {
          categoryField.id = {};
        }
        categoryField.id[module.language] = get(module, `categories.${index}.id`);

        let categories = [];
        const children = get(categoryField, 'children.categories') || categoryField.children;

        if (children && children.length) {
          categories = treverseCategories(
            children,
            module.categories[index] ? module.categories[index].children : [],
            module.language,
          );
        }

        categoryField.children = { categories };

        return categoryField;
      });
    });

    return initialValues;
  },
);

const getDetailFilters = state => state.baseModuleItems.customDetailFilters;

export const getFilterLabels = createSelector(
  [getDetailFilters],
  (detailFilter) => {
    const normalizedFilters = {};
    const filters = flatten(detailFilter.map(filter => filter.filters));
    filters.forEach((filter) => {
      normalizedFilters[filter.id] = filter.label;
    });
    return normalizedFilters;
  },
);

export const selectBaseModuleIsLoading = state => (
  state.baseModule.fetchingLangs
  || state.baseModule.fetchingFieldTypes
  || state.baseModule.fetchingModulesList
  || state.baseModule.fetchingUpdateModule
  || state.baseModule.fetchingDeleteModule
);

export const selectBaseModuleItemsIsLoading = state => (
  state.baseModuleItems.fetchingItemsList
  || state.baseModuleItems.fetchItemSave
  || state.baseModuleItems.fetchItemDelete
);

export const selectLanguages = state => Object.values(state.entities.languages);

export const selectIsMultiLanguage = state => (selectLanguages(state) || []).length > 1;
