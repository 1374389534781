import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { contentColumnPropType, contentComponentPropType, contentPropType } from './ContentPropTypes';
import ContentComponents from './ContentComponents';
import { containerClass } from './utils/content';
import CONTENT_COMPONENT_TYPE from './ContentComponents/types';
import CONTENT_SECTION_TYPE from './ContentSections/types';
import { BUTTON_MODE } from './constants';
import { isVideoUrl } from './utils/media';

class BrowsboxContentColumn extends Component {
  static propTypes = {
    className: PropTypes.string,
    column: contentColumnPropType.isRequired,
    components: PropTypes.arrayOf(contentComponentPropType),
    onChange: PropTypes.func,
    children: PropTypes.node,
    type: PropTypes.string, // Override component.type to determine ContentComponents to add
    content: contentPropType.isRequired,
  };

  static defaultProps = {
    className: '',
    components: [],
    children: null,
    type: '',
    contentType: '',
    onChange: () => {},
  };

  constructor(props) {
    super(props);
    this.renderComponent = this.renderComponent.bind(this);
  }

  shouldRenderComponent(component) {
    const { content: { options, type: contentType } } = this.props;
    const { type } = component;

    if ([CONTENT_SECTION_TYPE.blockBuilder, CONTENT_SECTION_TYPE.footerBuilder].includes(contentType)) {
      // block builder should render all components without additional conditions
      return true;
    }

    if (type === CONTENT_COMPONENT_TYPE.button) {
      return options.primaryButtonsActive
        && options.buttonDisplay === BUTTON_MODE.BUTTON_PER_COLUMN;
    }

    if (type === CONTENT_COMPONENT_TYPE.buttonSecondary) {
      return options.secondaryButtonsActive
        && options.buttonDisplay === BUTTON_MODE.BUTTON_PER_COLUMN;
    }

    return true;
  }

  renderComponent(component, idx) {
    const {
      onChange,
      type,
      content,
    } = this.props;

    if (!this.shouldRenderComponent(component)) {
      return null;
    }

    let componentTag = type || component.type;
    if (componentTag === 'image' && isVideoUrl(component.url)) {
      componentTag = 'video';
    }
    const Tag = ContentComponents[componentTag];
    const containerClassName = containerClass(content.type, component.type);
    if (Tag) {
      return (
        <Tag
          key={idx}
          onChange={onChange}
          containerClassName={containerClassName}
          {...component}
        />
      );
    }
    return <div key={idx}>Missing component <b>{component.type}</b></div>;
  }

  render() {
    const {
      className,
      column,
      components,
      children,
      type,
    } = this.props;

    const classes = classNames(
      'l-column',
      className,
      column.classes,
      { [`o-content-column-${type}`]: type },
    );

    if (column.enabled === false) {
      return null;
    }

    return (
      <div
        className={classes}
        id={column.id}
      >
        {children}
        {components.map(this.renderComponent)}
      </div>
    );
  }
}

export default BrowsboxContentColumn;
