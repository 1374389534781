/* eslint-disable import/prefer-default-export */
import isEmail from 'validator/lib/isEmail';
import isUrl from 'validator/lib/isURL';
import { LINK_TYPE_EXTERNAL, LINK_TYPE_EMAIL, completeUrl } from '@liswood-tache/browsbox-static';
import i18n from '../../internationalization/i18n';
import { LINK_TYPE_PHONE } from './Links';

export const validate = (link) => {
  const { type, value } = link;
  // validate email and external url
  if (type === LINK_TYPE_EXTERNAL && !isUrl(value)) {
    return i18n.t('CONTENT.invalidUrl');
  } if (type === LINK_TYPE_EMAIL && !isEmail(value)) {
    return i18n.t('CONTENT.invalidEmail');
  }
  return true;
};

export const convertUriToHyperlink = (uri, type) => {
  if (type === LINK_TYPE_EMAIL && uri.indexOf('mailto:') !== 0) {
    return `mailto:${uri}`;
  }

  if (type === LINK_TYPE_PHONE && uri.indexOf('tel:') !== 0) {
    return `tel:${uri}`;
  }

  if (type === LINK_TYPE_EXTERNAL) {
    return completeUrl(uri);
  }

  return uri;
};
