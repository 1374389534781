import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CONTENT_SECTION_TYPE, contentPropType, DEFAULT_COLUMN_COUNT } from '@liswood-tache/browsbox-static';
import BrowsboxContentResizableColumnsList from '../ContentResizableColumnsList';

const propTypes = {
  content: contentPropType.isRequired,
  title: PropTypes.func.isRequired,
  subTitle: PropTypes.func.isRequired,
  column: PropTypes.func.isRequired,
  text: PropTypes.func.isRequired,
  button: PropTypes.func.isRequired,
};

const defaultProps = {};

const ContentSectionBase = (props) => {
  const {
    content: {
      id, columns, type, options: { columnCount },
    },
    title,
    subTitle,
    text,
    button,
    column,
  } = props;

  const columnsCountClass = type === CONTENT_SECTION_TYPE.gallery
    ? `c-${type}__${columnCount || DEFAULT_COLUMN_COUNT}columns` : '';
  const lightboxGroupClass = type === CONTENT_SECTION_TYPE.gallery
    ? 'js-lightbox-group' : '';
  const mainClasses = classNames(
    `c-section__main ${lightboxGroupClass}`,
    columnsCountClass,
    'l-columns',
  );

  return (
    <div className="c-section__wrapper" key={id}>
      <header className="c-section__header">
        { title() }
        { subTitle() }
      </header>

      <BrowsboxContentResizableColumnsList
        className={mainClasses}
        columns={columns}
        contentId={id}
        renderColumn={column}
      />

      <footer className="c-section__footer">
        { text() }
        { button() }
      </footer>
    </div>
  );
};

ContentSectionBase.propTypes = propTypes;
ContentSectionBase.defaultProps = defaultProps;

export default ContentSectionBase;
