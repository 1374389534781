import React from 'react';
import PropTypes from 'prop-types';
import { BbPopup } from '@browsbox-ui';
import { useDispatch } from 'react-redux';
import { Gateway } from '../Gateway';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import BlockPicker from './BlockBackgroundImagePicker';
import { openMediaPicker } from '../../actions/mediaManager';

const Popup = ({
  show, onClose, onChange, onImageClick, value, tetherTarget,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Gateway into="popup">
      <BbPopup
        placement="right"
        target={tetherTarget}
        onClose={onClose}
        closeOnClickInside={false}
        className="o-bb-popup--config o-bb-popup--background"
      >
        <BlockPicker
          value={value}
          onChange={onChange}
          onImageClick={onImageClick}
        />
      </BbPopup>
    </Gateway>
  );
};

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  tetherTarget: PropTypes.string.isRequired,
  value: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundPosition: PropTypes.string.isRequired,
    backgroundBehavior: PropTypes.string.isRequired,
    backgroundTransparency: PropTypes.number.isRequired,
    backgroundScrolling: PropTypes.bool.isRequired,
  }).isRequired,
};

const BrowsboxContentBackgroundImage = ({
  className, children, contentId, contentType, onChange, value, showPopup, setShowPopup,
}) => {
  const dispatch = useDispatch();

  const tetherTarget = 'content-background';

  const handleOnClick = (evt) => {
    evt.preventDefault();
    setShowPopup(!showPopup);
  };

  const handleOnImageClick = () => {
    setShowPopup(false);
    dispatch(openMediaPicker({ id: contentId, type: contentType }));
  };

  const handleOnClose = () => setShowPopup(false);

  return (
    <a href="#" onClick={handleOnClick} className={className} id={tetherTarget}>
      <FontAwesomeIcon name="fas fa-image-landscape" />
      {children}
      <Popup
        show={showPopup}
        onChange={onChange}
        onClose={handleOnClose}
        onImageClick={handleOnImageClick}
        value={value}
        tetherTarget={tetherTarget}
      />
    </a>
  );
};

BrowsboxContentBackgroundImage.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundPosition: PropTypes.string.isRequired,
    backgroundBehavior: PropTypes.string.isRequired,
    backgroundTransparency: PropTypes.number.isRequired,
    backgroundScrolling: PropTypes.bool.isRequired,
  }).isRequired,
  contentId: PropTypes.oneOfType([PropTypes.object, PropTypes.number]).isRequired,
  contentType: PropTypes.oneOf(['section', 'column']).isRequired,
  showPopup: PropTypes.bool.isRequired,
  setShowPopup: PropTypes.func.isRequired,
};

BrowsboxContentBackgroundImage.defaultProps = {
  className: 'o-bb-popup__bgimg',
  children: null,
  backgroundImage: null,
};

export default BrowsboxContentBackgroundImage;
