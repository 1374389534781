import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import i18n from '../../internationalization/i18n';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const Block = ({
  items,
  value,
  onChange: handleChange,
}) => {
  const handleClick = (itemValue) => (e) => {
    e.preventDefault();

    handleChange(itemValue === value ? null : itemValue);
  };

  return (
    <div className="o-bb-section-config__group">
      {items.map(([itemValue, icon, label]) => (
        <div className="o-bb-section-config__row" key={itemValue}>
          <a
            href="#"
            onClick={handleClick(itemValue)}
            className={classNames('o-bb-popup--config-action', { active: itemValue === value })}
          >
            <FontAwesomeIcon name={icon} />
            {' '}
            {i18n.t(label)}
          </a>
        </div>
      ))}
    </div>
  );
};

Block.propTypes = {
  items: PropTypes.array.isRequired,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
};

export default Block;
