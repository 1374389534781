/* eslint-disable import/prefer-default-export */

export const classNameToTokens = (className) => {
  if (!className) {
    return [];
  }

  return className
    .split(' ')
    .map((token) => token.trim())
    .filter(Boolean);
};
