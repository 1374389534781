import React, { useState } from 'react';
import classNames from 'classnames';
import { ContentItemBackground, contentPropType } from '@liswood-tache/browsbox-static';
import PropTypes from 'prop-types';
import Footer from './Footer';
import FooterPortal from './FooterPortal';
import FooterSectionPortal from './FooterSectionPortal';
import FooterConfig from './FooterConfig';
import SuperAdminOnly from '../../AcessControll/SuperAdminOnly';
import useShowUiControls from '../../../hooks/use-show-ui-controls';

function FooterContainer({ content }) {
  const [isHovered, setIsHovered] = useState(false);
  const [configIsShown, setConfigIsShown] = useState(false);
  const showUiControls = useShowUiControls();

  const footer = content?.[0];
  const footerOptions = footer?.options || {};

  if (!footer) {
    return null;
  }

  const configAttributes = {
    backgroundFullWidth: Boolean(footerOptions.backgroundFullWidth),
    backgroundImage: footerOptions.backgroundImage,
    backgroundPosition: footerOptions.backgroundPosition,
    backgroundBehavior: footerOptions.backgroundBehavior,
    backgroundTransparency: Number(footerOptions.backgroundTransparency),
    backgroundScrolling: Boolean(footerOptions.backgroundScrolling),
    contentId: footer?.id,
    contentType: footer?.type,
    contentColor: footerOptions.backgroundColor,
    contentColorSchemeClass: footerOptions.colorSchemeClass,
    currentColumnCount: footer.columns.filter((column) => column.visible !== false).length,
    onHidePopup: () => setConfigIsShown(false),
    onShowPopup: () => setConfigIsShown(true),
  };

  const handleOnMouseOver = () => setIsHovered(true);
  const handleOnMouseLeave = () => setIsHovered(false);

  const backgroundPortalClassNames = classNames(
    'c-footer__background',
    { 'c-footer__background--boxed': !footerOptions?.backgroundFullWidth },
  );

  const configPortalClassNames = 'c-bb-section-config';

  return (
    <Footer
      className={footerOptions?.colorSchemeClass}
      onMouseOver={handleOnMouseOver}
      onMouseLeave={handleOnMouseLeave}
    >
      {(isHovered || configIsShown) && showUiControls && (
        <>
          <FooterPortal className="c-footer__hover-overlay" />
          <SuperAdminOnly>
            <FooterPortal className={configPortalClassNames}>
              <div className="c-bb-section-config__wrapper">
                <FooterConfig {...configAttributes} />
              </div>
            </FooterPortal>
          </SuperAdminOnly>
        </>
      )}

      <FooterPortal className={backgroundPortalClassNames}>
        <ContentItemBackground
          position={footerOptions?.backgroundPosition}
          behavior={footerOptions?.backgroundBehavior}
          transparency={Number(footerOptions?.backgroundTransparency)}
          scrolling={Boolean(footerOptions?.backgroundScrolling)}
          image={footerOptions?.backgroundImage}
        />
      </FooterPortal>

      <FooterSectionPortal footer={footer} />
    </Footer>
  );
}

FooterContainer.propTypes = {
  content: PropTypes.arrayOf(contentPropType).isRequired,
};

export default FooterContainer;
