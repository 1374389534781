import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { SubmissionError } from 'redux-form';
import { withNamespaces } from 'react-i18next';
import { closeGeneralSettingsView, loadGeneralSettings, saveGeneralSettings } from '../../actions/generalSettings';
import { isValidationError, transformValidationErrors } from '../../tools/validation';
import GeneralSettingsForm from './GeneralSettingsForm';
import GeneralSettingHeader from './GeneralSettingHeader';

function GeneralSettingsView({ t }) {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(loadGeneralSettings());
  }, []);

  const generalSettings = useSelector((state) => state.generalSettings.generalSettings);
  const isLoading = useSelector((state) => state.generalSettings.isLoading);

  const handleOnClose = () => dispatch(closeGeneralSettingsView());

  const handleOnSubmit = async (data) => {
    await dispatch(
      saveGeneralSettings(data, (error) => {
        if (isValidationError(error)) {
          throw new SubmissionError(transformValidationErrors(error));
        }
      }),
    );

    handleOnClose();
  };

  if (isLoading) {
    return false;
  }

  return (
    <div className="c-bb-settings-page">
      <GeneralSettingHeader title={t('GENERAL_SETTINGS.generalSettings')} onClose={handleOnClose} />
      <main className="c-bb-settings-page__main">
        <GeneralSettingsForm initialValues={generalSettings} onSubmit={handleOnSubmit} onCancel={handleOnClose} />
      </main>
    </div>
  );
}

GeneralSettingsView.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withNamespaces()(GeneralSettingsView);
