import { useEffect, useState } from 'react';
import { useDragDropManager } from 'react-dnd';

export default function useDragDropCollectedProps(collect) {
  const [collectedProps, setCollectedProps] = useState({});
  const manager = useDragDropManager();

  useEffect(() => {
    const monitor = manager.getMonitor();

    return monitor.subscribeToStateChange(() => {
      const nextCollectedProps = collect(monitor);

      setCollectedProps(nextCollectedProps);
    });
  }, [manager]);

  return collectedProps;
}
