import { ROUTE_GENERAL_SETTINGS } from '../api/routes';
import { CALL_BB_API } from '../api';

export const GENERAL_SETTINGS_OPEN_VIEW = 'GENERAL_SETTINGS_OPEN_VIEW';
export const GENERAL_SETTINGS_CLOSE_VIEW = 'GENERAL_SETTINGS_CLOSE_VIEW';

export const openGeneralSettingsView = () => ({
  type: GENERAL_SETTINGS_OPEN_VIEW,
});

export const closeGeneralSettingsView = () => ({
  type: GENERAL_SETTINGS_CLOSE_VIEW,
});

export const GENERAL_SETTINGS_REQUEST = 'GENERAL_SETTINGS_REQUEST';
export const GENERAL_SETTINGS_SUCCESS = 'GENERAL_SETTINGS_SUCCESS';
export const GENERAL_SETTINGS_FAILURE = 'GENERAL_SETTINGS_FAILURE';

const fetchGeneralSettings = () => ({
  [CALL_BB_API]: {
    types: [GENERAL_SETTINGS_REQUEST, GENERAL_SETTINGS_SUCCESS, GENERAL_SETTINGS_FAILURE],
    endpoint: ROUTE_GENERAL_SETTINGS,
    method: 'GET',
  },
});

export const loadGeneralSettings = () => dispatch => dispatch(fetchGeneralSettings());

export const GENERAL_SETTINGS_SAVE_REQUEST = 'GENERAL_SETTINGS_SAVE_REQUEST';
export const GENERAL_SETTINGS_SAVE_SUCCESS = 'GENERAL_SETTINGS_SAVE_SUCCESS';
export const GENERAL_SETTINGS_SAVE_FAILURE = 'GENERAL_SETTINGS_SAVE_FAILURE';

export const saveGeneralSettings = (data, onError) => ({
  [CALL_BB_API]: {
    types: [GENERAL_SETTINGS_SAVE_REQUEST, GENERAL_SETTINGS_SAVE_SUCCESS, GENERAL_SETTINGS_SAVE_FAILURE],
    endpoint: ROUTE_GENERAL_SETTINGS,
    method: 'PUT',
    json: data,
    options: {
      onError,
    },
  },
});
