import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { BUTTON_MODE, CONTENT_SECTION_TYPE } from '@liswood-tache/browsbox-static';
import classNames from 'classnames';
import { setContentOption } from '../../actions/contentConfig';
import { getContentById } from '../../selectors/entities';
import Icon from '../Icon/Icon';
import i18n from '../../internationalization/i18n';

class ContentConfigButton extends React.Component {
  setContentOption = (option, value) => {
    this.props.setContentOption({
      contentId: this.props.contentId,
      option,
      value,
    });
  };

  setContentButtonTypeOption = value => this.setContentOption('buttonDisplay', value);

  setContentButtonAlignOption = value => this.setContentOption('buttonAlign', value);

  setOnePerColumn = () => {
    this.setContentOption('primaryButtonsActive', true);
    this.setContentButtonTypeOption(BUTTON_MODE.BUTTON_PER_COLUMN);
  };

  setOnePerLayout = () => {
    this.setContentOption('primaryButtonsActive', true);
    this.setContentButtonTypeOption(BUTTON_MODE.BUTTON_PER_LAYOUT);
  };

  setButtonAlignLeft = () => this.setContentButtonAlignOption('left');

  setButtonAlignCenter = () => this.setContentButtonAlignOption('center');

  setButtonAlignRight = () => this.setContentButtonAlignOption('right');

  toggleSecondaryButtons = () => {
    const { content: { options: { secondaryButtonsActive } } } = this.props;

    this.setContentOption('secondaryButtonsActive', !secondaryButtonsActive);
  };

  togglePrimaryButton = () => {
    const { content: { options: { primaryButtonsActive, buttonDisplay } } } = this.props;

    this.setContentOption('primaryButtonsActive', !primaryButtonsActive);

    if (primaryButtonsActive) {
      // when toggling off primary buttons, secondary buttons should be also toggled off
      // https://browsbox.atlassian.net/browse/BB-604
      this.setContentOption('secondaryButtonsActive', false);
    }

    if (!primaryButtonsActive && buttonDisplay === BUTTON_MODE.NO_BUTTON) {
      this.setOnePerLayout();
    }
  };

  isPossibleButtonsManagement = () => {
    const { type } = this.props.content;
    const formPrefix = 'form-';
    const disabledSections = [
      'slider',
      'h1',
      'h2',
      'imageTextHorizontal',
      'gallery',
      'promo',
      'googleMaps',
    ];

    return !disabledSections.includes(type) && !type.startsWith(formPrefix);
  };

  isPrimaryButtonsActive = () => {
    const { content } = this.props;
    const { primaryButtonsActive } = content.options;

    return Boolean(primaryButtonsActive);
  };

  isExtraOptionsDisabled = () => {
    const { content } = this.props;

    if (content.type === CONTENT_SECTION_TYPE.blockBuilder) {
      return false;
    }

    return !this.isPrimaryButtonsActive();
  };

  hasLimitedButtonsManagement = () => {
    const { content } = this.props;

    return content.type === CONTENT_SECTION_TYPE.blockBuilder;
  };

  renderButtonModes = () => {
    const { content } = this.props;
    const { primaryButtonsActive, buttonDisplay } = content.options;
    const noButtonSettingsId = `section-settings-no-button-${content.id}`;
    const buttonPerColumnSettingsId = `section-settings-button-per-column-${content.id}`;
    const buttonPerLayoutSettingsId = `section-settings-button-per-layout-${content.id}`;

    return (
      <div className="o-bb-section-config__group">
        <div className="o-bb-section-config__checkbox">
          <input
            className="o-bb-checkbox__input"
            id={noButtonSettingsId}
            type="checkbox"
            checked={Boolean(primaryButtonsActive)}
            onChange={this.togglePrimaryButton}
          />
          <label className="o-bb-checkbox__label" htmlFor={noButtonSettingsId}>
            {i18n.t('CONTENT.showButton')}
          </label>
        </div>
        <div className={classNames(
          'o-bb-section-config__extra-options',
          'o-bb-section-config__extra-options--nested',
          { 'o-bb-section-config__extra-options--disabled': this.isExtraOptionsDisabled() || this.hasLimitedButtonsManagement() },
        )}
        >
          <div className="o-bb-section-config__radiobutton">
            <input
              name="button_mode"
              className="o-bb-radiobutton__input"
              id={buttonPerColumnSettingsId}
              type="radio"
              value={BUTTON_MODE.BUTTON_PER_COLUMN}
              checked={buttonDisplay === BUTTON_MODE.BUTTON_PER_COLUMN}
              onChange={this.setOnePerColumn}
            />
            <label className="o-bb-radiobutton__label" htmlFor={buttonPerColumnSettingsId}>
              {i18n.t('CONTENT.buttonPerColumn')}
            </label>
          </div>
          <div className="o-bb-section-config__radiobutton">
            <input
              name="button_mode"
              className="o-bb-radiobutton__input"
              id={buttonPerLayoutSettingsId}
              type="radio"
              value={BUTTON_MODE.BUTTON_PER_LAYOUT}
              checked={buttonDisplay === BUTTON_MODE.BUTTON_PER_LAYOUT}
              onChange={this.setOnePerLayout}
            />
            <label className="o-bb-radiobutton__label" htmlFor={buttonPerLayoutSettingsId}>
              {i18n.t('CONTENT.buttonPerLayout')}
            </label>
          </div>
        </div>
      </div>
    );
  };

  renderAlignmentSettings = () => {
    const {
      content: {
        options: {
          buttonAlign,
        },
      },
    } = this.props;

    return (
      <div className="o-bb-section-config__group">
        <div className="o-bb-group__title">
          {i18n.t('CONTENT.alignment')}
        </div>
        <div className="o-bb-section-config__row o-bb-section-config__row--icon-buttons">
          <button
            type="button"
            className={`o-bb-section-config__icon-button${(buttonAlign === 'left' ? ' o-bb-section-config__icon-button--active' : '')}`}
            onClick={this.setButtonAlignLeft}
          >
            <Icon name="align-left" />
          </button>
          <button
            type="button"
            className={`o-bb-section-config__icon-button${(buttonAlign === 'center' ? ' o-bb-section-config__icon-button--active' : '')}`}
            onClick={this.setButtonAlignCenter}
          >
            <Icon name="align-center" />
          </button>
          <button
            type="button"
            className={`o-bb-section-config__icon-button${(buttonAlign === 'right' ? ' o-bb-section-config__icon-button--active' : '')}`}
            onClick={this.setButtonAlignRight}
          >
            <Icon name="align-right" />
          </button>
        </div>
      </div>
    );
  };

  renderPrimaryButtonSettings = () => {
    const { content } = this.props;
    const { primaryButtonsActive } = content.options;
    const settingsId = `section-settings-primary-button-${content.id}`;

    return (
      <div className="o-bb-section-config__group">
        <div className="o-bb-section-config__checkbox">
          <input
            className="o-bb-checkbox__input"
            id={settingsId}
            type="checkbox"
            checked={Boolean(primaryButtonsActive)}
            onChange={this.togglePrimaryButton}
          />
          <label className="o-bb-checkbox__label" htmlFor={settingsId}>
            {i18n.t('CONTENT.showButton')}
          </label>
        </div>
      </div>
    );
  };

  renderSecondaryButtonSettings = () => {
    const { content } = this.props;
    const { secondaryButtonsActive } = content.options;
    const settingsId = `section-settings-secondary-button-${content.id}`;

    return (
      <div className={classNames(
        'o-bb-section-config__group',
        { 'o-bb-section-config__extra-options--disabled': !this.isPrimaryButtonsActive() },
      )}
      >
        <div className="o-bb-section-config__checkbox">
          <input
            className="o-bb-checkbox__input"
            id={settingsId}
            type="checkbox"
            checked={Boolean(secondaryButtonsActive)}
            onChange={this.toggleSecondaryButtons}
          />
          <label className="o-bb-checkbox__label" htmlFor={settingsId}>
            {i18n.t('CONTENT.secondaryButtons')}
          </label>
        </div>
      </div>
    );
  };

  renderButtonSettings = () => {
    if (this.isPossibleButtonsManagement()) {
      return this.renderButtonModes();
    }

    return this.renderPrimaryButtonSettings();
  };

  render() {
    return (
      <div className="o-bb-section-config">
        {this.renderButtonSettings()}
        <div className={classNames(
          'o-bb-section-config__extra-options',
          { 'o-bb-section-config__extra-options--disabled': this.isExtraOptionsDisabled() },
        )}
        >
          {this.renderAlignmentSettings()}
          {this.renderSecondaryButtonSettings()}
        </div>
      </div>
    );
  }
}

ContentConfigButton.propTypes = {
  contentId: PropTypes.number.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  content: PropTypes.object.isRequired,
  setContentOption: PropTypes.func.isRequired,
};

const mapStateToProps = (state, props) => {
  const content = getContentById(state, props.contentId);

  return {
    content,
  };
};

const mapDispatchToProps = {
  setContentOption,
};

export default connect(mapStateToProps, mapDispatchToProps)(ContentConfigButton);
