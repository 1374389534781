import ContentComponentHr from './ContentComponentHr';
import ContentComponentButton from './ContentComponentButton';
import ContentComponentImage from './ContentComponentImage';
import ContentComponentGalleryImage from './ContentComponentGalleryImage';
import ContentComponentText from './ContentComponentText';
import ContentComponentTitle from './ContentComponentTitle';
import ContentComponentSubtitle from './ContentComponentSubtitle';
import ContentComponentHtml from './ContentComponentHtml';
import ContentComponentMaps from './ContentComponentMaps';
import ContentComponentSliderImage from './ContentComponentSliderImage';
import ContentComponentVideo from './ContentComponentVideo';
import ContentComponentDynamic from './ContentComponentDynamic';
import ContentComponentMultiLocationMap from './ContentComponentMultiLocationMap';
import ContentComponentSocialMedia from './ContentComponentSocialMedia';

const ContentComponents = {
  button: ContentComponentButton,
  buttonSecondary: ContentComponentButton,
  galleryImage: ContentComponentGalleryImage,
  googleMaps: ContentComponentMaps,
  hr: ContentComponentHr,
  html: ContentComponentHtml,
  image: ContentComponentImage,
  multiLocationMap: ContentComponentMultiLocationMap,
  sliderImage: ContentComponentSliderImage,
  subtitle: ContentComponentSubtitle,
  text: ContentComponentText,
  title: ContentComponentTitle,
  video: ContentComponentVideo,
  dynamic: ContentComponentDynamic,
  socialMedia: ContentComponentSocialMedia,
};

export default ContentComponents;
