import React, { useMemo } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import BrowsboxContentResizableColumnsList from '../ContentResizableColumnsList';
import BrowsboxContentColumn from '../ContentColumn';
import ContentItemContext from '../ContentItemContext';
import { setComponentOption, setContentOption } from '../../../actions/contentConfig';

function FooterColumn({
  column, idx, columns, moduleOptions, content, renderTarget,
}) {
  const dispatch = useDispatch();

  const handleComponentContentChange = (componentProps, newContent) => {
    const { fromSectionOption } = componentProps;
    if (fromSectionOption) {
      dispatch(setContentOption({
        option: fromSectionOption,
        value: newContent,
        contentId: content.id,
      }));
    } else {
      dispatch(setComponentOption({
        option: 'content',
        value: newContent,
        contentId: componentProps.id,
      }));
    }
  };

  if (column.visible === false) {
    return null;
  }

  const { options } = column;

  const classes = classNames(
    options.colorSchemeClass,
    { [`l-column--${options.size}`]: typeof options.size !== 'undefined' },
    { [`l-column--v-align-${options.verticalAlignment}`]: options.verticalAlignment },
    { [`l-column--h-align-${options.horizontalAlignment}`]: options.horizontalAlignment },
  );

  const columnCount = columns.filter(c => c.visible !== false).length;

  return (
    <BrowsboxContentColumn
      className={classes}
      content={content}
      column={column}
      moduleOptions={moduleOptions}
      components={column.components}
      contentType={content.type}
      onChange={handleComponentContentChange}
      renderTarget={renderTarget}
      isLast={idx === columnCount - 1}
    />
  );
}

function FooterSection({ content }) {
  const moduleOptions = useSelector((state) => state.entities.modules[content.type].options);
  const renderTarget = useSelector((state) => state.options.view.responsiveView);

  const contextValue = useMemo(() => ({ content }), [content]);

  return (
    <ContentItemContext.Provider value={contextValue}>
      <BrowsboxContentResizableColumnsList
        className="l-columns"
        columns={content.columns}
        contentId={content.id}
        renderColumn={(column, idx, columns) => (
          <FooterColumn
            key={column.id}
            column={column}
            idx={idx}
            columns={columns}
            moduleOptions={moduleOptions}
            content={content}
            renderTarget={renderTarget}
          />
        )}
      />
    </ContentItemContext.Provider>
  );
}

export default FooterSection;
