/* eslint-disable import/prefer-default-export */

const prefixedColorSchemeClasses = (colorSchemeClasses, prefix) => {
  if (!colorSchemeClasses) {
    return {};
  }

  return Object.entries(colorSchemeClasses)
    .reduce((accumulator, [colorSchemeClass, color]) => {
      const unprefixedColorSchemeClass = (colorSchemeClass || '').replace(/^[\w-]+--/, '');
      const prefixedColorSchemeClass = `${prefix}${unprefixedColorSchemeClass}`;

      return {
        ...accumulator,
        [prefixedColorSchemeClass]: color,
      };
    }, {});
};

export const sectionColorSchemeClasses = (colorSchemeClasses) => prefixedColorSchemeClasses(colorSchemeClasses, 'c-section--');
export const columnColorSchemeClasses = (colorSchemeClasses) => prefixedColorSchemeClasses(colorSchemeClasses, 'l-column--');
export const footerColorSchemeClasses = (colorSchemeClasses) => prefixedColorSchemeClasses(colorSchemeClasses, 'c-footer--');
