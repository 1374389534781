import { useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';

function useContainerRef(className) {
  const element = useRef();

  if (element.current) {
    return element;
  }

  element.current = document.createElement('div');

  const existingElement = document.querySelector(`[class="${className}"]`);

  if (existingElement) {
    element.current = existingElement;
    element.current.innerHTML = '';
  }

  return element;
}

function FooterPortal({ children, className }) {
  const containerElement = useContainerRef(className);

  useEffect(() => {
    containerElement.current.setAttribute('class', className);
  }, [className]);

  useEffect(() => {
    const footerElement = document.querySelector('.js-footer');

    if (!footerElement) {
      // eslint-disable-next-line no-console
      console.warn('The .js-footer element doesn\'t exist');

      return () => {
      };
    }

    if (!footerElement.contains(containerElement.current)) {
      footerElement.prepend(containerElement.current);
    }

    return () => {
      if (footerElement.contains(containerElement.current)) {
        footerElement.removeChild(containerElement.current);
      }
    };
  });

  return createPortal(children, containerElement.current);
}

FooterPortal.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
};

FooterPortal.defaultProps = {
  className: '',
};

export default FooterPortal;
