export function move(array, from, to) {
  const nextArray = array.slice();

  nextArray.splice(to < 0 ? nextArray.length + to : to, 0, nextArray.splice(from, 1)[0]);

  return nextArray;
}

export default {
  move,
};
