import React from 'react';
import { withNamespaces } from 'react-i18next';
import PropTypes from 'prop-types';
import { Field, FieldArray, reduxForm } from 'redux-form';
import { useSelector } from 'react-redux';
import { BbButtonGroup } from '../@browsbox-ui';
import TextField from '../FormElements/TextField';

const socialMediaTitles = {
  facebook: 'Facebook',
  linkedin: 'LinkedIn',
  twitter: 'Twitter',
  instagram: 'Instagram',
  youtube: 'YouTube',
  pinterest: 'Pinterest',
  tiktok: 'TikTok',
};

function GeneralSettingsFormSocialMediaField({ field, index }) {
  const name = useSelector((state) => state.generalSettings.generalSettings.socialMedia[index].name);
  let title = name;

  if (name in socialMediaTitles) {
    title = socialMediaTitles[name];
  }

  return (
    <div className="c-bb-form__input-wrap">
      <Field key={field} name={`${field}.url`} component={TextField} icon={`fa-brands fa-${name}`} placeholder={title} />
    </div>
  );
}

function GeneralSettingsFormSocialMedia({ fields }) {
  return fields.map((field, index) => <GeneralSettingsFormSocialMediaField key={field} field={field} index={index} />);
}

function GeneralSettingsForm({
  handleSubmit, onCancel, submitting, t,
}) {
  return (
    <div className="c-bb-form c-bb--form-general-settings">
      <form onSubmit={handleSubmit}>
        <div className="c-bb-form__groups">
          <div className="c-bb-form__group">
            <h3 className="c-bb-form__group-title">
              {t('GENERAL_SETTINGS.socialMedia')}
            </h3>
          </div>
          <FieldArray name="socialMedia" component={GeneralSettingsFormSocialMedia} />
        </div>
        <div className="c-bb-form__actions">
          <BbButtonGroup>
            <button className="c-bb-button c-bb-button--primary" type="submit" disabled={submitting}>
              {t('CONTENT.save').toUpperCase()}
            </button>
            <button onClick={onCancel} className="c-bb-button c-bb-button--secondary" type="button">
              {t('CONTENT.cancel').toUpperCase()}
            </button>
          </BbButtonGroup>
        </div>
      </form>
    </div>
  );
}

GeneralSettingsForm.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  submitting: PropTypes.bool.isRequired,
  t: PropTypes.func.isRequired,
};

export default reduxForm({ form: 'generalSettingsForm' })(withNamespaces()(GeneralSettingsForm));
