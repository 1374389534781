import React from 'react';

// eslint-disable-next-line import/prefer-default-export,no-unused-vars
export const ContentComponentSizeListenerContext = React.createContext((value) => {});

export const withRectListenerContext = (WrappedComponent) => (props) => (
  <ContentComponentSizeListenerContext.Consumer>
    {context => (
      <WrappedComponent {...props} onBoundingClientRectChange={context} />
    )}
  </ContentComponentSizeListenerContext.Consumer>
);
