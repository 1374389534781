import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import BrowsboxModuleList from './BrowsboxModuleList';
import { loadModules } from '../../actions/modules';
import { addContent } from '../../actions/contentAdd';
import { openContentLayout } from '../../actions/contentLayout';
import BrowsboxSavedSectionsList from './BrowsboxSavedSectionsList';
import { loadSavedSections } from '../../actions/savedSections';

const BrowsboxModuleContainer = () => {
  const dispatch = useDispatch();

  const savedSections = useSelector((state) => Object.values(state.savedSections.savedSections));
  // TODO: Extract the list of acceptedContainers values to the dedicated file.
  const moduleItems = useSelector(
    (state) => Object.values(state.entities.modules).filter(({ acceptedContainers }) => acceptedContainers.includes('content')),
  );

  const handleAddContent = (item, moduleDef) => {
    dispatch(addContent(item));
    const { layouts } = moduleDef;
    const hasLayouts = Array.isArray(layouts) && layouts.length;
    const { id } = item;
    if (hasLayouts) {
      // isAddingContent for deleting content item from store if canceled
      dispatch(openContentLayout({ id, isAddingContent: true }));
    }
  };

  useEffect(() => {
    dispatch(loadSavedSections());
    dispatch(loadModules());
  }, []);

  return (
    <>
      <BrowsboxModuleList moduleItems={moduleItems} addContent={handleAddContent} />
      <BrowsboxSavedSectionsList savedSections={savedSections} />
    </>
  );
};

export default BrowsboxModuleContainer;
