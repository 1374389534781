/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CONTENT_COMPONENT_TYPE, Button } from '@liswood-tache/browsbox-static';
import { htmlDecode, htmlEncode } from 'htmlencode';
import Editor from '../../Editor/Editor';
import useShowUiControls from '../../../hooks/use-show-ui-controls';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  content: PropTypes.string,
  fromSectionOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  content: '',
  fromSectionOption: false,
  type: CONTENT_COMPONENT_TYPE.button,
};

const ContentComponentButton = (props) => {
  const {
    classes,
    containerClassName,
    content: contentEncoded,
    fromSectionOption,
    id,
    type,
  } = props;
  const content = htmlDecode(contentEncoded);
  const componentClasses = classNames(
    classes,
    containerClassName,
    'c-button',
    `c-${type}`,
  );
  const showUiControls = useShowUiControls();

  const onChange = (newContent) => {
    const newContentEncode = htmlEncode(newContent);

    // dispatch change only when new content is not empty
    // to prevent buttons disappear https://browsbox.atlassian.net/browse/BB-1207
    if (newContentEncode) {
      props.onChange({ id, type, fromSectionOption }, newContentEncode);
    }
  };

  // render edit behavior
  if (showUiControls) {
    return (
      <Editor
        content={content}
        type="button"
        onChange={onChange}
        className={componentClasses}
      />
    );
  }

  return <Button {...props} />;
};

ContentComponentButton.propTypes = propTypes;
ContentComponentButton.defaultProps = defaultProps;

export default ContentComponentButton;
