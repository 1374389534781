import React from 'react';

export function useForceUpdateWithKey() {
  const [key, setValue] = React.useState(0);

  return [
    key,
    React.useCallback(() => {
      setValue(prev => prev + 1);
    }, [setValue]),
  ];
}

export default function useForceUpdate() {
  const [, setValue] = React.useState(0);

  return React.useCallback(() => {
    setValue(prev => prev + 1);
  }, [setValue]);
}
