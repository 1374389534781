import React, { Component } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import omit from 'lodash/omit';
import { withNamespaces } from 'react-i18next';
import { saveContent } from '../../actions/contentSave';
import { restoreContent } from '../../actions/contentRestore';
import ConfirmationModal from '../Modals/ConfirmationModal';
import Spinner from '../Spinner';

const SAVE_CONTENT = 'save_content';
const RESTORE_CONTENT = 'restore_content';

const propTypes = {
  saveContent: PropTypes.func.isRequired,
  restoreContent: PropTypes.func.isRequired,
  isModified: PropTypes.bool.isRequired,
  isSaving: PropTypes.bool.isRequired,
  content: PropTypes.array.isRequired,
  pageid: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
};

const defaultProps = {
};

class BrowsboxContentUpdate extends Component {
  constructor(props) {
    super(props);
    this.onSave = this.onSave.bind(this);
    this.onRestore = this.onRestore.bind(this);
    this.onClose = this.onClose.bind(this);
    this.onConfirmation = this.onConfirmation.bind(this);
    this.state = {
      showConfirmationModal: false,
      action: false,
    };
  }

  onSave() {
    this.setState({ showConfirmationModal: true, action: SAVE_CONTENT });
  }

  onRestore(evt) {
    evt.preventDefault();
    this.setState({ showConfirmationModal: true, action: RESTORE_CONTENT });
  }

  onClose() {
    this.setState({ showConfirmationModal: false, action: undefined });
  }

  onConfirmation() {
    switch (this.state.action) {
      case SAVE_CONTENT:
        this.saveContent();
        break;
      case RESTORE_CONTENT:
        this.props.restoreContent();
        this.setState(
          { showConfirmationModal: false, action: undefined },
          () => {
            // refresh browser to restore content
            window.location.reload();
          },
        );
        break;
      default:
        this.setState({ showConfirmationModal: false, action: undefined });
    }
  }

  // Pick values from content and trigger save content action
  saveContent() {
    const { content, pageid: id } = this.props;
    // use `omit` to drop app properties from section
    const contentClone = {
      sections:
      content.map(c => omit(c, ['dndSource', 'dndType', 'index'])),
    };
    this.props.saveContent({ id, content: contentClone }).then(() => {
      this.setState({ showConfirmationModal: false, action: undefined });
    });
  }

  renderModal() {
    const {
      action,
      showConfirmationModal,
    } = this.state;
    const {
      isSaving,
      t,
    } = this.props;
    if (showConfirmationModal) {
      const title = action === SAVE_CONTENT ? t('CONTENT.saveChanges') : t('CONTENT.doRestore');
      const submitButtonText = action === SAVE_CONTENT ? t('CONTENT.save') : t('CONTENT.doRestore');
      return (
        <ConfirmationModal
          onClose={this.onClose}
          onOk={this.onConfirmation}
          onCancel={this.onClose}
          icon="icon-basic_sheet"
          submitButtonText={submitButtonText}
          title={title}
        >
          { !isSaving && (
            <p>{t('CONTENT.saveChangesMessage')}</p>
          )}
          { isSaving && (
            <Spinner size={4} />
          )}
        </ConfirmationModal>
      );
    }
    return null;
  }

  render() {
    const { t } = this.props;

    return (
      <>
        <div className={classNames(
          'bb-panel__actions',
          { 'bb-panel__actions--hidden': !this.props.isModified },
        )}
        >
          <button type="button" className="bb-panel__button" onClick={this.onSave}>{t('CONTENT.savePage')}</button>
          <span> | </span>
          <button type="button" className="bb-panel__button" onClick={this.onRestore}>{t('CONTENT.restore')}</button>
        </div>
        { this.renderModal() }
      </>
    );
  }
}

BrowsboxContentUpdate.propTypes = propTypes;
BrowsboxContentUpdate.defaultProps = defaultProps;

const mapStateToProps = (state) => {
  const {
    content: {
      isModified,
      isSaving,
    },
    entities: {
      content,
    },
    pages: {
      settings: { pageid },
    },
  } = state;

  return {
    isModified,
    isSaving,
    content,
    pageid,
  };
};

const mapDispatchToProps = {
  saveContent,
  restoreContent,
};

const BrowsboxContentContainer = withNamespaces()(connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxContentUpdate));

export default BrowsboxContentContainer;
