import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { moduleOptionsProps } from '@liswood-tache/browsbox-static';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../Icon/Icon';
import { Gateway } from '../Gateway';
import { BbPopup } from '../@browsbox-ui';
import BrowsboxContentColorPicker from './ContentColorPicker';
import i18n from '../../internationalization/i18n';
import BrowsboxContentBackgroundImage from './ContentBackgroundImage';
import { columnColorSchemeClasses } from './utils';
import { clearColumnOption, setColumnOption } from '../../actions/contentConfig';
import BrowsboxContentVerticalAlignmentPicker from './ContentVerticalAlignmentPicker';
import BrowsboxContentHorizontalAlignmentPicker from './ContentHorizontalAlignmentPicker';

// NOTE: Temporary file, until this gets the proper styling.
import './ContentColumnConfig.scss';
import BrowsboxColumnPositionHandle from './ContentColumnPositionHandle';
import { useExclusivePopups } from '../../hooks/common/use-popups';
import useShowUiControls from '../../hooks/use-show-ui-controls';

const BrowsboxContentColumnConfigMenu = ({
  tetherTarget,
  moduleOptions,
  columnBackgroundActive,
  columnAlignmentActive,
  contentColor,
  contentColorSchemeClass,
  colorSchemeClasses,
  colors,
  backgroundImage,
  backgroundPosition,
  backgroundBehavior,
  backgroundScrolling,
  backgroundTransparency,
  horizontalAlignment,
  verticalAlignment,
  contentId,
  columnId,
  onBackgroundColorChange,
  onBackgroundImageOptionsChange,
  onHorizontalAlignmentChange,
  onVerticalAlignmentChange,
  onClose,
}) => {
  const colorPickerActive = colors.length !== 0 || Object.keys(colorSchemeClasses).length !== 0;
  const mediaPickerActive = typeof moduleOptions.backgroundImage !== 'undefined';

  const {
    setShownPopup,
    popups: [
      showColorPickerPopup,
      setShowColorPickerPopup,
      showBackgroundImagePopup,
      setShowBackgroundImagePopup,
      showVerticalAlignmentPopup,
      setShowVerticalAlignmentPopup,
      showHorizontalAlignmentPopup,
      setShowHorizontalAlignmentPopup,
    ],
  } = useExclusivePopups('colorPicker', 'backgroundPicker', 'verticalAlignmentPicker', 'horizontalAlignmentPicker');

  const handleOnClose = () => {
    setShownPopup();

    requestAnimationFrame(() => {
      onClose();
    });
  };

  return (
    <Gateway into="popup">
      <BbPopup
        target={tetherTarget}
        className="o-bb-popup--config o-bb-popup--section-config"
        onClose={handleOnClose}
        closeOnClickInside={false}
        placement="bottom left"
      >
        <div className="o-bb-section-config__group">
          {columnBackgroundActive && (
            <>
              <div className="o-bb-section-config__row">
                {colorPickerActive && (
                  <BrowsboxContentColorPicker
                    className="o-bb-popup--config-action"
                    color={contentColor}
                    colorSchemeClass={contentColorSchemeClass}
                    colorSchemeClasses={columnColorSchemeClasses(colorSchemeClasses)}
                    colors={colors}
                    onChange={onBackgroundColorChange}
                    showPopup={showColorPickerPopup}
                    setShowPopup={setShowColorPickerPopup}
                  >
                    {i18n.t('CONTENT.backgroundColor')}
                  </BrowsboxContentColorPicker>
                )}
              </div>
              <div className="o-bb-section-config__row">
                {mediaPickerActive && (
                  <BrowsboxContentBackgroundImage
                    className="o-bb-popup--config-action"
                    value={{
                      backgroundImage,
                      backgroundPosition,
                      backgroundBehavior,
                      backgroundTransparency,
                      backgroundScrolling,
                    }}
                    contentId={{ contentId, columnId }}
                    contentType="column"
                    onChange={onBackgroundImageOptionsChange}
                    showPopup={showBackgroundImagePopup}
                    setShowPopup={setShowBackgroundImagePopup}
                  >
                    {i18n.t('CONTENT.backgroundImage')}
                  </BrowsboxContentBackgroundImage>
                )}
              </div>
              <div className="o-bb-section-config__row">
                {columnAlignmentActive && (
                  <BrowsboxContentVerticalAlignmentPicker
                    className="o-bb-popup--config-action"
                    onChange={onVerticalAlignmentChange}
                    value={verticalAlignment}
                    showPopup={showVerticalAlignmentPopup}
                    setShowPopup={setShowVerticalAlignmentPopup}
                  >
                    {i18n.t('CONTENT.verticalAlignment')}
                  </BrowsboxContentVerticalAlignmentPicker>
                )}
              </div>
              <div className="o-bb-section-config__row">
                {columnAlignmentActive && (
                  <BrowsboxContentHorizontalAlignmentPicker
                    className="o-bb-popup--config-action"
                    onChange={onHorizontalAlignmentChange}
                    value={horizontalAlignment}
                    showPopup={showHorizontalAlignmentPopup}
                    setShowPopup={setShowHorizontalAlignmentPopup}
                  >
                    {i18n.t('CONTENT.horizontalAlignment')}
                  </BrowsboxContentHorizontalAlignmentPicker>
                )}
              </div>
            </>
          )}
        </div>
      </BbPopup>
    </Gateway>
  );
};

BrowsboxContentColumnConfigMenu.propTypes = {
  tetherTarget: PropTypes.string.isRequired,
  columnBackgroundActive: PropTypes.bool.isRequired,
  columnAlignmentActive: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  onBackgroundColorChange: PropTypes.func.isRequired,
  onBackgroundImageOptionsChange: PropTypes.func.isRequired,
  verticalAlignment: PropTypes.string.isRequired,
  onVerticalAlignmentChange: PropTypes.func.isRequired,
  horizontalAlignment: PropTypes.string.isRequired,
  onHorizontalAlignmentChange: PropTypes.func.isRequired,
  moduleOptions: moduleOptionsProps.isRequired,
  contentColor: PropTypes.string,
  contentColorSchemeClass: PropTypes.string,
  colorSchemeClasses: PropTypes.object.isRequired,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
  backgroundBehavior: PropTypes.string.isRequired,
  backgroundScrolling: PropTypes.bool.isRequired,
  backgroundTransparency: PropTypes.number.isRequired,
  contentId: PropTypes.number.isRequired,
  columnId: PropTypes.number.isRequired,
};

BrowsboxContentColumnConfigMenu.defaultProps = {
  backgroundImage: '',
  contentColor: '',
  contentColorSchemeClass: '',
};

const BrowsboxContentColumnConfig = ({
  contentType, contentId, columnId, onPopupShow, onPopupHide, columnDragRef, ...props
}) => {
  const dispatch = useDispatch();
  const showUiControls = useShowUiControls();

  const modules = useSelector((state) => state.entities.modules);
  const { colors = [], colorSchemeClasses = {}, options: moduleOptions = {} } = modules[contentType] || {};

  const [showConfigMenu, setShowConfigMenu] = useState(false);

  const handleBackgroundColorChange = (clear, color, useColorSchemeClass) => {
    if (useColorSchemeClass && color) {
      dispatch(setColumnOption({
        option: 'colorSchemeClass',
        value: color,
        contentId,
        columnId,
      }));
    } else if (useColorSchemeClass && clear === true) {
      dispatch(clearColumnOption({
        option: 'colorSchemeClass',
        contentId,
        columnId,
      }));
    } else if (!useColorSchemeClass && clear === true) {
      dispatch(clearColumnOption({
        option: 'backgroundColor',
        contentId,
        columnId,
      }));
    } else if (!useColorSchemeClass && color) {
      dispatch(setColumnOption({
        option: 'backgroundColor',
        value: color,
        contentId,
        columnId,
      }));
    }
  };

  const handleBackgroundImageOptionsChange = (options) => {
    Object.entries(options)
      .forEach(([option, value]) => dispatch(setColumnOption({
        option,
        value,
        contentId,
        columnId,
      })));
  };

  const handleVerticalAlignmentChange = (value) => {
    dispatch(setColumnOption({
      option: 'verticalAlignment',
      value,
      contentId,
      columnId,
    }));
  };

  const handleHorizontalAlignmentChange = (value) => {
    dispatch(setColumnOption({
      option: 'horizontalAlignment',
      value,
      contentId,
      columnId,
    }));
  };

  const handleMenuOpen = () => {
    setShowConfigMenu(true);

    window.requestAnimationFrame(() => {
      onPopupShow();
    });
  };

  const handleMenuClose = () => {
    setShowConfigMenu(false);

    window.requestAnimationFrame(() => {
      onPopupHide();
    });
  };

  const configMenuTetherTarget = `content-column-${columnId}-config`;

  if (
    !showUiControls
    || (!props.columnAlignmentActive && !props.columnBackgroundActive && !props.columnPositioningActive)
  ) {
    return null;
  }

  return (
    <div className="l-column-config">
      <div className="l-column-config__bar">
        {(props.columnAlignmentActive || props.columnBackgroundActive) && (
          <div
            key="config"
            className="l-column-config__button"
            onClick={handleMenuOpen}
            id={configMenuTetherTarget}
          >
            <a className="o-action-link">
              <Icon name="settings" />
            </a>
          </div>
        )}
        {props.columnPositioningActive && (
          <BrowsboxColumnPositionHandle ref={columnDragRef} />
        )}
        {showConfigMenu && (
          <BrowsboxContentColumnConfigMenu
            contentId={contentId}
            columnId={columnId}
            colors={colors}
            colorSchemeClasses={colorSchemeClasses}
            moduleOptions={moduleOptions}
            tetherTarget={configMenuTetherTarget}
            onClose={handleMenuClose}
            onBackgroundColorChange={handleBackgroundColorChange}
            onBackgroundImageOptionsChange={handleBackgroundImageOptionsChange}
            onVerticalAlignmentChange={handleVerticalAlignmentChange}
            onHorizontalAlignmentChange={handleHorizontalAlignmentChange}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

BrowsboxContentColumnConfig.propTypes = {
  contentId: PropTypes.number.isRequired,
  columnId: PropTypes.number.isRequired,
  columnAlignmentActive: PropTypes.bool.isRequired,
  columnBackgroundActive: PropTypes.bool.isRequired,
  columnPositioningActive: PropTypes.bool.isRequired,
  contentColor: PropTypes.string,
  contentColorSchemeClass: PropTypes.string,
  backgroundImage: PropTypes.string,
  backgroundScrolling: PropTypes.bool.isRequired,
  backgroundTransparency: PropTypes.number.isRequired,
  verticalAlignment: PropTypes.string.isRequired,
  horizontalAlignment: PropTypes.string.isRequired,
  onPopupShow: PropTypes.func,
  onPopupHide: PropTypes.func,
  columnDragRef: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.func,
  ]).isRequired,
};

BrowsboxContentColumnConfig.defaultProps = {
  backgroundImage: '',
  contentColor: '',
  contentColorSchemeClass: '',
  onPopupShow: () => {},
  onPopupHide: () => {},
};

export default BrowsboxContentColumnConfig;
