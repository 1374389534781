import React from 'react';
import PropTypes from 'prop-types';
import { BbPopup } from '@browsbox-ui';
import { Gateway } from '../Gateway';
import BlockPicker from './BlockAlignmentPicker';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const ITEMS = [
  ['top', 'fas fa-arrow-up-to-line', 'CONTENT.verticallyAlignTop'],
  ['center', 'fas fa-arrows-to-line', 'CONTENT.verticallyAlignCenter'],
  ['bottom', 'fas fa-arrow-down-to-line', 'CONTENT.verticallyAlignBottom'],
];

const Popup = ({
  show, value, onClose, onChange, tetherTarget,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Gateway into="popup">
      <BbPopup
        placement="right"
        target={tetherTarget}
        onClose={onClose}
        closeOnClickInside={false}
        className="o-bb-popup--config o-bb-popup--vertical-alignment-picker"
      >
        <BlockPicker
          items={ITEMS}
          value={value}
          onChange={onChange}
        />
      </BbPopup>
    </Gateway>
  );
};

Popup.propTypes = {
  value: PropTypes.string.isRequired,
  show: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  tetherTarget: PropTypes.string.isRequired,
};

const BrowsboxContentVerticalAlignmentPicker = ({
  className, children, value, onChange: handleChange, showPopup, setShowPopup,
}) => {
  const tetherTarget = 'content-vertical-alignment-picker';

  const handleOnClick = (evt) => {
    evt.preventDefault();
    setShowPopup(!showPopup);
  };

  const handleClose = () => setShowPopup(false);

  return (
    <a href="#" onClick={handleOnClick} className={className} id={tetherTarget}>
      <FontAwesomeIcon name="fas fa-arrow-down-to-line" />
      {children}
      <Popup
        value={value}
        show={showPopup}
        onChange={handleChange}
        onClose={handleClose}
        tetherTarget={tetherTarget}
      />
    </a>
  );
};

BrowsboxContentVerticalAlignmentPicker.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  showPopup: PropTypes.bool.isRequired,
  setShowPopup: PropTypes.func.isRequired,
};

BrowsboxContentVerticalAlignmentPicker.defaultProps = {
  className: '',
};

export default BrowsboxContentVerticalAlignmentPicker;
