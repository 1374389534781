import React, { Fragment, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import usePreviousValue from '../../../hooks/common/use-previous-value';
import { classNameToTokens } from './utils';
import { useForceUpdateWithKey } from '../../../hooks/common/use-force-update';

function Footer({
  children, className, onMouseOver, onMouseLeave,
}) {
  const html = useSelector((state) => state.pages.footerHtml);
  const previousClassName = usePreviousValue(className);
  const [key, forceUpdate] = useForceUpdateWithKey();

  const handleMouseOver = useCallback(() => {
    onMouseOver();
  }, [onMouseOver]);

  const handleMouseLeave = useCallback(() => {
    onMouseLeave();
  }, [onMouseLeave]);

  useEffect(() => {
    const element = document.querySelector('.js-footer');

    if (!element) {
      return () => {};
    }

    element.addEventListener('mouseover', onMouseOver);
    element.addEventListener('mouseleave', onMouseLeave);

    return () => {
      element.removeEventListener('mouseover', onMouseOver);
      element.removeEventListener('mouseleave', onMouseLeave);
    };
  }, [handleMouseOver, handleMouseLeave]);

  useEffect(() => {
    if (!html) {
      return;
    }

    const element = document.querySelector('.js-footer');

    if (!element) {
      return;
    }

    element.innerHTML = html;

    forceUpdate();
  }, [html]);

  useEffect(() => {
    const element = document.querySelector('.js-footer');

    if (!element) {
      return;
    }

    if (className === previousClassName) {
      return;
    }

    element.classList.remove(...classNameToTokens(previousClassName));
    element.classList.add(...classNameToTokens(className));
  }, [className, previousClassName]);

  return <Fragment key={key}>{children}</Fragment>;
}

Footer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onMouseOver: PropTypes.func,
  onMouseLeave: PropTypes.func,
};

Footer.defaultProps = {
  className: '',
  children: [],
  onMouseOver: () => {},
  onMouseLeave: () => {},
};

export default Footer;
