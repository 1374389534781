/* eslint-disable no-mixed-operators,jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';
import {
  CONTENT_COMPONENT_TYPE, IMAGE_PLACEHOLDER_URL, LinkedImage, getWebMediaUrl,
  ContentComponentSizeListenerContext,
} from '@liswood-tache/browsbox-static';
import { openMediaPicker } from '../../../actions/mediaManager';
import { DEFAULT_VIEW } from '../../../actions/responsive';
import Cropper from '../ImageTools/Cropper/Cropper';
import withCropper, { withCropperPropTypes } from '../ImageTools/Cropper/withCropper';
import ContentItemContext from '../ContentItemContext';
import ResizableContainer from '../ImageTools/ResizableContainer/ResizableContainer';
import ImageToolbar from '../ImageTools/ImageToolbar/ImageToolbar';
import { clearComponentOption, setComponentOption } from '../../../actions/contentConfig';
import { getComponentById } from '../../../utils/entities';
import { findMediaItem } from '../../../selectors/media';

const propTypes = {
  alt: PropTypes.string,
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  id: PropTypes.number.isRequired,
  openMediaPicker: PropTypes.func.isRequired,
  renderTarget: PropTypes.string.isRequired, // Render default or preview
  tooltip: PropTypes.string,
  type: PropTypes.string,
  url: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  imageRef: PropTypes.func,
  hyperlink: PropTypes.string,
  media: PropTypes.shape({
    id: PropTypes.number.isRequired,
    fileExtension: PropTypes.string.isRequired,
    filename: PropTypes.string.isRequired,
  }),
  clearComponentOption: PropTypes.func.isRequired,
  setComponentOption: PropTypes.func.isRequired,

  ...withCropperPropTypes,
};

const defaultProps = {
  alt: '',
  classes: '',
  containerClassName: '',
  tooltip: '',
  type: CONTENT_COMPONENT_TYPE.image,
  url: '',
  width: 0,
  height: 0,
  imageRef: () => {},
  hyperlink: '',
  cropperData: null,
  media: null,
};

class ContentComponentImage extends Component {
  static contextType = ContentComponentSizeListenerContext;

  constructor(props) {
    super(props);
    this.onSelect = this.onSelect.bind(this);
    this.onError = this.onError.bind(this);
    this.onMounted = this.onMounted.bind(this);
    this.enableHoverMode = this.enableHoverMode.bind(this);
    this.disableHoverMode = this.disableHoverMode.bind(this);
    this.onRemoveImageClick = this.onRemoveImageClick.bind(this);
    this.onChooseImageClick = this.onChooseImageClick.bind(this);
    this.onImageCropClick = this.onImageCropClick.bind(this);
    this.createCropCallback = this.createCropCallback.bind(this);
    this.onResetDimensionsClick = this.onResetDimensionsClick.bind(this);

    this.state = {
      imageStatus: false,
      isHover: false,
      isResizing: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.component
      && this.props.component.mediaId !== prevProps.component.mediaId
      && prevState.imageStatus
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        imageStatus: false,
      });
    }
  }

  onMounted(ref) {
    if (ref) {
      this.context(ref.getBoundingClientRect());

      this.imageRef = ref;
      if (this.props.imageRef) {
        this.props.imageRef(ref);
      }
    }
  }

  onSelect(evt) {
    evt.preventDefault();
    const {
      id,
      type,
      width,
      height,
    } = this.props;
    const bbox = this.imageRef ? this.imageRef.getBoundingClientRect() : { width: 0, height: 0 };
    const imageSize = {
      width: width === 0 ? bbox.width : width,
      height: height === 0 ? bbox.height : height,
    };
    this.props.openMediaPicker({ id, type, ...imageSize });
  }

  onError() {
    this.setState({
      imageStatus: `Failed to load ${this.props.url}`,
    });
  }

  onRemoveImageClick() {
    const { id } = this.props;
    const attributes = ['url', 'alt'];

    attributes.forEach((attribute) => {
      this.props.clearComponentOption({
        option: attribute,
        contentId: id,
      });
    });

    this.props.handleRemoveImage();
  }

  onChooseImageClick() {
    const { id, type } = this.props;
    const { width, height } = this.state;

    this.props.openMediaPicker({
      id, type, width, height,
    });
  }

  onResetDimensionsClick() {
    const { id } = this.props;

    this.props.clearComponentOption({
      option: 'imageWidth',
      contentId: id,
    });

    this.props.clearComponentOption({
      option: 'imageHeight',
      contentId: id,
    });

    this.props.clearComponentOption({
      option: 'hasCustomSize',
      contentId: id,
    });

    this.props.clearComponentOption({
      option: 'cropperDataRaw',
      contentId: id,
    });

    if (this.props.media) {
      const webUrl = getWebMediaUrl(this.props.media);
      this.props.setComponentOption({
        option: 'url',
        value: webUrl,
        contentId: id,
      });
    }

    this.props.handleRemoveImage();
  }

  onImageCropClick() {
    this.props.enableImageCropping();
  }

  hasCustomStyles = () => {
    const { cropperData } = this.props;

    return Object.keys(cropperData || {}).length > 0;
  };

  isVectorImage = () => {
    const vectors = ['svg'];
    const url = this.resolveImageSrc();

    return vectors.some(extension => url.endsWith(extension));
  };

  resolveCustomStyles = () => {
    const { isResizing } = this.state;

    if (!isResizing || !this.hasCustomStyles()) {
      return {};
    }

    const { cropperData: { canvasData } } = this.props;

    return {
      width: `${canvasData.width}px`,
      height: `${canvasData.height}px`,
      top: `${canvasData.top}px`,
      left: `${canvasData.left}px`,
    };
  };

  enableResizing = () => {
    this.setState({ isResizing: true });
  };

  disableResizing = () => {
    this.setState({
      isResizing: false,
    });

    if (this.hasCustomStyles()) {
      this.props.enableImageCropping();
    }

    if (this.hasCustomStyles()) {
      this.props.enableCustomSizing();
    }
  };

  resolveImageSrc = () => {
    const { cropperData, component } = this.props;
    const { isResizing } = this.state;
    const isGalleryImage = this.props.type === CONTENT_COMPONENT_TYPE.galleryImage;

    if ((isResizing || isGalleryImage) && cropperData) {
      if (cropperData.src) {
        return cropperData.src;
      }
    }

    return component.url;
  };

  disableHoverMode() {
    this.setState({ isHover: false });
  }

  enableHoverMode() {
    this.setState({ isHover: true });
  }

  createCropCallback() {
    if (this.props.isCroppingSupported) {
      return this.onImageCropClick;
    }

    return null;
  }

  renderStatus() {
    const {
      imageStatus,
    } = this.state;

    if (imageStatus) {
      return (
        // eslint-disable-next-line jsx-a11y/click-events-have-key-events
        <div
          className="c-image__status-notice"
          onClick={this.onSelect}
        >
          { imageStatus }
        </div>
      );
    }

    return null;
  }

  renderPlaceholder({ content }) {
    const {
      alt,
      classes,
      containerClassName,
      renderTarget,
      tooltip,
      type,
      hyperlink,
      component,
    } = this.props;

    const componentClasses = classNames(
      classes,
      `c-bb-${type}`,
      'o-image-placeholder',
      containerClassName,
    );
    // render edit behavior
    if (renderTarget === DEFAULT_VIEW) {
      if (this.props.type === CONTENT_COMPONENT_TYPE.image) {
        return (
          <div className={componentClasses}>
            <ResizableContainer
              content={content}
              component={component}
            >
              <LinkedImage hyperlink={hyperlink}>
                <img
                  className={`c-${type}__element c-image__element`}
                  src={IMAGE_PLACEHOLDER_URL}
                  alt={alt}
                  title={tooltip}
                  onClick={this.onSelect}
                  ref={this.onMounted}
                />
              </LinkedImage>
            </ResizableContainer>
          </div>
        );
      }

      return (
        <div
          className={componentClasses}
          onClick={this.onSelect}
        >
          <div className="c-image__container">
            <LinkedImage hyperlink={hyperlink}>
              <img
                className={`c-image__element c-${type}__element`}
                src={IMAGE_PLACEHOLDER_URL}
                alt={alt}
                title={tooltip}
                ref={this.onMounted}
              />
            </LinkedImage>
          </div>
          { this.renderStatus() }
        </div>
      );
    }
    // render target is preview or html

    if (this.props.type === CONTENT_COMPONENT_TYPE.image) {
      return (
        <div className={componentClasses}>
          <ResizableContainer
            content={content}
            component={component}
            disabled
          >
            <LinkedImage hyperlink={hyperlink}>
              <img className={`c-${type}__element c-image__element`} src={IMAGE_PLACEHOLDER_URL} alt={alt} title={tooltip} />
            </LinkedImage>
          </ResizableContainer>
        </div>
      );
    }

    return (
      <div className={componentClasses}>
        <div className="c-image__container">
          <LinkedImage hyperlink={hyperlink}>
            <img className={`c-${type}__element c-image__element`} src={IMAGE_PLACEHOLDER_URL} alt={alt} title={tooltip} />
          </LinkedImage>
        </div>
      </div>
    );
  }

  renderImage({ content }) {
    const {
      alt,
      classes,
      containerClassName,
      renderTarget,
      tooltip,
      type,
      url,
      hyperlink,
      component,
    } = this.props;

    const componentClasses = classNames(
      classes,
      containerClassName,
      'c-image',
      `c-image-${type}`,
      { 'c-image--resizing': this.state.isResizing },
    );

    // render edit behavior
    if (renderTarget === DEFAULT_VIEW) {
      if (this.props.type === CONTENT_COMPONENT_TYPE.image) {
        return (
          <div className={componentClasses}>
            <ResizableContainer
              content={content}
              component={component}
              onResizeStart={this.enableResizing}
              onResizeEnd={this.disableResizing}
            >
              {this.state.isHover && !this.props.isCropping && !this.state.isResizing && (
                <ImageToolbar
                  onChooseImageClick={this.onChooseImageClick}
                  onCropClick={this.createCropCallback()}
                  onResetDimensionsClick={this.onResetDimensionsClick}
                  onRemoveClick={this.onRemoveImageClick}
                />
              )}
              {(this.props.isCropping && !this.state.isResizing) && (
                <Cropper
                  src={component.url}
                  originalMediaId={Number(component.mediaId)}
                  onCrop={this.props.handleCropImage}
                  onCropChange={this.props.handleCropChange}
                  onCropCancel={this.props.disableImageCropping}
                  cropperData={this.props.cropperData}
                  isCropPending={this.props.isCropPending}
                />
              ) || (
                <LinkedImage
                  hyperlink={hyperlink}
                  className={classNames(
                    { 'c-image__link--resizing': this.state.isResizing },
                    { 'c-image__link--has-custom-styles': this.hasCustomStyles() },
                  )}
                >
                  <img
                    className={classNames(
                      `c-${type}__element`,
                      'c-image__element',
                      { 'c-image__element--resizing': this.state.isResizing },
                      { 'c-image__element--has-custom-styles': this.hasCustomStyles() },
                      { 'c-image__element--vector': this.isVectorImage() },
                    )}
                    style={this.resolveCustomStyles()}
                    src={this.resolveImageSrc()}
                    alt={alt}
                    title={tooltip}
                    onError={this.onError}
                    ref={this.onMounted}
                  />
                </LinkedImage>
              )}
            </ResizableContainer>
            { this.renderStatus() }
          </div>
        );
      }

      return (
        <div className={componentClasses}>
          <div className="c-image__container">
            <LinkedImage hyperlink={hyperlink}>
              <img
                className={classNames(
                  `c-${type}__element c-image__element`,
                  { 'c-image__element--vector': this.isVectorImage() },
                )}
                src={url}
                alt={alt}
                title={tooltip}
                onClick={this.onSelect}
                onError={this.onError}
                ref={this.onMounted}
              />
            </LinkedImage>
            { this.renderStatus() }
          </div>
        </div>
      );
    }
    // render target is preview or html

    if (this.props.type === CONTENT_COMPONENT_TYPE.image) {
      return (
        <div className={componentClasses}>
          <ResizableContainer
            content={content}
            component={component}
            disabled
          >
            <LinkedImage hyperlink={hyperlink}>
              <img
                className={classNames(
                  `c-${type}__element c-image__element`,
                  { 'c-image__element--vector': this.isVectorImage() },
                )}
                src={url}
                alt={alt}
                title={tooltip}
              />
            </LinkedImage>
          </ResizableContainer>
        </div>
      );
    }

    return (
      <div className={componentClasses}>
        <LinkedImage hyperlink={hyperlink}>
          <img
            className={classNames(
              `c-${type}__element c-image__element`,
              { 'c-image__element--vector': this.isVectorImage() },
            )}
            src={url}
            alt={alt}
            title={tooltip}
          />
        </LinkedImage>
      </div>
    );
  }

  renderImageWithContext(context) {
    if (!this.props.component.url) {
      return this.renderPlaceholder(context);
    }

    return this.renderImage(context);
  }

  render() {
    if (!this.props.component) {
      return null;
    }

    return (
      <div
        className={classNames({ 'has-cropper': this.props.isCropping })}
        onMouseEnter={this.enableHoverMode}
        onMouseLeave={this.disableHoverMode}
      >
        <ContentItemContext.Consumer>
          {context => this.renderImageWithContext(context)}
        </ContentItemContext.Consumer>
      </div>
    );
  }
}

ContentComponentImage.propTypes = propTypes;
ContentComponentImage.defaultProps = defaultProps;

const mapStateToProps = (state, ownProps) => {
  const { id } = ownProps;
  const component = getComponentById(state.entities.content, id);
  const mediaId = component && component.mediaId;
  const media = mediaId && findMediaItem(state, mediaId);

  return {
    media,
  };
};

const mapDispatchToProps = {
  openMediaPicker,
  clearComponentOption,
  setComponentOption,
};

export default withCropper(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(ContentComponentImage),
);
