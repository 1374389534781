import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import CONTENT_COMPONENT_TYPE from './types';
import { ContentComponentSizeListenerContext } from './context';

export default class Hr extends Component {
  static propTypes = {
    classes: PropTypes.string,
    containerClassName: PropTypes.string,
    type: PropTypes.string,
  };

  static defaultProps = {
    classes: '',
    containerClassName: '',
    type: CONTENT_COMPONENT_TYPE.hr,
  };

  static contextType = ContentComponentSizeListenerContext;

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    this.context(this.ref.current.getBoundingClientRect());
  }

  render() {
    const {
      type,
      classes,
      containerClassName,
    } = this.props;

    const componentClasses = classNames(
      classes,
      containerClassName,
      `c-${type}`,
    );

    return <hr ref={this.ref} className={componentClasses} />;
  }
}
