/* eslint-disable react/jsx-fragments */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { contentPropType } from '../ContentPropTypes';
import { moduleDefaultOptionsProps } from '../ModulePropTypes';
import BrowsboxContentColumn from '../ContentColumn';
import ContentItemBackground from '../ContentItemBackground';

class FooterSection extends Component {
  constructor(props) {
    super(props);
    this.renderColumn = this.renderColumn.bind(this);
  }

  renderColumn(column, idx) {
    const { content } = this.props;

    if (column.visible === false) {
      return null;
    }

    const { options } = column;

    const classes = classNames(
      options.colorSchemeClass,
      { [`l-column--${options.size}`]: typeof options.size !== 'undefined' },
      { [`l-column--v-align-${options.verticalAlignment}`]: options.verticalAlignment },
      { [`l-column--h-align-${options.horizontalAlignment}`]: options.horizontalAlignment },
    );

    const isItemBackgroundVisible = options.backgroundImage || options.colorSchemeClass;

    return (
      <BrowsboxContentColumn
        className={classes}
        key={idx}
        column={column}
        content={content}
        components={column.components}
        onChange={this.onComponentContentChange}
      >
        {isItemBackgroundVisible && (
          <div className="l-column__background">
            <ContentItemBackground
              position={options.backgroundPosition}
              behavior={options.backgroundBehavior}
              transparency={Number(options.backgroundTransparency)}
              scrolling={Boolean(options.backgroundScrolling)}
              image={options.backgroundImage}
            />
          </div>
        )}
      </BrowsboxContentColumn>
    );
  }

  renderSection() {
    const {
      content,
    } = this.props;

    const hasAnyComponentsToRender = content.columns.some((column) => column.components.length > 0);

    if (!hasAnyComponentsToRender) {
      return null;
    }

    return (
      <div className="l-columns">
        {content.columns.map(this.renderColumn)}
      </div>
    );
  }

  render() {
    return this.renderSection();
  }
}

FooterSection.propTypes = {
  content: contentPropType.isRequired,
  id: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
};

FooterSection.defaultProps = {
  moduleOptions: moduleDefaultOptionsProps,
};

export default FooterSection;
