export const SET_CONTENT_OPTION = 'SET_CONTENT_OPTION';
export const setContentOption = value => ({ type: SET_CONTENT_OPTION, value });
export const CLEAR_CONTENT_OPTION = 'CLEAR_CONTENT_OPTION';
export const clearContentOption = value => ({ type: CLEAR_CONTENT_OPTION, value });

export const MERGE_CONTENT_OPTION = 'MERGE_CONTENT_OPTION';
export const mergeContentOption = value => ({ type: MERGE_CONTENT_OPTION, value });

export const SET_COMPONENT_OPTION = 'SET_COMPONENT_OPTION';
export const setComponentOption = value => ({ type: SET_COMPONENT_OPTION, value });
export const CLEAR_COMPONENT_OPTION = 'CLEAR_COMPONENT_OPTION';
export const clearComponentOption = value => ({ type: CLEAR_COMPONENT_OPTION, value });

export const APPEND_COLUMN_COMPONENTS = 'APPEND_COLUMN_COMPONENTS';
export const appendColumnComponents = ({ column, newComponents }) => ({
  type: APPEND_COLUMN_COMPONENTS,
  value: {
    column,
    newComponents,
  },
});

export const SET_COLUMN_OPTION = 'SET_COLUMN_OPTION';
export const setColumnOption = value => ({ type: SET_COLUMN_OPTION, value });
export const CLEAR_COLUMN_OPTION = 'CLEAR_COLUMN_OPTION';
export const clearColumnOption = value => ({ type: CLEAR_COLUMN_OPTION, value });

export const TRANSPOSE_COLUMNS = 'TRANSPOSE_COLUMNS';
export const transposeColumns = ({ contentId, columnId, otherColumnId }) => ({
  type: TRANSPOSE_COLUMNS,
  value: { contentId, columnId, otherColumnId },
});
