import { useCallback, useMemo, useState } from 'react';

export default function usePopups(...popups) {
  const initialState = useMemo(() => popups.reduce((aggregator, popup) => ({
    ...aggregator,
    [popup]: false,
  }), {}), []);

  const [shownPopup, setShownPopup] = useState(initialState);

  const clearShownPopups = useCallback(() => setShownPopup(initialState), [setShownPopup]);

  return {
    clearShownPopups,
    popups: popups.flatMap((popup) => [
      shownPopup[popup],
      (intent = null) => {
        setShownPopup((state) => ({
          ...state,
          [popup]: typeof intent === 'function' ? intent(shownPopup === popup) : intent,
        }));
      },
    ]),
  };
}

export function useExclusivePopups(...popups) {
  const [shownPopup, setShownPopup] = useState(null);

  return {
    setShownPopup: (popup = null) => setShownPopup(popup),
    popups: popups.flatMap((popup) => [
      shownPopup === popup,
      (intent = null) => {
        const actualIntent = typeof intent === 'function' ? intent(shownPopup === popup) : intent;

        setShownPopup(actualIntent ? popup : null);
      },
    ]),
  };
}
