import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { CONTENT_COMPONENT_TYPE } from '@liswood-tache/browsbox-static';
import Icon from '../Icon/Icon';
import { Gateway } from '../Gateway';
import { BbPopup } from '../@browsbox-ui';
import SuperAdminOnly from '../AcessControll/SuperAdminOnly';
import { deleteComponent } from '../../actions/contentDelete';
import { openGoogleMapsEditor } from '../../actions/googleMaps';
import { openGeneralSettingsView } from '../../actions/generalSettings';
import i18n from '../../internationalization/i18n';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const BrowsboxContentComponentConfigMenu = ({
  componentType,
  tetherTarget,
  onClose,
  onRemoveComponentClick,
  onOpenGoogleMapsClick,
  onOpenGeneralSettingsClick,
}) => (
  <Gateway into="popup">
    <BbPopup
      target={tetherTarget}
      className="o-bb-popup--config o-bb-popup--component-config"
      onClose={onClose}
      closeOnClickInside={false}
      placement="right top"
    >
      <div className="o-bb-section-config__group">
        {componentType === CONTENT_COMPONENT_TYPE.googleMaps && (
        <a
          href="#"
          className="o-bb-popup--config-action"
          onClick={onOpenGoogleMapsClick}
        >
          <FontAwesomeIcon name="fa-solid fa-location-dot" />
          {i18n.t('CONTENT.settings')}
        </a>
        )}

        {componentType === CONTENT_COMPONENT_TYPE.socialMedia && (
        <a
          href="#"
          className="o-bb-popup--config-action"
          onClick={onOpenGeneralSettingsClick}
        >
          <FontAwesomeIcon name="fa-solid fa-pen" />
          {i18n.t('CONTENT.settings')}
        </a>
        )}

        <SuperAdminOnly>
          <a
            href="#"
            className="o-bb-popup--config-action"
            onClick={onRemoveComponentClick}
          >
            <FontAwesomeIcon name="fa-solid fa-trash" />
            {i18n.t('CONTENT.delete')}
          </a>
        </SuperAdminOnly>
      </div>
    </BbPopup>
  </Gateway>
);

BrowsboxContentComponentConfigMenu.propTypes = {
  componentType: PropTypes.string.isRequired,
  tetherTarget: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onRemoveComponentClick: PropTypes.func.isRequired,
  onOpenGoogleMapsClick: PropTypes.func.isRequired,
  onOpenGeneralSettingsClick: PropTypes.func.isRequired,
};

const BrowsboxContentComponentConfig = ({
  contentType,
  contentId,
  columnId,
  componentId,
  componentType,
  onPopupShow,
  onPopupHide,
  columnDragRef,
  ...props
}) => {
  const dispatch = useDispatch();
  const [showConfigMenu, setShowConfigMenu] = useState(false);

  const handleMenuOpen = () => {
    setShowConfigMenu(true);

    window.requestAnimationFrame(() => {
      onPopupShow();
    });
  };

  const handleMenuClose = () => {
    setShowConfigMenu(false);

    window.requestAnimationFrame(() => {
      onPopupHide();
    });
  };

  const handleRemoveComponent = () => {
    handleMenuClose();

    dispatch(deleteComponent({
      id: componentId,
      columnId,
    }));
  };

  const handleOpenGoogleMaps = () => {
    handleMenuClose();

    dispatch(openGoogleMapsEditor({
      id: contentId,
      componentId,
    }));
  };

  const handleOpenGeneralSettings = () => {
    handleMenuClose();

    dispatch(openGeneralSettingsView());
  };

  const configMenuTetherTarget = `content-component-${componentId}-config`;

  return (
    <div className="l-component-config">
      <div className="l-component-config__bar">
        <div
          key="config"
          className="l-component-config__button"
          onClick={handleMenuOpen}
          id={configMenuTetherTarget}
        >
          <a className="o-action-link">
            <Icon name="settings" />
          </a>
        </div>
        {showConfigMenu && (
          <BrowsboxContentComponentConfigMenu
            contentId={contentId}
            columnId={columnId}
            componentId={componentId}
            componentType={componentType}
            tetherTarget={configMenuTetherTarget}
            onClose={handleMenuClose}
            onRemoveComponentClick={handleRemoveComponent}
            onOpenGoogleMapsClick={handleOpenGoogleMaps}
            onOpenGeneralSettingsClick={handleOpenGeneralSettings}
            {...props}
          />
        )}
      </div>
    </div>
  );
};

BrowsboxContentComponentConfig.propTypes = {
  contentId: PropTypes.number.isRequired,
  columnId: PropTypes.number.isRequired,
  componentId: PropTypes.number.isRequired,
  componentType: PropTypes.string.isRequired,
  onPopupShow: PropTypes.func,
  onPopupHide: PropTypes.func,
};

BrowsboxContentComponentConfig.defaultProps = {
  onPopupShow: () => {
  },
  onPopupHide: () => {
  },
};

export default BrowsboxContentComponentConfig;
