import ContentSectionBase from './ContentSectionBase';
import ContentSectionGallery from './ContentSectionGallery';
import ContentSectionSlider from './ContentSectionSlider';

const ContentSections = {
  base: ContentSectionBase,
  gallery: ContentSectionGallery,
  slider: ContentSectionSlider,
};

export default ContentSections;
