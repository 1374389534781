import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import flatten from 'lodash/flatten';
import debounce from 'lodash/debounce';
import map from 'lodash/map';
import { BbPopup } from '@browsbox-ui';
import {
  moduleOptionsProps, CONTENT_SECTION_TYPE, CONTENT_COMPONENT_TYPE,
} from '@liswood-tache/browsbox-static';
import { openDeleteContent } from '../../actions/contentDelete';
import { toggleContentViewOption } from '../../actions/options';
import BrowsboxContentColorPicker from './ContentColorPicker';
import BrowsboxContentMarginsPicker from './ContentMarginsPicker';
import BrowsboxContentBackgroundImage from './ContentBackgroundImage';
import {
  setContentOption, clearContentOption,
} from '../../actions/contentConfig';
import { setContentColumnCount } from '../../actions/contentColumns';
import { openContentEditor } from '../../actions/contentEditor';
import { openContentLayout } from '../../actions/contentLayout';
import { openGoogleMapsEditor } from '../../actions/googleMaps';
import ContentConfigButton from './ContentConfigButton';
import { cloneContent } from '../../actions/contentClone';
import Icon from '../Icon/Icon';
import i18n from '../../internationalization/i18n';
import { Gateway } from '../Gateway';
import { sectionColorSchemeClasses } from './utils';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const popupClasses = 'o-bb-popup--config o-bb-popup--section-config';
const popupItemClasses = 'o-bb-popup--config-action';
const popupItemSelectedClasses = 'o-bb-popup--config-action o-bb-popup--config-selected';
const popupTitleClasses = 'o-bb-group__title';
const sectionConfigClass = 'o-bb-section-config';
const sectionConfigGroupClass = 'o-bb-section-config__group';
const sectionConfigRowClass = 'o-bb-section-config__row';

class BrowsboxContentSectionConfig extends Component {
  static propTypes = {
    backgroundImage: PropTypes.string,
    backgroundPosition: PropTypes.string.isRequired,
    backgroundBehavior: PropTypes.string.isRequired,
    backgroundTransparency: PropTypes.number.isRequired,
    backgroundScrolling: PropTypes.bool.isRequired,
    backgroundFullWidth: PropTypes.bool.isRequired,
    clearContentOption: PropTypes.func.isRequired,
    colorSchemeClasses: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    colors: PropTypes.arrayOf(PropTypes.string).isRequired,
    contentColor: PropTypes.string,
    contentColorSchemeClass: PropTypes.string,
    marginsClasses: PropTypes.string,
    contentId: PropTypes.number.isRequired,
    contentType: PropTypes.string.isRequired, // eslint-disable-line react/no-unused-prop-types
    currentColumnCount: PropTypes.number,
    isContentLocked: PropTypes.bool.isRequired,
    isContentUnlockable: PropTypes.bool.isRequired,
    isDeletable: PropTypes.bool.isRequired,
    hasLayouts: PropTypes.bool.isRequired,
    hasSourceEditor: PropTypes.bool.isRequired,
    hasGoogleMaps: PropTypes.bool.isRequired,
    hasSlider: PropTypes.bool.isRequired,
    hasButtons: PropTypes.bool.isRequired,
    maxColumnCount: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]),
    moduleOptions: moduleOptionsProps.isRequired,
    onHidePopup: PropTypes.func.isRequired,
    onShowPopup: PropTypes.func.isRequired,
    onSectionSave: PropTypes.func.isRequired,
    openDeleteContent: PropTypes.func.isRequired,
    cloneContent: PropTypes.func.isRequired,
    openContentEditor: PropTypes.func.isRequired,
    openContentLayout: PropTypes.func.isRequired,
    setContentColumnCount: PropTypes.func.isRequired,
    setContentOption: PropTypes.func.isRequired,
    toggleContentViewOption: PropTypes.func.isRequired,
    toggleableComponentTypes: PropTypes.array.isRequired, // eslint-disable-line
    openGoogleMapsEditor: PropTypes.func.isRequired,
  };

  static defaultProps = {
    contentColor: '',
    contentColorSchemeClass: '',
    currentColumnCount: 0,
    maxColumnCount: false,
    marginsClasses: '',
    backgroundImage: null,
  };

  constructor(props) {
    super(props);
    this.hideColumnCount = this.hideColumnCount.bind(this);
    this.hideConfig = this.hideConfig.bind(this);
    this.hideButtonConfig = this.hideButtonConfig.bind(this);
    this.onColorChange = this.onColorChange.bind(this);
    this.onMarginsChange = this.onMarginsChange.bind(this);
    this.onBackgroundImageOptionsChange = this.onBackgroundImageOptionsChange.bind(this);
    this.onDeleteClick = this.onDeleteClick.bind(this);
    this.onSaveClick = this.onSaveClick.bind(this);
    this.onLayoutClick = this.onLayoutClick.bind(this);
    this.onSelectColumnCount = this.onSelectColumnCount.bind(this);
    this.onToggleClick = this.onToggleClick.bind(this);
    this.showColumnCount = this.showColumnCount.bind(this);
    this.showConfig = this.showConfig.bind(this);
    this.showButtonConfig = this.showButtonConfig.bind(this);
    this.showGoogleMapsModal = this.showGoogleMapsModal.bind(this);
    this.showSourceEditor = this.showSourceEditor.bind(this);
    this.showSliderEditView = debounce(this.showSliderEditView.bind(this), 200);
    this.onLockToggleClick = this.onLockToggleClick.bind(this);
    this.toggleBackgroundFullWidth = this.toggleBackgroundFullWidth.bind(this);
    this.setShowColorPicker = this.setShowColorPicker.bind(this);
    this.setShowMarginsPicker = this.setShowMarginsPicker.bind(this);
    this.setShowBackgroundPicker = this.setShowBackgroundPicker.bind(this);

    this.state = {
      showConfigMenu: false,
      showButtonConfigMenu: false,
      showColumnCountMenu: false,
      mediaPickerActive: typeof props.moduleOptions.backgroundImage !== 'undefined',
      colorPickerActive: props.colors.length !== 0
        || Object.keys(props.colorSchemeClasses).length !== 0,
      marginsPickerActive: props.moduleOptions.marginsClasses !== null,
      showColorPicker: false,
      showMarginsPicker: false,
      showBackgroundPicker: false,
    };
  }

  componentWillUnmount() {
    this.props.onHidePopup();
  }

  onLayoutClick(evt) {
    if (evt) {
      evt.preventDefault();
    }
    this.hideConfig();
    const { contentId: id } = this.props;
    this.props.openContentLayout({ id });
  }

  onToggleClick(blockType) {
    const { toggleableComponentTypes } = this.props;
    const toggleBlock = toggleableComponentTypes.filter(block => block.type === blockType).pop();
    if (toggleBlock.enabled !== false) {
      this.props.clearContentOption({
        option: blockType,
        contentId: this.props.contentId,
      });
    } else {
      this.props.setContentOption({
        option: blockType,
        value: this.props.moduleOptions[blockType],
        contentId: this.props.contentId,
      });
    }
  }

  onLockToggleClick(evt) {
    if (evt) {
      evt.preventDefault();
    }
    const { contentId, isContentLocked } = this.props;
    this.props.setContentOption({
      option: 'locked',
      value: !isContentLocked,
      isRootOption: true, // 'locked' option is located outside of section options object
      contentId,
    });
  }

  onSaveClick(evt) {
    evt.preventDefault();

    this.props.onSectionSave();
  }

  onDeleteClick(evt) {
    evt.preventDefault();
    this.props.openDeleteContent({ id: this.props.contentId, type: 'section' });
  }

  onCloneClick = (event) => {
    event.preventDefault();
    this.props.cloneContent({ id: this.props.contentId });
  };

  onColorChange(clear, color, useColorSchemeClass) {
    if (useColorSchemeClass && color) {
      this.props.setContentOption({
        option: 'colorSchemeClass',
        value: color,
        contentId: this.props.contentId,
      });
    } else if (useColorSchemeClass && clear === true) {
      this.props.clearContentOption({
        option: 'colorSchemeClass',
        contentId: this.props.contentId,
      });
    } else if (!useColorSchemeClass && clear === true) {
      this.props.clearContentOption({
        option: 'backgroundColor',
        contentId: this.props.contentId,
      });
    } else if (!useColorSchemeClass && color) {
      this.props.setContentOption({
        option: 'backgroundColor',
        value: color,
        contentId: this.props.contentId,
      });
    }
  }

  onMarginsChange(marginsClasses) {
    if (!marginsClasses) {
      this.props.clearContentOption({
        option: 'marginsClasses',
        contentId: this.props.contentId,
      });

      return;
    }

    this.props.setContentOption({
      option: 'marginsClasses',
      value: marginsClasses,
      contentId: this.props.contentId,
    });
  }

  onBackgroundImageOptionsChange(options) {
    Object.entries(options)
      .forEach(([option, value]) => this.props.setContentOption({
        option,
        value,
        contentId: this.props.contentId,
      }));
  }

  onSelectColumnCount(evt, value) {
    if (evt) {
      evt.preventDefault();
    }
    const {
      contentId,
      contentType,
    } = this.props;
    if (contentType === CONTENT_SECTION_TYPE.gallery) {
      this.props.setContentOption({
        option: 'columnCount',
        value,
        contentId: this.props.contentId,
      });
    } else {
      this.props.setContentColumnCount({
        value,
        contentId,
        contentType,
      });
    }
    this.hideColumnCount();
  }

  getButtonConfigSettingsId() {
    return `content-button-settings-${this.props.contentId}`;
  }

  setShowColorPicker(showPopup) {
    this.setState({
      showColorPicker: showPopup,
      showMarginsPicker: false,
      showBackgroundPicker: false,
    });
  }

  setShowMarginsPicker(showPopup) {
    this.setState({
      showColorPicker: false,
      showMarginsPicker: showPopup,
      showBackgroundPicker: false,
    });
  }

  setShowBackgroundPicker(showPopup) {
    this.setState({
      showColorPicker: false,
      showMarginsPicker: false,
      showBackgroundPicker: showPopup,
    });
  }

  hideConfig() {
    window.requestAnimationFrame(() => {
      this.setState({
        showConfigMenu: false,
      }, () => {
        this.props.onHidePopup();
      });
    });
  }

  hideButtonConfig() {
    window.requestAnimationFrame(() => {
      this.setState({
        showButtonConfigMenu: false,
      }, () => {
        this.props.onHidePopup();
      });
    });
  }

  showConfig(evt) {
    evt.preventDefault();
    this.props.onShowPopup();
    window.requestAnimationFrame(() => {
      this.setState({
        showConfigMenu: true,
        showColumnCountMenu: false,
        showButtonConfigMenu: false,
      });
    });
  }

  showButtonConfig(evt) {
    evt.preventDefault();
    this.props.onShowPopup();
    window.requestAnimationFrame(() => {
      this.setState({
        showConfigMenu: false,
        showColumnCountMenu: false,
        showButtonConfigMenu: true,
      });
    });
  }

  showGoogleMapsModal(evt) {
    evt.preventDefault();
    const {
      contentId: id,
    } = this.props;
    this.props.openGoogleMapsEditor({ id });
  }

  showSliderEditView(event) {
    event.persist();

    const {
      contentId: id,
    } = this.props;
    this.props.toggleContentViewOption({ id, option: 'showEditView' });
  }

  showSourceEditor(evt) {
    evt.preventDefault();
    const {
      contentId: id,
    } = this.props;
    this.props.openContentEditor({ id });
  }

  showColumnCount(evt) {
    evt.preventDefault();
    this.props.onShowPopup();
    window.requestAnimationFrame(() => {
      this.setState({
        showConfigMenu: false,
        showColumnCountMenu: true,
      });
    });
  }

  hideColumnCount() {
    window.requestAnimationFrame(() => {
      this.setState({
        showColumnCountMenu: false,
      }, () => {
        this.props.onHidePopup();
      });
    });
  }

  toggleBackgroundFullWidth() {
    const value = !this.props.backgroundFullWidth;

    this.props.setContentOption({
      option: 'backgroundFullWidth',
      value,
      contentId: this.props.contentId,
    });
  }

  renderToggleable(block, idx) {
    const {
      isContentLocked,
      isContentUnlockable,
    } = this.props;
    if (isContentLocked && !isContentUnlockable) {
      return null;
    }

    return (
      <div key={idx} className="c-bb-section-config__button">
        <a className="o-action-link" onClick={() => this.onToggleClick(block.type)}>
          <Icon name={block.type} />
        </a>
      </div>
    );
  }

  renderConfig(tetherTarget) {
    return (
      <div key="config" className="c-bb-section-config__button" onClick={this.showConfig} id={tetherTarget}>
        <a className="o-action-link">
          <Icon name="settings" />
        </a>
      </div>
    );
  }

  renderConfigMenu(tetherTarget) {
    const {
      backgroundImage,
      backgroundPosition,
      backgroundBehavior,
      backgroundTransparency,
      backgroundScrolling,
      backgroundFullWidth,
      colorSchemeClasses,
      colors,
      contentColor,
      contentColorSchemeClass,
      contentId,
      marginsClasses,
      moduleOptions,
      hasLayouts,
    } = this.props;

    const {
      colorPickerActive,
      marginsPickerActive,
      mediaPickerActive,
      showColorPicker,
      showMarginsPicker,
      showBackgroundPicker,
    } = this.state;

    const htmlFor = 'section-settings-config-menu-toggle';

    return (
      <Gateway into="popup">
        <BbPopup
          target={tetherTarget}
          className={popupClasses}
          onClose={this.hideConfig}
          closeOnClickInside={false}
          placement="bottom left"
        >
          <div className={sectionConfigClass}>
            <div className={sectionConfigGroupClass}>
              <div className={sectionConfigRowClass}>
                { hasLayouts && (
                <a onClick={this.onLayoutClick} className={popupItemClasses}>
                  <FontAwesomeIcon name="fak fa-section-layouts" />
                  {i18n.t('CONTENT.sectionLayout')}
                </a>
                )}
              </div>
              <div className={sectionConfigRowClass}>
                { marginsPickerActive
                  ? (
                    <BrowsboxContentMarginsPicker
                      className={popupItemClasses}
                      marginsClasses={marginsClasses}
                      defaultMarginsClasses={moduleOptions.marginsClasses}
                      onChange={this.onMarginsChange}
                      showPopup={showMarginsPicker}
                      setShowPopup={this.setShowMarginsPicker}
                    >
                      {i18n.t('CONTENT.margins')}
                    </BrowsboxContentMarginsPicker>
                  ) : null}
              </div>
            </div>
            <div className={sectionConfigGroupClass}>
              <div className={popupTitleClasses}>
                {i18n.t('CONTENT.background')}
              </div>
              <div className={sectionConfigRowClass}>
                { colorPickerActive
                  ? (
                    <BrowsboxContentColorPicker
                      className={popupItemClasses}
                      color={contentColor}
                      colorSchemeClass={contentColorSchemeClass}
                      colorSchemeClasses={sectionColorSchemeClasses(colorSchemeClasses)}
                      colors={colors}
                      onChange={this.onColorChange}
                      showPopup={showColorPicker}
                      setShowPopup={this.setShowColorPicker}
                    >
                      {i18n.t('CONTENT.backgroundColor')}
                    </BrowsboxContentColorPicker>
                  ) : null}
              </div>
              <div className={sectionConfigRowClass}>
                { mediaPickerActive
                  ? (
                    <BrowsboxContentBackgroundImage
                      className={popupItemClasses}
                      value={{
                        backgroundImage,
                        backgroundPosition,
                        backgroundBehavior,
                        backgroundTransparency,
                        backgroundScrolling,
                      }}
                      contentId={contentId}
                      contentType="section"
                      onChange={this.onBackgroundImageOptionsChange}
                      showPopup={showBackgroundPicker}
                      setShowPopup={this.setShowBackgroundPicker}
                    >
                      {i18n.t('CONTENT.backgroundImage')}
                    </BrowsboxContentBackgroundImage>
                  ) : null}
              </div>
              <div className={sectionConfigRowClass}>
                <div className="o-bb-section-config__checkbox">
                  <input
                    className="o-bb-checkbox__input"
                    id={htmlFor}
                    type="checkbox"
                    checked={Boolean(backgroundFullWidth)}
                    onChange={this.toggleBackgroundFullWidth}
                  />
                  <label className="o-bb-checkbox__label" htmlFor={htmlFor}>
                    {i18n.t('CONTENT.fullWidth')}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </BbPopup>
      </Gateway>
    );
  }

  renderButtonConfig() {
    const { hasButtons } = this.props;
    const id = this.getButtonConfigSettingsId();

    if (hasButtons) {
      return (
        <div key="button-config" className="c-bb-section-config__button">
          <a onClick={this.showButtonConfig} className="o-action-link" id={id}>
            <Icon name="button-content" />
          </a>
        </div>
      );
    }

    return null;
  }

  renderButtonConfigMenu() {
    const id = this.getButtonConfigSettingsId();

    return (
      <Gateway into="popup">
        <BbPopup
          target={id}
          className={popupClasses}
          onClose={this.hideButtonConfig}
          closeOnClickInside={false}
          placement="bottom left"
        >
          <ContentConfigButton
            contentId={this.props.contentId}
          />
        </BbPopup>
      </Gateway>
    );
  }

  renderGoogleMaps() {
    const { hasGoogleMaps } = this.props;
    if (hasGoogleMaps) {
      return (
        <div key="googleMapsEditor" className="c-bb-section-config__button">
          <a onClick={this.showGoogleMapsModal} className="o-action-link">
            <Icon name="google-maps" />
          </a>
        </div>
      );
    }
    return null;
  }

  renderSourceEditor() {
    const { hasSourceEditor } = this.props;
    if (hasSourceEditor) {
      return (
        <div key="sourceEditor" className="c-bb-section-config__button">
          <a onClick={this.showSourceEditor} className="o-action-link">
            <Icon name="code" />
          </a>
        </div>
      );
    }
    return null;
  }

  renderColumnCountMenuItem(value) {
    const { key, selected } = value;
    const label = `${key} ${key === 1 ? i18n.t('CONTENT.column') : i18n.t('CONTENT.columns')}`;
    if (selected) {
      return (
        <span key={key} className={popupItemSelectedClasses}>
          <span>#{label}</span>
        </span>
      );
    }
    return (
      <a key={key} onClick={evt => this.onSelectColumnCount(evt, key)} className={popupItemClasses}>
        <span>#{label}</span>
      </a>
    );
  }

  renderColumnCountMenu(tetherTarget) {
    const {
      currentColumnCount,
      maxColumnCount,
    } = this.props;
    const items = [...Array(maxColumnCount)].map((val, idx) => {
      const key = 1 + idx;
      const selected = key === currentColumnCount;
      return { key, selected };
    });
    return (
      <Gateway into="popup">
        <BbPopup
          target={tetherTarget}
          className={popupClasses}
          onClose={this.hideColumnCount}
          closeOnClickInside={false}
          placement="bottom left"
        >
          <div className={sectionConfigClass}>
            { items.map(val => this.renderColumnCountMenuItem(val)) }
          </div>
        </BbPopup>
      </Gateway>
    );
  }

  renderSave() {
    return (
      <div key="save" className="c-bb-section-config__button c-bb-section-config__button--save">
        <a onClick={this.onSaveClick} className="o-action-link">
          <Icon name="save" />
        </a>
      </div>
    );
  }

  renderDelete() {
    const {
      isDeletable,
    } = this.props;

    if (isDeletable) {
      return (
        <div key="delete" className="c-bb-section-config__button">
          <a onClick={this.onDeleteClick} className="o-action-link">
            <Icon name="delete" />
          </a>
        </div>
      );
    }
    return null;
  }

  renderClone() {
    return (
      <div key="clone" className="c-bb-section-config__button">
        <a onClick={this.onCloneClick} className="o-action-link">
          <Icon name="duplicate" title="Clone" />
        </a>
      </div>
    );
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  renderColumnCount(tetherTarget) {
    const {
      currentColumnCount,
      maxColumnCount,
    } = this.props;

    if (maxColumnCount) {
      return (
        <div key="columnCount" className="c-bb-section-config__button c-bb-section-config__button--text">
          <a onClick={this.showColumnCount} className="o-action-link" id={tetherTarget}>
            #{currentColumnCount}
          </a>
        </div>
      );
    }
    return null;
  }

  renderSliderEditView() {
    const {
      hasSlider,
    } = this.props;

    if (hasSlider) {
      return (
        <div key="sliderEditView" className="c-bb-section-config__button">
          <a className="o-action-link c-action-link--toggle-slider-view" onClick={this.showSliderEditView}>
            <Icon name="slider" />
          </a>
        </div>
      );
    }
    return null;
  }

  render() {
    const {
      showConfigMenu,
      showButtonConfigMenu,
      showColumnCountMenu,
    } = this.state;
    const {
      contentId,
      toggleableComponentTypes,
    } = this.props;
    const settingsTetherTarget = `content-settings-${contentId}`;
    const columnCountTetherTarget = `content-columncount-${contentId}`;

    return (
      <div className="c-bb-section-config__wrapper">
        <div className="c-bb-section-config__left">
          { this.renderConfig(settingsTetherTarget) }
          { this.renderSliderEditView() }
          { this.renderSourceEditor() }
          { this.renderGoogleMaps() }
          { this.renderButtonConfig() }
          { toggleableComponentTypes.map(this.renderToggleable, this) }
          { this.renderColumnCount(columnCountTetherTarget) }
          { this.renderSave() }
          { this.renderDelete() }
          { this.renderClone() }
        </div>
        <div className="c-bb-section-config__right">
          { showConfigMenu && this.renderConfigMenu(settingsTetherTarget) }
          { showButtonConfigMenu && this.renderButtonConfigMenu() }
          { showColumnCountMenu && this.renderColumnCountMenu(columnCountTetherTarget) }
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { modules } = state.entities;
  const moduleDef = modules[ownProps.contentType] || {};
  const columns = flatten(map(moduleDef.columns, 'components'));
  const componentTypes = map(columns, 'type');
  const colors = moduleDef.colorPicker || [];
  const colorSchemeClasses = moduleDef.colorSchemeClasses || {};
  const hasLayouts = Array.isArray(moduleDef.layouts) && moduleDef.layouts.length > 0;
  const hasSourceEditor = componentTypes.indexOf(CONTENT_COMPONENT_TYPE.html) !== -1;
  const hasGoogleMaps = componentTypes.indexOf(CONTENT_COMPONENT_TYPE.googleMaps) !== -1;
  const hasSlider = CONTENT_SECTION_TYPE.slider === moduleDef.type;
  const hasButtons = ![
    CONTENT_COMPONENT_TYPE.divider,
    CONTENT_COMPONENT_TYPE.breadcrumbs,
    CONTENT_COMPONENT_TYPE.html,
    CONTENT_COMPONENT_TYPE.news,
  ].includes(ownProps.contentType);

  return {
    colorSchemeClasses,
    colors,
    hasLayouts,
    hasSourceEditor,
    hasGoogleMaps,
    hasSlider,
    hasButtons,
  };
};

const mapDispatchToProps = {
  clearContentOption,
  openContentEditor,
  openContentLayout,
  openDeleteContent,
  cloneContent,
  openGoogleMapsEditor,
  setContentColumnCount,
  setContentOption,
  toggleContentViewOption,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxContentSectionConfig);
