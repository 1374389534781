import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import get from 'lodash/get';
import {
  changeBaseModuleLang,
  updateBaseModule,
  saveBaseModule,
} from '../../actions/baseModule';
import BaseModuleEditForm from './BaseModuleEditForm';
import { getDefaultLang, getCurrentModuleFormInputs, getModuleByName } from '../../selectors';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

class BaseModuleUpdate extends Component {
  static propTypes = {
    defaultLang: PropTypes.object.isRequired,
    lang: PropTypes.string.isRequired,
    langs: PropTypes.array.isRequired,
    changeBaseModuleLang: PropTypes.func.isRequired,
    updateBaseModule: PropTypes.func.isRequired,
    saveBaseModule: PropTypes.func.isRequired,
    fieldTypes: PropTypes.array.isRequired,
    initialValues: PropTypes.object,
    currentModule: PropTypes.object.isRequired,
  };

  static defaultProps = {
    initialValues: null,
  };

  constructor(props) {
    super(props);
    this.changeLang = this.changeLang.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  async handleSubmit(value) {
    const traverse = (categories, lang) => categories.map((category) => {
      let children = [];
      if (category.children && category.children.categories && category.children.categories.length) {
        children = traverse(category.children.categories, lang);
      }

      return {
        id: get(category, `id.${lang}`),
        title: category.title[lang],
        image: get(category, 'image.id', null),
        image_meta: {
          alt: get(category, 'image.alt', null),
          tooltip: get(category, 'image.tooltip', null),
          hyperlink: get(category, 'image.hyperlink', null),
        },
        children,
      };
    });

    const getLangEntity = function getLangEntity(formData, lang) {
      return {
        id: formData.id[lang],
        label: formData[`label_${lang}`],
        hasDetailPages: Boolean(formData.hasDetailPages),
        sortingEnabled: Boolean(formData.sortingEnabled),
        internalName: formData.internalName,
        language: lang,
        fields: formData.fields && formData.fields.map(field => (
          {
            id: get(field, `id.${lang}`),
            label: field.label[lang],
            internalName: field.internalName,
            type: field.type,
            options: field.options,
            settings: field.settings,
          }
        )),
        categories: formData.categories && traverse(formData.categories, lang),
        columns: formData.columns,
        templateRouteTitle: formData.templateRouteTitle,
        templatePageTitle: formData.templatePageTitle,
        templateMetaDescription: formData.templateMetaDescription,
      };
    };

    const entities = this.props.langs.map(lng => getLangEntity(value, lng.code));

    const actions = entities.map((entity) => {
      // check if entity needs update or create if ID is not defined yet
      const action = entity.id ? this.props.updateBaseModule : this.props.saveBaseModule;
      return action(entity);
    });

    await Promise.all(actions);
    this.redirectToItems(value.internalName);
  }

  redirectToItems = (internalName = null) => {
    const name = internalName || this.props.match.params.name;
    // eslint-disable-next-line react/prop-types
    this.props.history.push(`/items/${name}`);
  };

  changeLang = (lang) => {
    this.props.changeBaseModuleLang(lang);
  };

  onCancel = () => {
    this.redirectToItems();
  };

  render() {
    return (
      <div className="c-bb-base-module">
        <div className="c-bb-base-module__header">
          <FontAwesomeIcon name={this.props.currentModule.icon || 'fak fa-module'} className="c-bb-base-module__icon" />
          <h1 className="c-bb-base-module__title">
            {this.props.currentModule.label}
          </h1>
        </div>
        <div className="c-bb-base-module-body">
          <div className="c-bb-form c-bb-form--has-header">
            <BaseModuleEditForm
              form={`baseModuleForm_${this.props.currentModule.id}`}
              onSubmit={this.handleSubmit}
              onCancel={this.onCancel}
              initialValues={this.props.initialValues}
              lang={this.props.lang}
              langs={this.props.langs}
              defaultLang={this.props.defaultLang}
              fieldTypes={this.props.fieldTypes}
            />
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, props) => {
  const currentModule = getModuleByName(state, props.match.params.name);

  return {
    key: currentModule.id,
    isOpen: state.baseModule.isOpen,
    lang: state.baseModule.lang,
    currentModule,
    defaultLang: getDefaultLang(state),
    langs: state.baseModule.langs,
    fieldTypes: state.baseModule.fieldTypes,
    initialValues: getCurrentModuleFormInputs(currentModule)(state),
  };
};

const mapDispatchToProps = {
  changeBaseModuleLang,
  updateBaseModule,
  saveBaseModule,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleUpdate));
