import React from 'react';
import PropTypes from 'prop-types';
import BrowsboxSavedSectionsItem from './BrowsboxSavedSectionsItem';
import i18n from '../../internationalization/i18n';

const BrowsboxSavedSectionsList = ({ savedSections }) => (
  <div className="c-bb-saved-sections">
    <div className="c-bb-saved-sections__title">
      {i18n.t('CONTENT.savedLayoutBlocks')}
    </div>
    {savedSections.length > 0 && (
    <ul className="c-bb-saved-sections__list">
      {savedSections.map(savedSection => (
        <li className="c-bb-saved-sections__item" key={savedSection.id}>
          <BrowsboxSavedSectionsItem savedSection={savedSection} />
        </li>
      ))}
    </ul>
    )}
    {savedSections.length === 0 && (
    <div className="c-bb-saved-sections__empty">
      {i18n.t('CONTENT.noSavedLayoutBlocks')}
    </div>
    )}
  </div>
);

BrowsboxSavedSectionsList.propTypes = {
  savedSections: PropTypes.oneOfType([
    PropTypes.array,
    PropTypes.object,
  ]).isRequired,
};

export default BrowsboxSavedSectionsList;
