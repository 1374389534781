import React from 'react';
import PropTypes from 'prop-types';
import color from 'react-color/lib/helpers/color';
import { ColorWrap, Checkboard, Swatch } from 'react-color/lib/components/common';
import map from 'lodash/map';

export const BlockSwatches = ({ colors = [], onClick, onSwatchHover }) => (
  <div className="c-bb-color-picker__swatches">
    { map(colors, c => (
      <Swatch
        key={c}
        className="c-bb-color-picker__swatch"
        color={c}
        onClick={onClick}
        onHover={onSwatchHover}
      />
    )) }
  </div>
);

BlockSwatches.propTypes = {
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  onClick: PropTypes.func.isRequired,
  onSwatchHover: PropTypes.func.isRequired,
};

export const Block = ({
  onChange, onSwatchHover, hex, colors, className = '',
}) => {
  const transparent = hex === 'transparent';

  const getContrastingColor = (data) => {
    if (!data) {
      return '#fff';
    }
    const col = color.toState(data);
    if (col.hex === 'transparent') {
      return 'rgba(0,0,0,0.4)';
    }
    const yiq = ((col.rgb.r * 299) + (col.rgb.g * 587) + (col.rgb.b * 114)) / 1000;
    return (yiq >= 128) ? '#000' : '#fff';
  };

  const handleChange = (hexCode, e) => {
    if (!color.isValidHex(hexCode)) {
      return;
    }

    onChange({
      hex: hexCode,
      source: 'hex',
    }, e);
  };

  return (
    <div className={`c-bb-color-picker block-picker ${className}`}>
      <div
        className="c-bb-color-picker__head"
        style={{
          background: hex,
        }}
      >
        { transparent && (
          <Checkboard borderRadius="6px 6px 0 0" />
        ) }
        <div
          className="c-bb-color-picker__label"
          style={{ color: getContrastingColor(hex) }}
        >
          { hex }
        </div>
      </div>
      <div className="c-bb-color-picker__body">
        <BlockSwatches
          colors={colors}
          onClick={handleChange}
          onSwatchHover={onSwatchHover}
        />
      </div>
    </div>
  );
};

Block.propTypes = {
  className: PropTypes.string,
  hex: PropTypes.string,
  onChange: PropTypes.func,
  onSwatchHover: PropTypes.func,
  colors: PropTypes.arrayOf(PropTypes.string),
};

Block.defaultProps = {
  className: '',
  hex: '',
  onChange: null,
  onSwatchHover: () => {},
  colors: [],
};

export default ColorWrap(Block);
