/* eslint-disable no-template-curly-in-string */
export const ROUTE_MEDIA = '/api/media';
export const ROUTE_MEDIA_MOVE = '/api/media/${id}/move';
export const ROUTE_MEDIA_SEARCH = '/api/media/search';
export const ROUTE_MEDIA_FOLDERS_ROOT = '/api/media/folders';
export const ROUTE_MEDIA_FOLDERS = '/api/media/folders/${id}';
export const ROUTE_MEDIA_FOLDER_MOVE = '/api/media/folders/${id}/move';
export const ROUTE_MEDIA_FOLDER_PATH = '/api/media/folders/path/${id}';
export const ROUTE_MEDIA_FOLDER_PATH_ROOT = '/api/media/folders/path';
export const ROUTE_MEDIA_CROP = '/api/media/${id}/crop';
export const ROUTE_MEDIA_FILE = '/api/media/${id}';
export const ROUTE_MEDIA_GET = '/media/get/web';
export const ROUTE_MEDIA_GET_LARGE = '/media/get/large';
export const ROUTE_MEDIA_GET_ORIGINAL = '/media/get/original';
export const ROUTE_MEDIA_RENAME = '/api/media/${id}/filename';
export const ROUTE_MEDIA_UPLOAD = '/api/media';
export const ROUTE_MODULES = '/api/blocks';
export const ROUTE_PAGES = '/api/routes';
export const ROUTE_PAGE_CONTENT = '/api/pages/${id}/sections';
export const ROUTE_PAGE_DUPLICATE = '/api/routes/${id}/duplicate';
export const ROUTE_PAGE_FOOTER_HTML = '/api/pages/page/footer-html';
export const ROUTE_PAGE_HEADER_HTML = '/api/pages/page/header-html';
export const ROUTE_PAGE_ITEM = '/api/routes/${id}';
export const ROUTE_PAGE_NEW = '/api/routes';
export const ROUTE_PAGE_PUBLISH = '/api/routes/${id}/publish';
export const ROUTE_PAGE_REORDER = '/api/routes/order';
export const ROUTE_PAGE_RESTRICT = '/api/routes/${id}/restrict';
export const ROUTE_PAGE_TYPES = '/api/page/types';
export const ROUTE_PAGE_UNPUBLISH = '/api/routes/${id}/unpublish';
export const ROUTE_PAGE_UNRESTRICT = '/api/routes/${id}/unrestrict';
export const ROUTE_SAVED_SECTIONS = '/api/saved-sections';
export const ROUTE_SAVED_SECTION = '/api/saved-sections/${savedSectionId}';
export const ROUTE_LANGUAGES = '/api/languages';
export const ROUTE_CSS_EDITOR = '/api/css-editor';
export const ROUTE_JS_EDITOR = '/api/js-editor';
export const ROUTE_SESSION_ACTIVITY = '/api/sessions/activities';
export const ROUTE_LOGOUT = '/logout';
export const ROUTE_WEBSITE_SETTINGS = '/api/website/settings';
export const ROUTE_BASEMODULES_ENTITIES = '/api/base-module/entities';
export const ROUTE_BASEMODULES_ENTITY = '/api/base-module/entities/${id}';
export const ROUTE_BASEMODULE_ENTITIES = '/api/base-module/entities/all';
export const ROUTE_BASEMODULE_FIELD_TYPES = '/api/base-module/entities/field-types';
export const ROUTE_BASEMODULE_ITEMS = '/api/base-module/items/${entityId}/entity';
export const ROUTE_BASEMODULE_ITEMS_SEARCH = '/api/base-module/items/search';
export const ROUTE_BASEMODULE_ALL_ITEMS = '/api/base-module/items/all';
export const ROUTE_BASEMODULE_ITEM = '/api/base-module/items/${itemId}';
export const ROUTE_BASEMODULE_ITEM_MOVE = '/api/base-module/items/move/${itemId}';
export const ROUTE_BASEMODULE_ITEM_CLONE = '/api/base-module/items/clone/${itemId}';
export const ROUTE_BASEMODULE_ITEM_SEO_PREVIEW = '/api/base-module/items/seo-preview/${entityId}';
export const ROUTE_BASEMODULE_ITEM_PUBLISH = '/api/routes/${routeId}/publish';
export const ROUTE_BASEMODULE_ITEM_UNPUBLISH = '/api/routes/${routeId}/unpublish';
export const ROUTE_BASEMODULE_ITEMS_ORDER = '/api/base-module/items/${id}/order';
export const ROUTE_NSI_PRODUCT_TYPES = '/nsi/product/types';
export const ROUTE_NSI_PRODUCT_TYPE = '/api/nsi/product/${id}/type';
export const ROUTE_NSI_DETAIL_FILTERS = '/api/nsi/product/detail-filters';
export const ROUTE_NSI_ITEM_DETAIL_FILTERS = '/api/nsi/product/${id}/detail-filters';
export const ROUTE_CURRENT_PAGE = '/api/routes/current';
export const ROUTE_BROWSBOX_LANGUAGES = '/api/languages/browsbox';
export const ROUTE_TAGS_GET = '/api/routes/tags';
export const ROUTE_PAGE_HTML_CONTENT = '/api/routes/${id}/html-content';
export const ROUTE_BROWSBOX_MENU = '/api/browsbox-menu/configuration-items';
export const ROUTE_USERS = '/api/users';
export const ROUTE_USER = '/api/users/${id}';
export const ROUTE_USER_ENABLE = '/api/users/${id}/enable';
export const ROUTE_GENERAL_SETTINGS = '/api/general-settings';
