import React, { useContext } from 'react';
import Icon from '../Icon/Icon';
import ContentComponentDragContext from './ContentComponentDragContext';

function BrowsboxContentComponentMoveHandle() {
  const drag = useContext(ContentComponentDragContext);

  if (!drag) {
    return null;
  }

  return (
    <button
      type="button"
      className="c-component-tools__button"
      ref={drag}
    >
      <Icon name="drag" />
    </button>
  );
}

export default BrowsboxContentComponentMoveHandle;
