/* eslint-disable react/no-danger */
import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { htmlDecode, htmlEncode } from 'htmlencode';
import { CONTENT_COMPONENT_TYPE } from '@liswood-tache/browsbox-static';
import Editor from '../../Editor/Editor';
import useShowUiControls from '../../../hooks/use-show-ui-controls';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  content: PropTypes.string,
  fromSectionOption: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  id: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  content: '',
  fromSectionOption: false,
  type: CONTENT_COMPONENT_TYPE.title,
};

const ContentComponentTitle = (props) => {
  const {
    classes,
    containerClassName,
    content: contentEncoded,
    fromSectionOption,
    id,
    type,
  } = props;
  const content = htmlDecode(contentEncoded);
  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
  );
  const showUiControls = useShowUiControls();

  const onChange = (newContent) => {
    const newContentEncode = htmlEncode(newContent);
    props.onChange({ id, type, fromSectionOption }, newContentEncode);
  };

  // render edit behavior
  if (showUiControls) {
    return (
      <Editor
        content={content}
        type={type}
        onChange={onChange}
        className={componentClasses}
      />
    );
  }
  // render target is preview or html
  return (
    <div
      dangerouslySetInnerHTML={{ __html: content }}
      className={componentClasses}
    />
  );
};

ContentComponentTitle.propTypes = propTypes;
ContentComponentTitle.defaultProps = defaultProps;

export default ContentComponentTitle;
