import classNames from 'classnames';
import React from 'react';
import PropTypes from 'prop-types';
import { getLargeImageUrl } from './utils/media';

const ContentItemBackground = ({
  behavior, transparency, position, scrolling, image,
}) => {
  const classes = classNames('c-background', {
    'c-background--cover': behavior === 'cover',
    'c-background--tiled': behavior === 'tiled',
    'c-background--actual-size': behavior === 'actual_size',
    'c-background--left-top': position === 'left_top',
    'c-background--left-center': position === 'left_center',
    'c-background--left-bottom': position === 'left_bottom',
    'c-background--center-top': position === 'center_top',
    'c-background--center-center': position === 'center_center',
    'c-background--center-bottom': position === 'center_bottom',
    'c-background--right-top': position === 'right_top',
    'c-background--right-center': position === 'right_center',
    'c-background--right-bottom': position === 'right_bottom',
    'c-background--fixed': !scrolling,
  });

  const styles = {
    backgroundImage: image ? `url(${getLargeImageUrl(image)})` : undefined,
    opacity: 1 - transparency / 100,
  };

  return (
    <div className={classes}>
      <div className="c-background__image" style={styles} />
    </div>
  );
};

ContentItemBackground.propTypes = {
  image: PropTypes.string,
  position: PropTypes.string.isRequired,
  behavior: PropTypes.string.isRequired,
  transparency: PropTypes.number.isRequired,
  scrolling: PropTypes.bool.isRequired,
};

ContentItemBackground.defaultProps = {
  image: null,
};

export default ContentItemBackground;
