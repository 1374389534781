import {
  USERS_OPEN_VIEW,
  USERS_CLOSE_VIEW,
  USERS_FETCH_SUCCESS,
  USERS_FETCH_REQUEST,
  USERS_FETCH_FAILURE,
  USERS_READ_SUCCESS,
  USERS_SET_SEARCH_QUERY,
  USERS_SET_SORT_BY,
  USERS_SET_PER_PAGE,
  USERS_SET_PAGE,
} from '../actions/users';
import { OPEN_BASE_MODULE_VIEW, CHANGE_BASE_MODULE_SCREEN } from '../actions/baseModule';
import { GENERAL_SETTINGS_OPEN_VIEW } from '../actions/generalSettings';

const initialState = {
  isOpen: false,
  isLoading: true,
  items: {},
  ids: [],
  searchQuery: '',
  sortBy: 'email',
  sortDirection: 'asc',
  total: 0,
  page: 1,
  perPage: 50,
};

export default function users(state = initialState, action) {
  switch (action.type) {
    case USERS_OPEN_VIEW: {
      return {
        ...state,
        isOpen: true,
      };
    }
    case OPEN_BASE_MODULE_VIEW:
    case CHANGE_BASE_MODULE_SCREEN:
    case GENERAL_SETTINGS_OPEN_VIEW:
    case USERS_CLOSE_VIEW: {
      return {
        ...state,
        isOpen: false,
      };
    }
    case USERS_FETCH_REQUEST: {
      return {
        ...state,
        isLoading: true,
      };
    }
    case USERS_FETCH_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        items: {
          ...state.items,
          ...action.response.entities.users,
        },
        ids: action.response.result.data,
        total: action.response.result.total,
      };
    }
    case USERS_FETCH_FAILURE: {
      return {
        ...state,
        isLoading: false,
      };
    }
    case USERS_READ_SUCCESS: {
      return {
        ...state,
        items: {
          ...state.items,
          ...action.response.entities.users,
        },
      };
    }
    case USERS_SET_SEARCH_QUERY: {
      return {
        ...state,
        searchQuery: action.payload,
        page: 1,
      };
    }
    case USERS_SET_PAGE: {
      return {
        ...state,
        page: action.payload,
      };
    }
    case USERS_SET_PER_PAGE: {
      return {
        ...state,
        perPage: action.payload,
      };
    }
    case USERS_SET_SORT_BY: {
      const field = action.payload;

      return {
        ...state,
        sortBy: field,
        sortDirection: state.sortDirection === 'asc' ? 'desc' : 'asc',
      };
    }
    default:
      return state;
  }
}
