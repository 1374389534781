import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withNamespaces } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';
import {
  setBaseModuleListFilters,
  reorderBaseModuleItems,
} from '../../../actions/baseModuleItems';
import BaseModuleItemsList from './BaseModuleItemsList';
import i18n from '../../../internationalization/i18n';
import BaseModuleItemsFilters from './BaseModuleItemsFilters';
import { getModuleByName, selectBaseModuleItemsList } from '../../../selectors';
import { BbButton } from '../../@browsbox-ui';
import FontAwesomeIcon from '../../Icon/FontAwesomeIcon';

const propTypes = {
  itemsList: PropTypes.array.isRequired,
  setBaseModuleListFilters: PropTypes.func.isRequired,
  reorderBaseModuleItems: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  currentModule: PropTypes.any.isRequired,
  filters: PropTypes.object.isRequired,
};

const defaultProps = {
  itemListFilterByCategory: null,
  currentModuleItem: null,
};

const BaseModuleItems = (props) => {
  const {
    isLoading, itemsList, currentModule,
  } = props;
  const history = useHistory();
  const { name } = useParams();

  const onSortEnd = ({ oldIndex, newIndex }) => {
    // TODO:: How to sort items on multi-language mode?
    props.reorderBaseModuleItems({ oldIndex, newIndex });
  };

  const handleCreateModuleItem = React.useCallback(
    () => history.push(`/item/create/${name}`),
    [history, name],
  );

  const handleFiltersChange = React.useCallback((filters) => {
    props.setBaseModuleListFilters(filters);
  }, [props.setBaseModuleListFilters]);

  if (!currentModule) {
    return null;
  }

  return (
    <div className="c-bb-base-module">
      <div className="c-bb-base-module__header">
        <FontAwesomeIcon name={currentModule.icon || 'fak fa-module'} className="c-bb-base-module__icon" />
        <h1 className="c-bb-base-module__title">
          {props.currentModule && props.currentModule.label}
        </h1>
        <BbButton
          primary
          onClick={handleCreateModuleItem}
        >
          <FontAwesomeIcon name="fas fa-plus" className="c-bb-button__icon" />
          {i18n.t('CONTENT.create').toUpperCase()}
        </BbButton>
      </div>
      <div className="c-bb-base-module-items-container">
        <BaseModuleItemsFilters
          initialFilters={props.filters}
          onChange={handleFiltersChange}
        />
        <BaseModuleItemsList
          items={itemsList}
          isLoading={isLoading}
          onSortEnd={onSortEnd}
          sortingEnabled={props.currentModule.sorting_enabled}
          currentModule={props.currentModule}
        />
      </div>
    </div>
  );
};

BaseModuleItems.propTypes = propTypes;
BaseModuleItems.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const currentModule = getModuleByName(state, props.match.params.name);

  return {
    key: currentModule ? currentModule.id : undefined,
    itemsList: selectBaseModuleItemsList(state),
    currentModule,
    isLoading: state.baseModuleItems.fetchingItemsList,
    filters: state.baseModuleItems.filters,
  };
};

const mapDispatchToProps = {
  reorderBaseModuleItems,
  setBaseModuleListFilters,
};

export default withNamespaces()(connect(mapStateToProps, mapDispatchToProps)(BaseModuleItems));
