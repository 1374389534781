import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import BbButton from '@browsbox-ui/bb-button';
import ModalFooter from './ModalFooter';
import ModalHeader from './ModalHeader';
import { FormInput } from '../FormElements';
import i18n from '../../internationalization/i18n';
import Modal from './Modal';
import Spinner from '../Spinner';

const propTypes = {
  children: PropTypes.node, // Modal data
  icon: PropTypes.string, // Modal data
  onCancel: PropTypes.func.isRequired, // Modal data
  onClose: PropTypes.func.isRequired, // Modal data
  onOk: PropTypes.func.isRequired, // Modal data
  submitButtonText: PropTypes.string, // Modal data
  title: PropTypes.string.isRequired, // Modal data
  value: PropTypes.string, // Modal data, input value
  valueMaxLength: PropTypes.number, // Modal data, max input value length
  label: PropTypes.string.isRequired, // Modal data, input label
  showRequired: PropTypes.bool, // Modal data, input label
  isLoading: PropTypes.bool, // Modal data, show spinner
  validate: PropTypes.func,
};

const defaultProps = {
  children: null,
  icon: '',
  value: '',
  validate: () => {},
  submitButtonText: null,
  valueMaxLength: 125,
  showRequired: true,
  isLoading: false,
};

class PromptModal extends Component {
  constructor(props) {
    super(props);
    this.onOk = this.onOk.bind(this);
    this.onCancel = this.onCancel.bind(this);
    this.onInputChange = this.onInputChange.bind(this);
    this.state = {
      value: props.value,
      error: null,
    };
  }

  onOk() {
    this.props.onOk(this.state);
  }

  onCancel(evt) {
    evt.preventDefault();
    this.props.onCancel();
  }

  // Handle input element value change
  onInputChange(evt) {
    const { target: { value } } = evt;
    let error = null;

    if (this.props.validate) {
      error = this.props.validate(value);
    }

    if (String(value).length > this.props.valueMaxLength) {
      return false;
    }

    return this.setState({ value, error });
  }

  onFormSubmit = (event) => {
    event.preventDefault();

    if (this.state.error) {
      return;
    }

    this.onOk();
  };

  render() {
    const {
      children,
      icon,
      onClose,
      title,
      valueMaxLength,
      label,
      showRequired,
      isLoading,
    } = this.props;

    const {
      value,
      error,
    } = this.state;

    const submitButtonText = this.props.submitButtonText || i18n.t('CONTENT.save');
    const isValid = (value.length > 0 && value.length < valueMaxLength) && !error;

    return (
      <Modal
        onClose={onClose}
        backdropClassName="o-bb-backdrop--high"
        modalClassName="o-bb-modal--confirmation"
      >
        <div>
          <ModalHeader icon={icon} title={title} />
          <div className="o-bb-modal__content">
            { isLoading && <Spinner size={4} /> }

            { !isLoading && (
            <>
              {children}
              <form onSubmit={this.onFormSubmit}>
                <FormInput
                  id="prompt-modal-value"
                  maxLength={valueMaxLength}
                  name="prompt-modal-value"
                  value={value}
                  valueLength={String(value).length}
                  onChange={this.onInputChange}
                  type="text"
                  label={label}
                  required
                  showRequired={showRequired}
                />
              </form>
            </>
            )}
            {error && (
              <div>
                <span className="o-bb-modal__error">
                  {error}
                </span>
              </div>
            )}
          </div>
        </div>
        <Modal.Context>
          {({ closeModal }) => (
            <ModalFooter>
              <BbButton
                className="c-bb-button--primary"
                disabled={!isValid}
                onClick={this.onOk}
                text={submitButtonText}
              />
              <BbButton
                className="c-bb-button--secondary"
                onClick={closeModal}
                text={i18n.t('CONTENT.cancel')}
              />
            </ModalFooter>
          )}
        </Modal.Context>
      </Modal>
    );
  }
}

PromptModal.propTypes = propTypes;
PromptModal.defaultProps = defaultProps;

const mapStateToProps = state => ({
  pageTypes: state.entities.pageTypes,
});

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(PromptModal);
