import React from 'react';
import PropTypes from 'prop-types';
import SuperAdminOnly from '../AcessControll/SuperAdminOnly';

export default function SuperAdminInFooterBuilderOnly({ children, contentType }) {
  if (contentType !== 'footer-builder') {
    return children;
  }

  return (
    <SuperAdminOnly>
      {children}
    </SuperAdminOnly>
  );
}

SuperAdminInFooterBuilderOnly.propTypes = {
  children: PropTypes.node.isRequired,
  contentType: PropTypes.string.isRequired,
};
