export const ADD_CONTENT = 'ADD_CONTENT';
export const addContent = value => ({ type: ADD_CONTENT, value });

export const ADD_COMPONENT = 'ADD_COMPONENT';
export const addComponent = value => ({ type: ADD_COMPONENT, value });

export const ADD_COMPONENT_TO_COLUMN = 'ADD_COMPONENT_TO_COLUMN';
export const addComponentToColumn = value => ({ type: ADD_COMPONENT_TO_COLUMN, value });

export const INSERT_COMPONENT_TO_COLUMN = 'INSERT_COMPONENT_TO_COLUMN';
export const insertComponentToColumn = value => ({ type: INSERT_COMPONENT_TO_COLUMN, value });

export const CLEAR_LAST_ADDED_CONTENT_ID = 'CLEAR_LAST_ADDED_CONTENT_ID';
export const clearLastAddedContentId = () => ({ type: CLEAR_LAST_ADDED_CONTENT_ID });

export const MOVE_COMPONENT_TO_COLUMN = 'MOVE_COMPONENT_TO_COLUMN';
export const moveComponentToColumn = value => ({ type: MOVE_COMPONENT_TO_COLUMN, value });
