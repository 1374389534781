import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../internationalization/i18n';

function PopupItem({ value, onClick }) {
  const { key, selected } = value;
  const label = `${key} ${key === 1 ? i18n.t('CONTENT.column') : i18n.t('CONTENT.columns')}`;

  const handleOnClick = (e) => {
    e.preventDefault();

    onClick(key);
  };

  if (selected) {
    return (
      <span key={key} className="o-bb-popup--config-action o-bb-popup--config-selected">
        <span>#{label}</span>
      </span>
    );
  }

  return (
    <a key={key} onClick={handleOnClick} className="o-bb-popup--config-action">
      <span>#{label}</span>
    </a>
  );
}

function BlockColumnCountPicker({
  onChange, maxColumnCount, currentColumnCount,
}) {
  const items = [...Array(maxColumnCount)].map((val, idx) => {
    const key = 1 + idx;
    const selected = key === currentColumnCount;
    return { key, selected };
  });

  return (
    <div className="o-bb-section-config">
      { items.map((value) => <PopupItem key={value.key} value={value} onClick={onChange} />) }
    </div>
  );
}

BlockColumnCountPicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  currentColumnCount: PropTypes.number.isRequired,
  maxColumnCount: PropTypes.number.isRequired,
};

export default BlockColumnCountPicker;
