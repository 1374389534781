import React from 'react';
import { useDrag } from 'react-dnd';
import PropTypes from 'prop-types';

import './ContentColumnResizeHandle.scss';
import { DND_BUILDER_RESIZABLE_COLUMN } from '../DragAndDrop/dndTypes';

const BrowsboxContentColumnResizeHandle = ({ columnId }) => {
  const [, drag] = useDrag(() => ({
    type: DND_BUILDER_RESIZABLE_COLUMN,
    item: { id: columnId },
  }), [columnId]);

  return (
    <div className="l-column__resize-handle-container">
      <div className="l-column__resize-handle" ref={drag} />
    </div>
  );
};

BrowsboxContentColumnResizeHandle.propTypes = {
  columnId: PropTypes.number.isRequired,
};

export default BrowsboxContentColumnResizeHandle;
