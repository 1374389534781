import {
  CLOSE_REMOVE_SAVED_SECTION,
  CLOSE_RENAME_SAVED_SECTION,
  CLOSE_SAVE_SECTION, OPEN_REMOVE_SAVED_SECTION,
  OPEN_RENAME_SAVED_SECTION,
  OPEN_SAVE_SECTION, REMOVE_SAVED_SECTION_FAILURE, REMOVE_SAVED_SECTION_REQUEST, REMOVE_SAVED_SECTION_SUCCESS,
  RENAME_SAVED_SECTION_FAILURE, RENAME_SAVED_SECTION_REQUEST, RENAME_SAVED_SECTION_SUCCESS,
  SAVE_SECTION_FAILURE,
  SAVE_SECTION_REQUEST, SAVE_SECTION_SUCCESS,
  SAVED_SECTIONS_FAILURE,
  SAVED_SECTIONS_REQUEST,
  SAVED_SECTIONS_SUCCESS,
} from '../actions/savedSections';

const initialState = {
  areSavedSectionsLoading: false,
  savedSections: {},
  isSaveSectionModalOpen: false,
  saveSectionProps: null,
  isRenameSavedSectionModalOpen: false,
  renameSavedSectionProps: null,
  isRemoveSavedSectionModalOpen: false,
  removeSavedSectionProps: null,
  isSaveSectionLoading: false,
  isRenameSavedSectionLoading: false,
  isRemoveSavedSectionLoading: false,
};

const savedSections = (state = initialState, action) => {
  switch (action.type) {
    case SAVED_SECTIONS_REQUEST:
      return {
        ...state,
        areSavedSectionsLoading: true,
      };

    case SAVED_SECTIONS_SUCCESS:
      return {
        ...state,
        areSavedSectionsLoading: false,
        savedSections: action.response.entities.savedSections || {},
      };

    case SAVED_SECTIONS_FAILURE:
      return {
        ...state,
        areSavedSectionsLoading: false,
      };

    case SAVE_SECTION_REQUEST:
      return {
        ...state,
        isSaveSectionLoading: true,
      };

    case SAVE_SECTION_SUCCESS:
      return {
        ...state,
        isSaveSectionLoading: false,
        savedSections: { ...state.savedSections, [action.response.id]: action.response },
      };

    case SAVE_SECTION_FAILURE:
      return {
        ...state,
        isSaveSectionLoading: false,
      };

    case RENAME_SAVED_SECTION_REQUEST:
      return {
        ...state,
        isRenameSavedSectionLoading: true,
      };

    case RENAME_SAVED_SECTION_SUCCESS:
      return {
        ...state,
        isRenameSavedSectionLoading: false,
        savedSections: { ...state.savedSections, [action.response.id]: action.response },
      };

    case RENAME_SAVED_SECTION_FAILURE:
      return {
        ...state,
        isRenameSavedSectionLoading: false,
      };

    case REMOVE_SAVED_SECTION_REQUEST:
      return {
        ...state,
        isRemoveSavedSectionLoading: true,
      };

    case REMOVE_SAVED_SECTION_SUCCESS:
      // eslint-disable-next-line no-unused-vars
      const { [action.meta.savedSectionId]: _, ...remainingSavedSections } = state.savedSections;

      return {
        ...state,
        isRemoveSavedSectionLoading: false,
        savedSections: remainingSavedSections,
      };

    case REMOVE_SAVED_SECTION_FAILURE:
      return {
        ...state,
        isRemoveSavedSectionLoading: false,
      };

    case OPEN_SAVE_SECTION:
      return {
        ...state,
        isSaveSectionModalOpen: true,
        saveSectionProps: action.value,
      };

    case CLOSE_SAVE_SECTION:
      return {
        ...state,
        isSaveSectionModalOpen: false,
        saveSectionProps: null,
      };

    case OPEN_RENAME_SAVED_SECTION:
      return {
        ...state,
        isRenameSavedSectionModalOpen: true,
        renameSavedSectionProps: action.value,
      };

    case CLOSE_RENAME_SAVED_SECTION:
      return {
        ...state,
        isRenameSavedSectionModalOpen: false,
        renameSavedSectionProps: null,
      };

    case OPEN_REMOVE_SAVED_SECTION:
      return {
        ...state,
        isRemoveSavedSectionModalOpen: true,
        removeSavedSectionProps: action.value,
      };

    case CLOSE_REMOVE_SAVED_SECTION:
      return {
        ...state,
        isRemoveSavedSectionModalOpen: false,
        removeSavedSectionProps: null,
      };

    default:
      return state;
  }
};

export default savedSections;
