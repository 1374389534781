import React from 'react';
import PropTypes from 'prop-types';
import { IMAGE_PLACEHOLDER_URL } from '@liswood-tache/browsbox-static';
import i18n from '../../internationalization/i18n';

const BackgroundBehavior = ({ value: currentValue, onChange }) => {
  const options = [['cover', 'CONTENT.cover'], ['tiled', 'CONTENT.tiled'], ['actual_size', 'CONTENT.actualSize']];

  const onValueChange = (evt) => onChange(evt.target.value);

  return (
    <div className="o-bb-section-config__group">
      {options.map((option) => {
        const [optionValue, translationKey] = option;
        const htmlFor = `background-behavior-${optionValue}`;

        return (
          <div key={optionValue} className="o-bb-section-config__radiobutton">
            <input
              className="o-bb-radiobutton__input"
              id={htmlFor}
              type="radio"
              name="background-behavior"
              checked={optionValue === currentValue}
              value={optionValue}
              onChange={onValueChange}
            />
            <label className="o-bb-radiobutton__label o-bb-radiobutton__label--uppercase o-bb-radiobutton__label--bold" htmlFor={htmlFor}>
              {i18n.t(translationKey)}
            </label>
          </div>
        );
      })}
    </div>
  );
};

const BackgroundPosition = ({ value: currentValue, onChange }) => {
  const options = [
    'left_top',
    'center_top',
    'right_top',
    'left_center',
    'center_center',
    'right_center',
    'left_bottom',
    'center_bottom',
    'right_bottom',
  ];

  const onValueChange = (evt) => onChange(evt.target.value);

  return (
    <div className="o-bb-image-position-control">
      {options.map((optionValue) => {
        const htmlFor = `background-position-${optionValue}`;

        return (
          <div key={optionValue} className={`o-bb-image-position-control__input-wrapper o-bb-image-position-control__input-wrapper--${optionValue.replace('_', '-')}`}>
            <input
              className="o-bb-image-position-control__input"
              id={htmlFor}
              type="radio"
              name="background-position"
              checked={optionValue === currentValue}
              value={optionValue}
              onChange={onValueChange}
            />
            <label className="o-bb-image-position-control__label" htmlFor={htmlFor}>{' '}</label>
          </div>
        );
      })}
    </div>
  );
};

const BlockBackgroundImagePicker = ({
  value, onImageClick, onChange,
}) => {
  const handleChange = (option) => (optionValue) => {
    onChange({
      ...value,
      ...{ [option]: optionValue },
    });
  };

  const handleInputChange = (option) => (evt) => handleChange(option)(evt.target.value);
  const handleToggleableChange = (option) => (evt) => handleChange(option)(evt.target.checked);

  return (
    <div>
      <div className="o-bb-popup__background-image">
        {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
        <img src={value.backgroundImage || IMAGE_PLACEHOLDER_URL} alt="Section's background" onClick={onImageClick} />
      </div>
      <div className="o-bb-section-config">
        <BackgroundBehavior
          value={value.backgroundBehavior}
          onChange={handleChange('backgroundBehavior')}
        />
        <div className="o-bb-section-config__group">
          <div className="o-bb-section-config__row">
            <div className="o-bb-section-config__control">
              <label className="" htmlFor="background-transparency">{i18n.t('CONTENT.transparency')}</label>
              <input
                type="number"
                min="0"
                max="100"
                id="background-transparency"
                value={value.backgroundTransparency}
                onChange={handleInputChange('backgroundTransparency')}
              />
            </div>
          </div>
          <div className="o-bb-section-config__row">
            <div className="o-bb-section-config__control">
              <span>{i18n.t('CONTENT.position')}</span>
              <BackgroundPosition
                value={value.backgroundPosition}
                onChange={handleChange('backgroundPosition')}
              />
            </div>
          </div>
          <div className="o-bb-section-config__row">
            <div className="o-bb-section-config__checkbox">
              <input
                className="o-bb-checkbox__input"
                type="checkbox"
                id="background-scrolled"
                checked={value.backgroundScrolling}
                onChange={handleToggleableChange('backgroundScrolling')}
              />
              <label className="o-bb-checkbox__label o-bb-checkbox__label--scroll" htmlFor="background-scrolled">{i18n.t('CONTENT.scrollAlong')}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

BlockBackgroundImagePicker.propTypes = {
  value: PropTypes.shape({
    backgroundImage: PropTypes.string,
    backgroundPosition: PropTypes.string.isRequired,
    backgroundBehavior: PropTypes.string.isRequired,
    backgroundTransparency: PropTypes.number.isRequired,
    backgroundScrolling: PropTypes.bool.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onImageClick: PropTypes.func.isRequired,
};

export default BlockBackgroundImagePicker;
