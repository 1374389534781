import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { moduleOptionsProps } from '@liswood-tache/browsbox-static';
import { useDispatch, useSelector } from 'react-redux';
import Icon from '../../Icon/Icon';
import i18n from '../../../internationalization/i18n';
import BrowsboxContentColorPicker from '../ContentColorPicker';
import BrowsboxContentBackgroundImage from '../ContentBackgroundImage';
import { Gateway } from '../../Gateway';
import { BbPopup } from '../../@browsbox-ui';
import { footerColorSchemeClasses } from '../utils';
import { clearContentOption, setContentOption } from '../../../actions/contentConfig';
import usePopups, { useExclusivePopups } from '../../../hooks/common/use-popups';
import BlockColumnCountPicker from '../BlockColumnCountPicker';
import { setContentColumnCount } from '../../../actions/contentColumns';

function ConfigMenu({
  tetherTarget,
  backgroundImage,
  backgroundPosition,
  backgroundBehavior,
  backgroundTransparency,
  backgroundScrolling,
  backgroundFullWidth,
  contentColor,
  contentColorSchemeClass,
  colors,
  colorSchemeClasses,
  contentId,
  moduleOptions,
  onBackgroundColorChange,
  onBackgroundImageOptionsChange,
  onToggleBackgroundFullWidth,
  onClose,
}) {
  const htmlFor = 'section-settings-config-menu-toggle';

  const mediaPickerActive = typeof moduleOptions.backgroundImage !== 'undefined';
  const colorPickerActive = colors.length !== 0 || Object.keys(colorSchemeClasses).length !== 0;

  const {
    setShownPopup, popups: [
      showColorPicker, setShowColorPicker,
      showBackgroundPicker, setShowBackgroundPicker,
    ],
  } = useExclusivePopups('colorPicker', 'backgroundPicker');

  const handleOnClose = () => {
    setShownPopup();

    requestAnimationFrame(() => {
      onClose();
    });
  };

  return (
    <Gateway into="popup">
      <BbPopup
        target={tetherTarget}
        className="o-bb-popup--config o-bb-popup--section-config"
        onClose={handleOnClose}
        closeOnClickInside={false}
        placement="bottom left"
      >
        <div className="o-bb-section-config">
          <div className="o-bb-section-config__group">
            <div className="o-bb-group__title">
              {i18n.t('CONTENT.background')}
            </div>
            <div className="o-bb-section-config__row">
              {colorPickerActive
                ? (
                  <BrowsboxContentColorPicker
                    className="o-bb-popup--config-action"
                    color={contentColor}
                    colorSchemeClass={contentColorSchemeClass}
                    colorSchemeClasses={footerColorSchemeClasses(colorSchemeClasses)}
                    colors={colors}
                    onChange={onBackgroundColorChange}
                    showPopup={showColorPicker}
                    setShowPopup={setShowColorPicker}
                  >
                    {i18n.t('CONTENT.backgroundColor')}
                  </BrowsboxContentColorPicker>
                ) : null}
            </div>
            <div className="o-bb-section-config__row">
              {mediaPickerActive
                ? (
                  <BrowsboxContentBackgroundImage
                    className="o-bb-popup--config-action"
                    value={{
                      backgroundImage,
                      backgroundPosition,
                      backgroundBehavior,
                      backgroundTransparency,
                      backgroundScrolling,
                    }}
                    contentId={contentId}
                    contentType="section"
                    onChange={onBackgroundImageOptionsChange}
                    showPopup={showBackgroundPicker}
                    setShowPopup={setShowBackgroundPicker}
                  >
                    {i18n.t('CONTENT.backgroundImage')}
                  </BrowsboxContentBackgroundImage>
                ) : null}
            </div>
            <div className="o-bb-section-config__row">
              <div className="o-bb-section-config__checkbox">
                <input
                  className="o-bb-checkbox__input"
                  id={htmlFor}
                  type="checkbox"
                  checked={Boolean(backgroundFullWidth)}
                  onChange={onToggleBackgroundFullWidth}
                />
                <label className="o-bb-checkbox__label" htmlFor={htmlFor}>
                  {i18n.t('CONTENT.fullWidth')}
                </label>
              </div>
            </div>
          </div>
        </div>
      </BbPopup>
    </Gateway>
  );
}

ConfigMenu.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
  backgroundBehavior: PropTypes.string.isRequired,
  backgroundTransparency: PropTypes.number.isRequired,
  backgroundScrolling: PropTypes.bool.isRequired,
  backgroundFullWidth: PropTypes.bool.isRequired,
  contentId: PropTypes.number.isRequired,
  contentColor: PropTypes.string,
  contentColorSchemeClass: PropTypes.string,
  colors: PropTypes.array.isRequired,
  colorSchemeClasses: PropTypes.object.isRequired,
  moduleOptions: moduleOptionsProps.isRequired,
  onBackgroundColorChange: PropTypes.func.isRequired,
  onBackgroundImageOptionsChange: PropTypes.func.isRequired,
  onToggleBackgroundFullWidth: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

ConfigMenu.defaultProps = {
  contentColor: '',
  contentColorSchemeClass: '',
  backgroundImage: null,
};

function ColumnCountConfigMenu({
  tetherTarget, onChange, onClose, currentColumnCount, maxColumnCount,
}) {
  const handleChange = (value) => {
    onChange(value);

    requestAnimationFrame(() => {
      onClose();
    });
  };

  const handleClose = () => {
    requestAnimationFrame(() => {
      onClose();
    });
  };

  return (
    <Gateway into="popup">
      <BbPopup
        placement="bottom left"
        target={tetherTarget}
        onClose={handleClose}
        closeOnClickInside={false}
        className="o-bb-popup--column-count-picker"
      >
        <BlockColumnCountPicker
          currentColumnCount={currentColumnCount}
          maxColumnCount={maxColumnCount}
          onChange={handleChange}
        />
      </BbPopup>
    </Gateway>
  );
}

ColumnCountConfigMenu.propTypes = {
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  tetherTarget: PropTypes.string.isRequired,
  currentColumnCount: PropTypes.number.isRequired,
  maxColumnCount: PropTypes.number.isRequired,
};

function Config({ tetherTarget, onClick }) {
  const handleOnClick = (e) => {
    e.preventDefault();

    onClick((isShown) => !isShown);
  };

  return (
    <div key="config" className="c-bb-section-config__button" id={tetherTarget} onClick={handleOnClick}>
      <a className="o-action-link">
        <Icon name="settings" />
      </a>
    </div>
  );
}

Config.propTypes = {
  tetherTarget: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

function ColumnCountConfig({ tetherTarget, onClick, currentColumnCount }) {
  const handleOnClick = (e) => {
    e.preventDefault();

    onClick((isShown) => !isShown);
  };

  return (
    <div
      key="config"
      className="c-bb-section-config__button c-bb-section-config__button--text"
      id={tetherTarget}
      onClick={handleOnClick}
    >
      <a className="o-action-link">
        #{currentColumnCount}
      </a>
    </div>
  );
}

ColumnCountConfig.propTypes = {
  tetherTarget: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  currentColumnCount: PropTypes.number.isRequired,
};

function FooterConfig({
  backgroundImage,
  backgroundPosition,
  backgroundBehavior,
  backgroundTransparency,
  backgroundScrolling,
  backgroundFullWidth,
  contentId,
  contentType,
  contentColor,
  contentColorSchemeClass,
  currentColumnCount,
  onHidePopup,
  onShowPopup,
}) {
  const {
    popups: [
      showConfig, setShowConfig,
      showColumnCountConfig, setShowColumnCountConfig,
    ],
  } = usePopups('config', 'columnConfig');

  const moduleDef = useSelector((state) => state.entities.modules[contentType]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (showConfig || showColumnCountConfig) {
      requestAnimationFrame(() => {
        onShowPopup();
      });

      return;
    }

    requestAnimationFrame(() => {
      onHidePopup();
    });
  }, [showConfig, showColumnCountConfig, onShowPopup, onHidePopup]);

  const handleBackgroundColorChange = (clear, color, useColorSchemeClass) => {
    if (useColorSchemeClass && color) {
      dispatch(setContentOption({
        option: 'colorSchemeClass',
        value: color,
        contentId,
      }));
    } else if (useColorSchemeClass && clear === true) {
      dispatch(clearContentOption({
        option: 'colorSchemeClass',
        contentId,
      }));
    } else if (!useColorSchemeClass && clear === true) {
      dispatch(clearContentOption({
        option: 'backgroundColor',
        contentId,
      }));
    } else if (!useColorSchemeClass && color) {
      dispatch(setContentOption({
        option: 'backgroundColor',
        value: color,
        contentId,
      }));
    }
  };

  const handleBackgroundImageOptionsChange = (options) => {
    Object.entries(options)
      .forEach(([option, value]) => dispatch(setContentOption({
        option,
        value,
        contentId,
      })));
  };

  const handleToggleBackgroundFullWidth = () => {
    const value = !backgroundFullWidth;

    dispatch(setContentOption({
      option: 'backgroundFullWidth',
      value,
      contentId,
    }));
  };

  const handleColumnCountChange = (value) => {
    dispatch(setContentColumnCount({
      value,
      contentId,
      contentType,
    }));
  };

  const configTetherTarget = `footer-settings-${contentId}-config`;
  const columnCountConfigTetherTarget = `footer-settings-${contentId}-column-count-config`;

  return (
    <>
      <div className="c-bb-section-config__left">
        <Config tetherTarget={configTetherTarget} onClick={setShowConfig} />

        <ColumnCountConfig
          tetherTarget={columnCountConfigTetherTarget}
          onClick={setShowColumnCountConfig}
          currentColumnCount={currentColumnCount}
        />
      </div>

      {showConfig && (
        <ConfigMenu
          tetherTarget={configTetherTarget}
          backgroundImage={backgroundImage}
          backgroundPosition={backgroundPosition}
          backgroundBehavior={backgroundBehavior}
          backgroundTransparency={backgroundTransparency}
          backgroundScrolling={backgroundScrolling}
          backgroundFullWidth={backgroundFullWidth}
          contentId={contentId}
          contentColor={contentColor}
          contentColorSchemeClass={contentColorSchemeClass}
          colors={moduleDef.colorPicker || []}
          colorSchemeClasses={moduleDef.colorSchemeClasses || {}}
          moduleOptions={moduleDef.options || {}}
          onBackgroundColorChange={handleBackgroundColorChange}
          onBackgroundImageOptionsChange={handleBackgroundImageOptionsChange}
          onToggleBackgroundFullWidth={handleToggleBackgroundFullWidth}
          onClose={setShowConfig}
        />
      )}

      {showColumnCountConfig && (
        <ColumnCountConfigMenu
          tetherTarget={columnCountConfigTetherTarget}
          onChange={handleColumnCountChange}
          onClose={setShowColumnCountConfig}
          currentColumnCount={currentColumnCount}
          maxColumnCount={moduleDef.options.maxColumnCount}
        />
      )}
    </>
  );
}

FooterConfig.propTypes = {
  backgroundImage: PropTypes.string,
  backgroundPosition: PropTypes.string.isRequired,
  backgroundBehavior: PropTypes.string.isRequired,
  backgroundTransparency: PropTypes.number.isRequired,
  backgroundScrolling: PropTypes.bool.isRequired,
  backgroundFullWidth: PropTypes.bool.isRequired,
  contentId: PropTypes.number.isRequired,
  contentType: PropTypes.string.isRequired,
  contentColor: PropTypes.string,
  contentColorSchemeClass: PropTypes.string,
  currentColumnCount: PropTypes.number.isRequired,
  onHidePopup: PropTypes.func.isRequired,
  onShowPopup: PropTypes.func.isRequired,
};

FooterConfig.defaultProps = {
  contentColor: '',
  contentColorSchemeClass: '',
  backgroundImage: null,
};

export default FooterConfig;
