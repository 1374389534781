import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { useDrop } from 'react-dnd';
import classNames from 'classnames';
import { contentColumnPropType, contentComponentPropType, contentPropType } from '@liswood-tache/browsbox-static';
import { DND_BUILDER_COMPONENT } from '../DragAndDrop/dndTypes';
import { moveComponentToColumn } from '../../actions/contentAdd';
import useDragDropCollectedProps from '../../hooks/dnd/use-drag-drop-collected-props';
import i18n from '../../internationalization/i18n';

export default function ContentComponentDropArea({
  content,
  column,
  component,
}) {
  const dispatch = useDispatch();
  const precedingComponent = useMemo(
    () => {
      if (!component) {
        return column.components[column.components.length - 1];
      }

      const componentIndex = column.components.findIndex(c => c.id === component.id);

      return column.components[Math.max(0, componentIndex - 1)];
    },
    [column, component],
  );

  const [{ isOver, draggedComponentId }, drop] = useDrop(() => ({
    accept: DND_BUILDER_COMPONENT,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      draggedComponentId: monitor.getItem()?.componentId,
    }),
    drop: (item) => {
      dispatch(moveComponentToColumn({
        contentId: content.id,
        sourceColumnId: item.columnId,
        targetColumnId: column.id,
        sourceComponentId: item.componentId,
        targetComponentId: component?.id,
      }));
    },
  }), [column, component]);

  const { isDragging } = useDragDropCollectedProps((monitor) => ({
    isDragging: monitor.isDragging() && monitor.getItemType() === DND_BUILDER_COMPONENT,
  }));

  if ([precedingComponent?.id, component?.id].includes(draggedComponentId)) {
    return null;
  }

  if (!isDragging) {
    return null;
  }

  return (
    <div
      className={classNames([
        'c-component-drop-area',
        { 'is-over': isOver },
      ])}
      ref={drop}
    >
      <div className="c-component-drop-area__placeholder">
        {i18n.t('CONTENT.dropHere')}
      </div>
    </div>
  );
}

ContentComponentDropArea.propTypes = {
  content: contentPropType.isRequired,
  column: contentColumnPropType.isRequired,
  component: contentComponentPropType,
};

ContentComponentDropArea.defaultProps = {
  component: null,
};
