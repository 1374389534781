export const CONTENT_TYPE = 'content';
export const FOOTER_TYPE = 'footer';

export function selectSectionsType(entitiesState, type) {
  return Object.entries(entitiesState.modules)
    .filter(([, { acceptedContainers }]) => acceptedContainers.includes(type))
    .map(([blockType]) => blockType);
}

export function filterSectionsByType(entitiesState, sectionType) {
  const { content } = entitiesState;
  const types = selectSectionsType(entitiesState, sectionType);

  return content.filter(({ type }) => types.includes(type));
}

export function selectContentSections(entitiesState) {
  return filterSectionsByType(entitiesState, CONTENT_TYPE);
}

export function selectFooterSections(entitiesState) {
  return filterSectionsByType(entitiesState, FOOTER_TYPE);
}
