import { schema as Schema } from 'normalizr';

// Use array index instead of 'id' for an api response
// consisting of an array of objects without an 'id' in the object.
// This keeps the order of the objects within the array.
const idOrIndex = (item, parent) => {
  if (typeof item.id === 'undefined') {
    return parent.indexOf(item);
  }
  return item.id;
};

const moduleSchema = new Schema.Entity('modules', {}, {
  idAttribute: module => module.type,
});

const mediaSchema = new Schema.Entity('media', {}, {
  idAttribute: media => media.id,
});

const mediaFolderSchema = new Schema.Entity('mediaFolder', {}, {
  idAttribute: folder => folder.id,
});

const linksSchema = new Schema.Entity('links', {}, {
  // idAttribute: idOrIndex,
  idAttribute: link => link.path,
});

const pageSchema = new Schema.Entity('pages');
pageSchema.define({
  children: [pageSchema],
});

const pageTypeSchema = new Schema.Entity('pageTypes', {}, {
  idAttribute: idOrIndex,
});

const pagePropsSchema = new Schema.Entity('pageProps');

const contentSchema = new Schema.Entity('content', {});

const componentSchema = new Schema.Entity('components', {}, {
  idAttribute: component => component.type,
});

const userSchema = new Schema.Entity('users');

const savedSectionsSchema = new Schema.Entity('savedSections');

const footerSchema = new Schema.Entity('footer');

export const CONTENT_SCHEMA = contentSchema;
export const MODULE_ARRAY_SCHEMA = [moduleSchema];
export const MODULE_SCHEMA = moduleSchema;
export const PAGE_ARRAY_SCHEMA = [pageSchema];
export const PAGE_PROPS_SCHEMA = pagePropsSchema;
export const PAGE_SCHEMA = pageSchema;
export const PAGE_TYPES_ARRAY_SCHEMA = [pageTypeSchema];
export const SAVED_SECTIONS_SCHEMA = savedSectionsSchema;
export const SAVED_SECTIONS_ARRAY_SCHEMA = [savedSectionsSchema];
export const FOOTER_SCHEMA = footerSchema;
export const MEDIA_ITEM_SCHEMA = mediaSchema;
export const MEDIA_SCHEMA = [mediaSchema];
export const MEDIA_FOLDER_SCHEMA = [mediaFolderSchema];
export const LINKS_SCHEMA = [linksSchema];
export const COMPONENTS_ARRAY_SCHEMA = [componentSchema];
export const USER_SCHEMA = userSchema;
export const USER_ARRAY_SCHEMA = [userSchema];
export const USER_PAGINATION_SCHEMA = {
  data: USER_ARRAY_SCHEMA,
};
