import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon/Icon';

export default function GeneralSettingHeader({ title, onClose }) {
  const handleOnClick = () => onClose();

  return (
    <header className="c-bb-settings-page__header">
      <h1 className="c-bb-settings-page__title">{title}</h1>
      <button type="button" className="c-bb-settings-page__close-button" onClick={handleOnClick}>
        <Icon name="close" />
      </button>
    </header>
  );
}

GeneralSettingHeader.propTypes = {
  title: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};
