import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { BbPopup } from '@browsbox-ui';
import BlockPicker from './BlockColorPicker';
import { Gateway } from '../Gateway';

const clearColor = 'transparent'; // equals "select none"

const propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  colors: PropTypes.arrayOf(PropTypes.string).isRequired,
  color: PropTypes.string,
  colorSchemeClass: PropTypes.string,
  colorSchemeClasses: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  showPopup: PropTypes.bool.isRequired,
  setShowPopup: PropTypes.func.isRequired,
};

const defaultProps = {
  className: '',
  children: null,
  color: clearColor,
  colorSchemeClass: '',
};

const BrowsboxContentColorPicker = (props) => {
  const {
    className,
    children,
    colors,
    color,
    colorSchemeClass,
    colorSchemeClasses,
    onChange,
    showPopup,
    setShowPopup,
  } = props;

  const colorSchemeClassesNames = Object.keys(colorSchemeClasses)
    .map(k => colorSchemeClasses[k]);
  const useColorSchemeClass = colorSchemeClassesNames.length !== 0;
  const allColors = useColorSchemeClass
    ? colorSchemeClassesNames.concat(clearColor)
    : colors.concat(clearColor);
  const defaultColorValue = color === null ? defaultProps.color : color;

  const [colorState, setColorState] = useState(useColorSchemeClass
    ? colorSchemeClasses[colorSchemeClass] || defaultProps.color
    : defaultColorValue);

  useEffect(() => {
    if (color !== null && color !== '' && color !== colorState) {
      setColorState(color);
    }
  }, [color]);

  useEffect(() => {
    if (useColorSchemeClass) {
      setColorState(colorSchemeClasses[colorSchemeClass] || defaultProps.color);
    }
  }, [useColorSchemeClass, colorSchemeClasses, colorSchemeClass]);

  const onClose = () => {
    setShowPopup(false);
  };

  const onClick = (evt) => {
    evt.preventDefault();

    setShowPopup(!showPopup);
  };

  const onChangeColor = (colorValue) => {
    const colorHex = colorValue.hex.toLowerCase();

    setColorState(colorHex);

    if (useColorSchemeClass) {
      if (colorHex === clearColor) {
        onChange(true, null, useColorSchemeClass);
      } else {
        const colorSchemeClassName = Object.keys(colorSchemeClasses)
          .filter(k => colorSchemeClasses[k].toLowerCase() === colorHex).pop();
        onChange(false, colorSchemeClassName, useColorSchemeClass);
      }
    } else {
      onChange(colorHex === clearColor, colorHex, useColorSchemeClass);
    }
  };

  const renderColorPicker = (tetherTarget) => {
    if (showPopup) {
      return (
        <Gateway into="popup">
          <BbPopup
            placement="right"
            target={tetherTarget}
            onClose={() => onClose()}
            closeOnClickInside={false}
            className="o-bb-popup--config o-bb-popup--colorpicker"
          >
            <BlockPicker
              triangle="hide"
              colors={allColors}
              color={colorState}
              onChange={(colorValue) => onChangeColor(colorValue)}
            />
          </BbPopup>
        </Gateway>
      );
    }
    return null;
  };

  return (
    <a href="#" onClick={onClick} className={className} id="content-color-picker">
      <span className="bb-icon bb-icon--color" style={{ backgroundColor: colorState }} />
      {children}
      {renderColorPicker('content-color-picker')}
    </a>
  );
};

BrowsboxContentColorPicker.propTypes = propTypes;
BrowsboxContentColorPicker.defaultProps = defaultProps;

export default BrowsboxContentColorPicker;
