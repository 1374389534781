import { useEffect, useRef } from 'react';

export default function usePreviousValue(value) {
  const previousValue = useRef();

  useEffect(() => {
    if (previousValue.current !== value) {
      previousValue.current = value;
    }
  }, [value]);

  return previousValue.current;
}
