import moment from 'moment';
import get from 'lodash/get';
import set from 'lodash/set';
import { isValidNumber } from 'libphonenumber-js';
import i18n from '../internationalization/i18n';

const trans = (id, params = {}) => (i18n.exists(`VALIDATION.${id}`, params)
  ? i18n.t(`VALIDATION.${id}`, params)
  : id);

export const isValidationError = error => get(error, 'error.response.data.code', 500) === 400;

export const transformValidationErrors = (error) => {
  const validationErrorsRaw = get(error, 'error.response.data.errors.children', {});
  const errors = {};

  const flattenErrors = (validationErrors, parentKey = '') => {
    Object.keys(validationErrors).forEach((key) => {
      const path = [parentKey, key]
        .filter(Boolean)
        .join('.');

      const field = validationErrors[key];

      if (Array.isArray(field.errors) && field.errors.length > 0) {
        set(errors, path, field.errors[0]);

        return;
      }

      if (Array.isArray(field.children && field.children.length > 0)) {
        flattenErrors(field.children, path);
      }

      if (field.children && Object.entries(field.children).length > 0) {
        flattenErrors(field.children, path);
      }

      if (Array.isArray(field)) {
        field.forEach((subField) => flattenErrors(subField.children, path));
      }
    });
  };

  flattenErrors(validationErrorsRaw);

  return errors;
};

export const renderError = (error) => {
  if (!error) {
    return null;
  }

  if (typeof error === 'string') {
    return trans(error);
  }

  if (Array.isArray(error)) {
    const [message, params = {}] = error;

    return trans(message, params);
  }

  return null;
};

export default {
  required(value) {
    const trimmed = String(value).trim();
    return value && (trimmed.length > 0)
      ? undefined
      : 'required';
  },

  email(value) {
    if (!value) {
      return undefined;
    }

    const emailRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/i;

    return (!emailRegex.test(value))
      ? 'email'
      : undefined;
  },

  maxLength(max) {
    return function maxLength(value) {
      return value && value.length > max
        ? ['maxLength', { max }]
        : undefined;
    };
  },

  minLength(min) {
    return function minLength(value) {
      return value && value.length < min
        ? ['minLength', { min }]
        : undefined;
    };
  },

  minNumberOptions(min, name) {
    return function minLength(value) {
      return value && value.length < min
        ? ['minNumberOptions', { min, name }]
        : undefined;
    };
  },

  number(value) {
    return value && Number.isNaN(Number(value))
      ? 'number'
      : undefined;
  },

  photoNumberLength(value) {
    const size = 9;

    return value && value.length > size
      ? ['photoNumberLength', { size }]
      : undefined;
  },

  alphaNumeric(value) {
    return value && /[^a-zA-Z0-9 ]/i.test(value)
      ? 'alphaNumeric'
      : undefined;
  },

  alphabetic(value) {
    return value && /[^a-zA-Z ]/i.test(value)
      ? 'alphabetic'
      : undefined;
  },

  dateFormat(format) {
    return function dateFormat(value) {
      return value && !moment(value, format, 'en', true).isValid()
        ? ['dateFormat', { format }]
        : undefined;
    };
  },

  url(value) {
    return value && !/(https?:\/\/)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#()?&/=]*)/i.test(value)
      ? 'url'
      : undefined;
  },

  phoneNumber(value) {
    return value && !isValidNumber(value)
      ? 'phoneNumber'
      : undefined;
  },

  sameAs(name) {
    return (value, values = {}) => (value && values[name] && value !== values[name]
      ? 'valuesNotMatch'
      : undefined
    );
  },
};
