import { buildRoute, CALL_BB_API } from '../api';
import { ROUTE_SAVED_SECTION, ROUTE_SAVED_SECTIONS } from '../api/routes';
import { SAVED_SECTIONS_ARRAY_SCHEMA, SAVED_SECTIONS_SCHEMA } from '../api/schema';

export const SAVED_SECTIONS_REQUEST = 'SAVED_SECTIONS_REQUEST';
export const SAVED_SECTIONS_SUCCESS = 'SAVED_SECTIONS_SUCCESS';
export const SAVED_SECTIONS_FAILURE = 'SAVED_SECTIONS_FAILURE';

const fetchSavedSections = () => {
  const endpoint = buildRoute(ROUTE_SAVED_SECTIONS);
  return {
    [CALL_BB_API]: {
      types: [SAVED_SECTIONS_REQUEST, SAVED_SECTIONS_SUCCESS, SAVED_SECTIONS_FAILURE],
      endpoint,
      method: 'GET',
      schema: SAVED_SECTIONS_ARRAY_SCHEMA,
    },
  };
};

export const loadSavedSections = () => dispatch => dispatch(fetchSavedSections());

export default loadSavedSections;

export const OPEN_SAVE_SECTION = 'OPEN_SAVE_SECTION';
export const CLOSE_SAVE_SECTION = 'CLOSE_SAVE_SECTION';

export const openSaveSection = value => ({ type: OPEN_SAVE_SECTION, value });
export const closeSaveSection = () => ({ type: CLOSE_SAVE_SECTION });

export const SAVE_SECTION_REQUEST = 'SAVE_SECTION_REQUEST';
export const SAVE_SECTION_SUCCESS = 'SAVE_SECTION_SUCCESS';
export const SAVE_SECTION_FAILURE = 'SAVE_SECTION_FAILURE';

const postSaveSection = (value) => {
  const endpoint = buildRoute(ROUTE_SAVED_SECTIONS);
  return {
    [CALL_BB_API]: {
      types: [SAVE_SECTION_REQUEST, SAVE_SECTION_SUCCESS, SAVE_SECTION_FAILURE],
      endpoint,
      method: 'POST',
      json: value,
      schema: SAVED_SECTIONS_SCHEMA,
    },
  };
};

export const saveSection = value => dispatch => dispatch(postSaveSection(value));

export const OPEN_RENAME_SAVED_SECTION = 'OPEN_RENAME_SAVED_SECTION';
export const CLOSE_RENAME_SAVED_SECTION = 'CLOSE_RENAME_SAVED_SECTION';

export const openRenameSavedSection = value => ({ type: OPEN_RENAME_SAVED_SECTION, value });
export const closeRenameSavedSection = () => ({ type: CLOSE_RENAME_SAVED_SECTION });

export const RENAME_SAVED_SECTION_REQUEST = 'RENAME_SAVED_SECTION_REQUEST';
export const RENAME_SAVED_SECTION_SUCCESS = 'RENAME_SAVED_SECTION_SUCCESS';
export const RENAME_SAVED_SECTION_FAILURE = 'RENAME_SAVED_SECTION_FAILURE';

const putSavedSection = (value, savedSectionId) => {
  const endpoint = buildRoute(ROUTE_SAVED_SECTION, { savedSectionId });
  return {
    [CALL_BB_API]: {
      types: [RENAME_SAVED_SECTION_REQUEST, RENAME_SAVED_SECTION_SUCCESS, RENAME_SAVED_SECTION_FAILURE],
      endpoint,
      method: 'PUT',
      json: value,
      schema: SAVED_SECTIONS_SCHEMA,
    },
  };
};

export const renameSavedSection = (value, id) => dispatch => dispatch(putSavedSection(value, id));

export const OPEN_REMOVE_SAVED_SECTION = 'OPEN_REMOVE_SAVED_SECTION';
export const CLOSE_REMOVE_SAVED_SECTION = 'CLOSE_REMOVE_SAVED_SECTION';

export const openRemoveSavedSection = value => ({ type: OPEN_REMOVE_SAVED_SECTION, value });
export const closeRemoveSavedSection = () => ({ type: CLOSE_REMOVE_SAVED_SECTION });

export const REMOVE_SAVED_SECTION_REQUEST = 'REMOVE_SAVED_SECTION_REQUEST';
export const REMOVE_SAVED_SECTION_SUCCESS = 'REMOVE_SAVED_SECTION_SUCCESS';
export const REMOVE_SAVED_SECTION_FAILURE = 'REMOVE_SAVED_SECTION_FAILURE';

const deleteSavedSection = (savedSectionId) => {
  const endpoint = buildRoute(ROUTE_SAVED_SECTION, { savedSectionId });
  return {
    [CALL_BB_API]: {
      types: [REMOVE_SAVED_SECTION_REQUEST, REMOVE_SAVED_SECTION_SUCCESS, REMOVE_SAVED_SECTION_FAILURE],
      endpoint,
      method: 'DELETE',
      meta: { savedSectionId },
    },
  };
};

export const removeSavedSection = id => dispatch => dispatch(deleteSavedSection(id));
