/* eslint-disable react/jsx-fragments */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import get from 'lodash/get';
import { contentPropType } from './ContentPropTypes';
import ContentSectionBase from './ContentSections/ContentSectionBase';
import { moduleDefaultOptionsProps } from './ModulePropTypes';
import BrowsboxContentColumn from './ContentColumn';
import ContentComponents from './ContentComponents';
import CONTENT_COMPONENT_TYPE from './ContentComponents/types';
import { BUTTON_MODE } from './constants';
import { containerClass } from './utils/content';
import ContentItemBackground from './ContentItemBackground';

class BrowsboxContentItem extends Component {
  constructor(props) {
    super(props);
    this.renderColumn = this.renderColumn.bind(this);
  }

  renderTitle() {
    const { content: { options, id, type } } = this.props;
    const { title: content } = options;
    if (content) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.title, content, id, fromSectionOption: 'title',
      };
      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.title);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          {...component}
        />
      );
    }
    return null;
  }

  renderSubTitle() {
    const { content: { options, id, type } } = this.props;
    const { subtitle: content } = options;
    if (content) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.subtitle, content, id, fromSectionOption: 'subtitle',
      };
      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.subtitle);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          {...component}
        />
      );
    }
    return null;
  }

  renderButtons() {
    const {
      content: {
        options: {
          buttonDisplay,
        },
      },
    } = this.props;

    if (typeof buttonDisplay !== 'undefined' && buttonDisplay !== BUTTON_MODE.BUTTON_PER_LAYOUT) {
      return null;
    }

    return (
      <React.Fragment>
        {this.renderPrimaryButton()}
        {this.renderSecondaryButton()}
      </React.Fragment>
    );
  }

  renderPrimaryButton() {
    const { content: { options: { primaryButtonsActive } } } = this.props;

    if (primaryButtonsActive) {
      return this.renderButtonFromSectionOption('buttonContent');
    }

    return null;
  }

  renderSecondaryButton() {
    const { content: { options: { secondaryButtonsActive } } } = this.props;

    if (secondaryButtonsActive) {
      return this.renderButtonFromSectionOption('buttonSecondaryContent');
    }

    return null;
  }

  renderButtonFromSectionOption(name) {
    const { content: { options, id, type } } = this.props;
    const buttonContent = options[name];

    if (buttonContent) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.button,
        content: buttonContent,
        id,
        fromSectionOption: name,
      };

      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.button);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          {...component}
        />
      );
    }

    return null;
  }

  renderText() {
    const { content: { options, id, type } } = this.props;
    const {
      text: content,
    } = options;
    if (content) {
      const component = {
        type: CONTENT_COMPONENT_TYPE.text, content, id, fromSectionOption: 'text',
      };
      const containerClassName = containerClass(type, CONTENT_COMPONENT_TYPE.text);
      const Tag = ContentComponents[component.type];
      return (
        <Tag
          onChange={this.onComponentContentChange}
          containerClassName={containerClassName}
          {...component}
        />
      );
    }
    return null;
  }

  renderColumn(column, idx) {
    const { content } = this.props;

    if (column.visible === false) {
      return null;
    }

    const { options } = column;

    const classes = classNames(
      options.colorSchemeClass,
      { [`l-column--${options.size}`]: typeof options.size !== 'undefined' },
      { [`l-column--v-align-${options.verticalAlignment}`]: options.verticalAlignment },
      { [`l-column--h-align-${options.horizontalAlignment}`]: options.horizontalAlignment },
    );

    const isItemBackgroundVisible = options.backgroundImage || options.colorSchemeClass;

    return (
      <BrowsboxContentColumn
        className={classes}
        key={idx}
        column={column}
        content={content}
        components={column.components}
        onChange={this.onComponentContentChange}
      >
        {isItemBackgroundVisible && (
          <div className="l-column__background">
            <ContentItemBackground
              position={options.backgroundPosition}
              behavior={options.backgroundBehavior}
              transparency={Number(options.backgroundTransparency)}
              scrolling={Boolean(options.backgroundScrolling)}
              image={options.backgroundImage}
            />
          </div>
        )}
      </BrowsboxContentColumn>
    );
  }

  renderSection() {
    const {
      content,
      content: { options },
    } = this.props;

    const marginsClasses = get(options, 'marginsClasses', '');

    const classes = classNames(
      'c-section',
      `c-section-${content.type}`,
      content.classes,
      options.colorSchemeClass,
      marginsClasses,
      options.layoutClasses,
      { [`c-section--button-align-${options.buttonAlign}`]: Boolean(options.buttonAlign) },
    );

    const styles = {
      backgroundColor: content.options?.backgroundColor,
    };

    return (
      <section
        style={styles}
        className={classes}
        id={content.id}
      >
        <div className={classNames('c-section__background', { 'c-section__background--boxed': !options.backgroundFullWidth })}>
          <ContentItemBackground
            position={options.backgroundPosition}
            behavior={options.backgroundBehavior}
            transparency={Number(options.backgroundTransparency)}
            scrolling={Boolean(options.backgroundScrolling)}
            image={content.options?.backgroundImage}
          />
        </div>

        <ContentSectionBase
          className="c-section__wrapper"
          content={content}
          title={() => this.renderTitle()}
          subTitle={() => this.renderSubTitle()}
          text={() => this.renderText()}
          button={() => this.renderButtons()}
          column={this.renderColumn}
        />
      </section>
    );
  }

  render() {
    return this.renderSection();
  }
}

BrowsboxContentItem.propTypes = {
  content: contentPropType.isRequired,
  id: PropTypes.number.isRequired, // eslint-disable-line react/no-unused-prop-types
};

BrowsboxContentItem.defaultProps = {
  moduleOptions: moduleDefaultOptionsProps,
};

export default BrowsboxContentItem;
