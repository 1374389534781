import { CALL_BB_API } from '../api';
import { ROUTE_PAGE_HEADER_HTML, ROUTE_PAGE_FOOTER_HTML } from '../api/routes';

export const PAGE_HEADER_HTML_REQUEST = 'PAGE_HEADER_HTML_REQUEST';
export const PAGE_HEADER_HTML_SUCCESS = 'PAGE_HEADER_HTML_SUCCESS';
export const PAGE_HEADER_HTML_FAILURE = 'PAGE_HEADER_HTML_FAILURE';

// BrowsBox API middleware.
const getPageHeaderHtmlCall = () => ({
  [CALL_BB_API]: {
    types: [PAGE_HEADER_HTML_REQUEST, PAGE_HEADER_HTML_SUCCESS, PAGE_HEADER_HTML_FAILURE],
    endpoint: ROUTE_PAGE_HEADER_HTML,
    method: 'GET',
  },
});

export const PAGE_FOOTER_HTML_REQUEST = 'PAGE_FOOTER_HTML_REQUEST';
export const PAGE_FOOTER_HTML_SUCCESS = 'PAGE_FOOTER_HTML_SUCCESS';
export const PAGE_FOOTER_HTML_FAILURE = 'PAGE_FOOTER_HTML_FAILURE';

const getPageFooterCall = () => ({
  [CALL_BB_API]: {
    types: [PAGE_FOOTER_HTML_REQUEST, PAGE_FOOTER_HTML_SUCCESS, PAGE_FOOTER_HTML_FAILURE],
    endpoint: ROUTE_PAGE_FOOTER_HTML,
    method: 'GET',
  },
});

// Redux Thunk middleware.
export const getPageHeaderHtml = () => dispatch => dispatch(getPageHeaderHtmlCall());
export const getPageFooterHtml = () => dispatch => dispatch(getPageFooterCall());
