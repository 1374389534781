/* global mapboxgl */
/* eslint-disable react/no-danger */
import React, {
  useContext, useEffect, useRef, useState,
} from 'react';
import classNames from 'classnames';
import { ContentComponentSizeListenerContext } from '@liswood-tache/browsbox-static';
import { htmlDecode } from 'htmlencode';
import PropTypes from 'prop-types';
import MapMarker from './MapMarker';
import MapBoxLoader from './MapBoxLoader';

const useRefSize = (ref) => {
  const [rect, setRect] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      const { inlineSize: width, blockSize: height } = entries[0].borderBoxSize[0];

      setRect({ width, height });
    });

    observer.observe(ref.current);

    return () => {
      observer.disconnect();
    };
  }, []);

  return rect;
};

const SingleLocationMap = ({
  classes,
  containerClassName,
  latitude,
  longitude,
  mapStyle,
  mapZoom,
  markerTitleEncoded,
  markerValue,
  type,
}) => {
  const onBoundingRectChange = useContext(ContentComponentSizeListenerContext);
  const containerRef = useRef();
  const containerRect = useRefSize(containerRef);
  const mapRef = useRef();
  const [ready, setReady] = useState(false);

  const componentClasses = classNames(
    classes,
    containerClassName,
    `c-${type}`,
    `c-${type}--${mapStyle}`,
  );
  const markerTitle = htmlDecode(markerTitleEncoded);

  const styleSet = mapStyle === 'grayscale'
    ? 'mapbox://styles/mapbox/light-v10'
    : 'mapbox://styles/mapbox/streets-v11';

  useEffect(() => {
    onBoundingRectChange(containerRect);
    mapRef.current?.resize();
  }, [containerRect]);

  useEffect(() => {
    const params = {
      longitude,
      latitude,
      mapZoom: mapZoom || 15,
    };

    Object
      .keys(params)
      .every(item => {
        if (typeof params[item] === 'undefined') {
          // eslint-disable-next-line no-console
          console.warn(`Required attribute is empty: ${item}.`);
        }

        return item;
      });

    mapRef.current = new mapboxgl.Map({
      container: containerRef.current,
      style: styleSet,
      center: [params.longitude, params.latitude],
      zoom: params.mapZoom,
      cooperativeGestures: true,
    });
    setReady(true);

    return () => {
      mapRef.current.remove();
    };
  }, []);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.setStyle(styleSet);
    }
  }, [styleSet]);

  useEffect(() => {
    if (mapRef.current) {
      mapRef.current.flyTo({
        center: [
          longitude,
          latitude,
        ],
        essential: true,
      });
    }
  }, [longitude, latitude]);

  return (
    <div ref={containerRef} className={componentClasses}>
      {ready && markerValue && (
        <MapMarker
          mapRef={mapRef}
          latitude={latitude}
          longitude={longitude}
          value={markerValue}
        >
          <div className="c-mapbox-marker">
            {markerTitle && <div className="c-mapbox-marker__content" dangerouslySetInnerHTML={{ __html: markerTitle }} />}
            <svg className="c-mapbox-marker__icon" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <path d="M12 0c-4.198 0-8 3.403-8 7.602 0 4.198 3.469 9.21 8 16.398 4.531-7.188 8-12.2 8-16.398 0-4.199-3.801-7.602-8-7.602zm0 11c-1.657 0-3-1.343-3-3s1.343-3 3-3 3 1.343 3 3-1.343 3-3 3z" />
            </svg>
          </div>
        </MapMarker>
      )}
    </div>
  );
};

SingleLocationMap.propTypes = {
  latitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  longitude: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  markerTitleEncoded: PropTypes.string.isRequired,
  mapZoom: PropTypes.oneOfType([
    PropTypes.number.isRequired,
    PropTypes.string.isRequired,
  ]).isRequired,
  mapStyle: PropTypes.string,
  classes: PropTypes.string,
  containerClassName: PropTypes.string.isRequired,
  markerValue: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
};

SingleLocationMap.defaultProps = {
  mapStyle: '',
  classes: '',
};

export default (props => (
  <MapBoxLoader>
    <SingleLocationMap {...props} />
  </MapBoxLoader>
));
