import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { deleteComponent } from '../../../actions/contentDelete';
import { openGoogleMapsEditor } from '../../../actions/googleMaps';
import ContentItemContext from '../ContentItemContext';
import SuperAdminOnly from '../../AcessControll/SuperAdminOnly';
import ContentComponentMoveHandle from '../ContentComponentMoveHandle';
import { openGeneralSettingsView } from '../../../actions/generalSettings';
import ContentComponentConfig from '../ContentComponentConfig';

const ContentComponentToolbar = ({
  componentId,
  componentType,
  columnId,
}) => {
  const { content } = useContext(ContentItemContext);

  return (
    <div className="c-component-tools__toolbar">
      <ContentComponentConfig
        componentId={componentId}
        componentType={componentType}
        columnId={columnId}
        contentId={content.id}
      />

      <SuperAdminOnly>
        <ContentComponentMoveHandle componentId={componentId} columnId={columnId} />
      </SuperAdminOnly>
    </div>
  );
};

ContentComponentToolbar.propTypes = {
  componentId: PropTypes.number.isRequired,
  componentType: PropTypes.string.isRequired,
  columnId: PropTypes.number.isRequired,
};

const mapDispatchToProps = {
  removeComponent: deleteComponent,
  openGoogleMaps: openGoogleMapsEditor,
  openGeneralSettings: openGeneralSettingsView,
};

export default connect(null, mapDispatchToProps)(ContentComponentToolbar);
