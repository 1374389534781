/* eslint-disable react/jsx-no-constructed-context-values, camelcase */
import React, { Component } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import update from 'immutability-helper';
import { Transition, TransitionGroup } from 'react-transition-group';
import { contentPropType, contentDefaultProps } from '@liswood-tache/browsbox-static';
import BrowsboxContentItem from './ContentItem';
import BrowsboxContentEmpty from './ContentEmpty';
import { DND_CONTENT_MODULE } from '../DragAndDrop/dndTypes';
import { moveContent } from '../../actions/contentMove';
import { loadPageHtmlContent } from '../../actions/content';
import Loader from '../Loader';
import { isStaticPage, getInitialPageContents } from '../../tools/page-utils';
import AnimatedItemContext from './AnimatedItemContext';
import ExternalHtmlContainer from './ExternalHtmlContainer';

class BrowsboxContentList extends Component {
  static propTypes = {
    content: PropTypes.arrayOf(contentPropType).isRequired,
    isEmpty: PropTypes.bool,
    isSuperAdmin: PropTypes.bool,
    isContentLoading: PropTypes.bool.isRequired,
    isModulesLoaded: PropTypes.bool.isRequired,
    moveContent: PropTypes.func.isRequired,
    renderTarget: PropTypes.string.isRequired, // if target is 'preview' or 'html' do not add component edit behavior
    currentPage: PropTypes.object, // eslint-disable-line react/forbid-prop-types
    loadPageHtmlContent: PropTypes.func.isRequired,
    pageHtmlContent: PropTypes.string,
  };

  static defaultProps = {
    isSuperAdmin: false,
    isEmpty: false,
    currentPage: null,
    pageHtmlContent: '',
  };

  constructor(props) {
    super(props);
    this.renderItem = this.renderItem.bind(this);
    this.moveItem = this.moveItem.bind(this);
    this.addItem = this.addItem.bind(this);
    this.onBeginDrag = this.onBeginDrag.bind(this);
    this.onEndDrag = this.onEndDrag.bind(this);
    this.state = {
      contentItems: props.content,
      isExternalHtmlLoaded: false,
      isDragging: null,
    };
  }

  componentDidMount() {
    this.loadExternalPageHtmlIfNeeded();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.content.length) {
      this.setState({
        contentItems: nextProps.content.map(c => ({ ...c })),
      });
    } else {
      this.setState({
        contentItems: nextProps.content,
      });
    }
  }

  componentDidUpdate() {
    this.loadExternalPageHtmlIfNeeded();
  }

  onResetContentItems = () => {
    if (this.props.content.length) {
      this.setState({
        contentItems: this.props.content.map(c => ({ ...c })),
      });
    } else {
      this.setState({
        contentItems: this.props.content,
      });
    }
  };

  onBeginDrag(id) {
    this.setState({
      isDragging: id,
    });
  }

  onEndDrag(item) {
    this.setState({
      isDragging: null,
    });
    if (item.index !== item.originalIndex) {
      this.props.moveContent(item);
    }
  }

  handleExternalHtmlLoad = () => {
    // give some time for external CSS/JS to bootstrap to improve UX
    setTimeout(() => {
      this.setState({
        isExternalHtmlLoaded: true,
      });
    }, 500);
  };

  loadExternalPageHtmlIfNeeded = () => {
    if (this.props.currentPage && this.props.currentPage.module && !this.props.pageHtmlContent) {
      this.props.loadPageHtmlContent(this.props.currentPage.id);
    }
  };

  moveItem(dragIndex, hoverIndex) {
    if (typeof dragIndex === 'undefined') { return; }
    const { contentItems } = this.state;
    const dragItem = contentItems[dragIndex];

    this.setState((prev) => update(prev, {
      contentItems: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragItem],
        ],
      },
    }));
  }

  addItem(hoverIndex, props) {
    const newItem = { ...contentDefaultProps, ...props, dndType: DND_CONTENT_MODULE };
    this.setState(prev => update(prev, {
      contentItems: {
        $splice: [
          [hoverIndex, 0, newItem],
        ],
      },
    }));
  }

  renderItem(content, idx) {
    const isContentLocked = content.locked;
    const {
      isSuperAdmin,
      renderTarget,
    } = this.props;

    return (
      <BrowsboxContentItem
        addItem={this.addItem}
        id={content.id}
        type={content.type}
        contentItem={content}
        index={idx}
        isLocked={this.state.isDragging !== null}
        key={content.id}
        moveItem={this.moveItem}
        onBeginDrag={this.onBeginDrag}
        onEndDrag={this.onEndDrag}
        dndType={DND_CONTENT_MODULE}
        dndSource={content.dndSource}
        isContentLocked={isContentLocked}
        isContentUnlockable={isSuperAdmin}
        renderTarget={renderTarget}
        onResetContentItems={this.onResetContentItems}
      />
    );
  }

  renderEmpty() {
    return (
      <BrowsboxContentEmpty
        addItem={this.addItem}
      />
    );
  }

  renderLoader = () => (
    <div className="c-section__wrapper o-bb-content-loader">
      <div className="c-section__main  l-columns o-bb-content-loader__row">
        <div className="l-column o-bb-content-loader__row__column">
          <Loader
            height="300px"
          />
        </div>
        <div className="l-column o-bb-content-loader__row__column">
          <Loader
            height="24px"
            widthRandomness={0.1}
            count={10}
          />
        </div>
      </div>
      <div className="c-section__main  l-columns o-bb-content-loader__row">
        <div className="l-column o-bb-content-loader__row__column">
          <Loader
            height="24px"
            widthRandomness={0.1}
            count={10}
          />
        </div>
        <div className="l-column o-bb-content-loader__row__column">
          <Loader
            height="300px"
          />
        </div>
      </div>
    </div>
  );

  renderAnimatedContentItems = contentItems => (
    <TransitionGroup component={null} in={false}>
      {contentItems.map(((item, index) => (
        <Transition
          key={item.id}
          timeout={300}
        >
          {state => (
            <AnimatedItemContext.Provider
              value={{ state }}
            >
              {this.renderItem(item, index)}
            </AnimatedItemContext.Provider>
          )}
        </Transition>
      )))}
    </TransitionGroup>
  );

  render() {
    const {
      contentItems,
      isDragging,
      isExternalHtmlLoaded,
    } = this.state;
    const {
      isEmpty,
      isContentLoading,
      isModulesLoaded,
      pageHtmlContent,
      currentPage,
    } = this.props;

    // check that the current page is not a functional page (with a module)
    const isFunctionalPage = (currentPage && currentPage.module);

    if ((isContentLoading || !isModulesLoaded) || (isFunctionalPage && !pageHtmlContent)) {
      return this.renderLoader();
    }

    return (
      <div
        id="webcreatormodules"
        className={classNames(
          'c-bb-sections',
          { 'c-bb-sections--is-loading': (isFunctionalPage || isStaticPage()) && !isExternalHtmlLoaded },
          { 'c-bb-sections--is-dragging': !!isDragging },
        )}
      >
        {isFunctionalPage && (
          <ExternalHtmlContainer
            html={pageHtmlContent}
            onLoad={this.handleExternalHtmlLoad}
          />
        )}
        {isStaticPage() && (
          <ExternalHtmlContainer
            html={getInitialPageContents()}
            onLoad={this.handleExternalHtmlLoad}
          />
        )}
        { isEmpty && !isStaticPage() && this.renderEmpty() }
        { contentItems.length > 0 && this.renderAnimatedContentItems(contentItems) }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const {
    pages: {
      settings: {
        isSuperAdmin,
      },
      currentPage,
    },
    options: {
      view: {
        responsiveView: renderTarget,
      },
    },
    content: {
      isContentLoading,
      isModulesLoaded,
      pageHtmlContent,
    },
  } = state;

  return {
    isSuperAdmin,
    renderTarget,
    currentPage,
    isContentLoading,
    isModulesLoaded,
    pageHtmlContent,
  };
};

const mapDispatchToProps = {
  moveContent,
  loadPageHtmlContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(BrowsboxContentList);
