import React from 'react';
import PropTypes from 'prop-types';
import i18n from '../../internationalization/i18n';

const BlockRangePicker = ({ title, value, onChange }) => {
  const handleChange = (e) => onChange(parseInt(e.target.value, 10));

  return (
    <div className="o-bb-popup__slider">
      <span>{title}</span>
      <input type="range" min="0" max="500" step="50" value={value} onChange={handleChange} />
      <span className="o-bb-popup__percent">{value}%</span>
    </div>
  );
};

const Block = ({
  value: { top: topValue, bottom: bottomValue },
  onChange: handleChange,
  onReset: handleReset,
}) => {
  const handleTopChange = (top) => handleChange({ top, bottom: bottomValue });
  const handleBottomChange = (bottom) => handleChange({ top: topValue, bottom });
  const handleResetClick = (evt) => {
    evt.preventDefault();
    handleReset();
  };

  return (
    <div className="o-bb-section-config">
      <BlockRangePicker
        title={i18n.t('CONTENT.top')}
        value={topValue}
        onChange={handleTopChange}
      />
      <BlockRangePicker
        title={i18n.t('CONTENT.bottom')}
        value={bottomValue}
        onChange={handleBottomChange}
      />
      <div className="o-bb-popup__wrap">
        <a href="#" className="o-bb-popup__reset" onClick={handleResetClick}>
          {i18n.t('CONTENT.reset')}
        </a>
      </div>
    </div>
  );
};

Block.propTypes = {
  onChange: PropTypes.func.isRequired,
  value: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
  }).isRequired,
};

export default Block;
