import React from 'react';
import PropTypes from 'prop-types';
import { Gateway } from '../Gateway';
import { BbPopup } from '../@browsbox-ui';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';
import i18n from '../../internationalization/i18n';

const propTypes = {
  tetherTarget: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  onChangeNameClick: PropTypes.func.isRequired,
  onRemoveClick: PropTypes.func.isRequired,
};

export default function BrowsboxSavedSectionsConfigMenu({
  tetherTarget, onClose, onChangeNameClick, onRemoveClick,
}) {
  return (
    <Gateway into="popup">
      <BbPopup
        className="o-bb-popup--config"
        target={tetherTarget}
        onClose={onClose}
        onMouseLeave={onClose}
        placement="bottom"
      >
        <a className="o-bb-popup__action" onClick={onChangeNameClick}>
          <FontAwesomeIcon name="fas fa-pencil" />{i18n.t('CONTENT.changeName')}
        </a>

        <a className="o-bb-popup__action" onClick={onRemoveClick}>
          <FontAwesomeIcon name="fas fa-trash" />{i18n.t('CONTENT.delete')}
        </a>
      </BbPopup>
    </Gateway>
  );
}

BrowsboxSavedSectionsConfigMenu.propTypes = propTypes;
