import React from 'react';
import PropTypes from 'prop-types';
import { CONTENT_SECTION_TYPE } from '@liswood-tache/browsbox-static';
import ContentComponentToolbar from './ContentComponentToolbar';

const ComponentTools = ({
  componentId,
  columnId,
  contentType,
  componentType,
  disabled,
  children,
}) => {
  if (disabled || ![CONTENT_SECTION_TYPE.blockBuilder, CONTENT_SECTION_TYPE.footerBuilder].includes(contentType)) {
    return children;
  }

  return (
    <div className="c-component-tools">
      <ContentComponentToolbar
        componentId={componentId}
        columnId={columnId}
        componentType={componentType}
      />
      {children}
    </div>
  );
};

ComponentTools.propTypes = {
  contentType: PropTypes.string.isRequired,
  componentType: PropTypes.string.isRequired,
  componentId: PropTypes.number.isRequired,
  columnId: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

ComponentTools.defaultProps = {
  disabled: false,
};

export default ComponentTools;
