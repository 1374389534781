import React, { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { contentPropType } from '@liswood-tache/browsbox-static';
import FooterSection from './FooterSection';

function FooterSectionPortal({ footer }) {
  const sectionContainer = useRef(
    document.querySelector('.js-footer__editable-content'),
  );

  const [isCleared, setCleared] = useState(false);

  useEffect(() => {
    if (!sectionContainer.current) {
      return;
    }

    if (isCleared) {
      return;
    }

    sectionContainer.current.innerHTML = '';
    setCleared(true);
  }, [isCleared]);

  if (!sectionContainer.current || !isCleared) {
    return null;
  }

  return (
    createPortal(
      <FooterSection content={footer} />,
      sectionContainer.current,
    )
  );
}

FooterSectionPortal.propTypes = {
  footer: contentPropType,
};

export default FooterSectionPortal;
