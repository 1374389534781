import React from 'react';
import { useSelector } from 'react-redux';
import GeneralSettingsView from './GeneralSettingsView';

export default function GeneralSettingsContainer() {
  const isOpen = useSelector((state) => state.generalSettings.isOpen);

  if (!isOpen) {
    return null;
  }

  return (
    <GeneralSettingsView />
  );
}
