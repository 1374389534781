/* eslint-disable react/no-danger */
import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { CONTENT_COMPONENT_TYPE, ContentComponentSizeListenerContext } from '@liswood-tache/browsbox-static';
import ContentLoader from '../ContentLoader';
import { loadDynamicContent } from '../../../actions/dynamicContent';

const propTypes = {
  classes: PropTypes.string,
  containerClassName: PropTypes.string,
  type: PropTypes.string,
  loadDynamicContent: PropTypes.func.isRequired,
  url: PropTypes.string.isRequired,
  dynamicContent: PropTypes.object,
};

const defaultProps = {
  classes: '',
  containerClassName: '',
  type: CONTENT_COMPONENT_TYPE.dynamic,
  dynamicContent: {},
};

class ContentComponentDynamic extends Component {
  static contextType = ContentComponentSizeListenerContext;

  constructor(props) {
    super(props);

    this.ref = React.createRef();
  }

  componentDidMount() {
    const {
      url,
    } = this.props;

    this.props.loadDynamicContent(url);

    this.context(this.ref.current.getBoundingClientRect());
  }

  render() {
    const {
      type,
      classes,
      containerClassName,
      dynamicContent,
      url,
    } = this.props;

    const componentClasses = classNames(
      classes,
      containerClassName,
      `c-${type}`,
    );

    return (
      <div className={componentClasses} ref={this.ref}>
        { typeof dynamicContent[url] === 'undefined' || !dynamicContent[url]
          ? <ContentLoader />
          : <div dangerouslySetInnerHTML={{ __html: dynamicContent[url] }} />}
      </div>
    );
  }
}

ContentComponentDynamic.propTypes = propTypes;
ContentComponentDynamic.defaultProps = defaultProps;

const mapStateToProps = state => ({
  dynamicContent: state.content.dynamicContent,
});

const mapDispatchToProps = {
  loadDynamicContent,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ContentComponentDynamic);
