import React, { forwardRef } from 'react';
import Icon from '../Icon/Icon';

// eslint-disable-next-line no-unused-vars
const BrowsboxColumnPositionHandle = forwardRef((props, ref) => (
  <div className="l-column-config__drag-handle" ref={ref}>
    <a className="o-action-link">
      <Icon name="drag" className="fa-rotate-90" />
    </a>
  </div>
));

export default BrowsboxColumnPositionHandle;
