import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { DragLayer } from 'react-dnd';
import { connect } from 'react-redux';
import {
  DND_BUILDER_COMPONENT,
  DND_BUILDER_TRANSPOSABLE_COLUMN,
  DND_CONTENT_COMPONENT,
  DND_CONTENT_MODULE,
} from './dndTypes';

const resolveContentItemStyles = ({ width, height }) => ({
  width,
  height,
});

const OutlinePreview = ({ item, children }) => (
  <div
    className="o-bb-drag-layer__dnd-outline-preview"
    style={resolveContentItemStyles(item)}
  >
    { children }
  </div>
);

OutlinePreview.propTypes = {
  item: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

function getItemStyles(props) {
  const {
    initialOffset, currentOffset, item, itemType,
  } = props;

  if (!initialOffset || !currentOffset) {
    return {
      display: 'none',
    };
  }

  const { y, x } = currentOffset;
  let left = x;
  let top = y;

  if (itemType === DND_CONTENT_MODULE) {
    left = 0;
  }

  if (itemType === DND_CONTENT_COMPONENT) {
    top = y - (item.height * 0.5);
    left = x - (item.width * 0.5);
  }

  if (itemType === DND_BUILDER_TRANSPOSABLE_COLUMN) {
    top = y - item.height;
    left = x - (item.width * 0.5);
  }

  const transform = `translate(${left}px, ${top}px)`;

  return {
    width: '100%',
    transform,
    WebkitTransform: transform,
  };
}

@DragLayer(monitor => ({
  itemType: monitor.getItemType(),
  initialOffset: monitor.getInitialSourceClientOffset(),
  currentOffset: monitor.getSourceClientOffset(),
  isDragging: monitor.isDragging(),
  item: monitor.getItem(),
}))

class CustomDragLayer extends Component {
  static propTypes = {
    // eslint-disable-next-line react/forbid-prop-types
    item: PropTypes.PropTypes.shape({
      imageUrl: PropTypes.string,
      width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
    itemType: PropTypes.string,
    initialOffset: PropTypes.shape({ // eslint-disable-line
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    currentOffset: PropTypes.shape({ // eslint-disable-line
      x: PropTypes.number.isRequired,
      y: PropTypes.number.isRequired,
    }),
    isDragging: PropTypes.bool,
    components: PropTypes.object.isRequired,
  };

  static defaultProps = {
    itemType: '',
    isDragging: false,
    initialOffset: { x: 0, y: 0 },
    currentOffset: { x: 0, y: 0 },
    item: { imageUrl: '' },
  };

  componentDidUpdate(prevProps) {
    if (!prevProps.isDragging && this.props.isDragging) {
      this.onDragStart();
    }

    if (prevProps.isDragging && !this.props.isDragging) {
      this.onDragEnd();
    }
  }

  onDragStart = () => {
    document.body.classList.add('--disabled-selection');
  };

  onDragEnd = () => {
    // restore focus on body after dropping something to prevent double click's issues.
    document.body.click();
    document.body.classList.remove('--disabled-selection');
  };

  renderItem() {
    const { itemType, item } = this.props;

    switch (itemType) {
      case DND_CONTENT_MODULE:
        return (
          <div className="o-bb-drag-layer__dnd-content-module" />
        );
      case DND_CONTENT_COMPONENT:
        return (
          <div
            className="o-bb-drag-layer__dnd-content-component"
            style={resolveContentItemStyles(item)}
          >
            {item.imageUrl && (
              <img
                className="o-bb-drag-layer__dnd-content-component__image"
                src={item.imageUrl}
                alt="Content item"
              />
            )}
          </div>
        );
      case DND_BUILDER_TRANSPOSABLE_COLUMN:
        return (
          <OutlinePreview item={item}>
            <i className="fa-duotone fa-objects-column fa-2x" />
          </OutlinePreview>
        );
      case DND_BUILDER_COMPONENT:
        return (
          <OutlinePreview item={item}>
            <img className="o-bb-drag-layer__dnd-outline-preview__image" src={this.props.components[item.componentType].img} alt={item.componentType} />
          </OutlinePreview>
        );
      default: {
        return null;
      }
    }
  }

  render() {
    const { isDragging } = this.props;

    if (!isDragging) {
      return null;
    }

    return (
      <div className="o-bb-drag-layer o-content-dragger-preview2">
        <div style={getItemStyles(this.props)}>
          {this.renderItem()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  components: state.entities.components,
});

export default connect(mapStateToProps)(CustomDragLayer);
