import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useDragDropManager, useDrop } from 'react-dnd';
import classNames from 'classnames';
import { useDispatch } from 'react-redux';

import './ContentResizableColumnsList.scss';
import { setColumnOption } from '../../actions/contentConfig';
import { DND_BUILDER_RESIZABLE_COLUMN } from '../DragAndDrop/dndTypes';

const ContentResizeGridColumn = ({ index, canHover, onHover }) => {
  const [{ isOver }, drop] = useDrop(() => ({
    accept: DND_BUILDER_RESIZABLE_COLUMN,
    collect: (monitor) => ({
      isOver: monitor.isOver() && monitor.canDrop(),
    }),
    canDrop(item) {
      return canHover(item.id, index);
    },
    hover(item, monitor) {
      if (!monitor.canDrop()) {
        return;
      }

      onHover(item.id, index);
    },
  }));

  return (
    <div className="l-resize-grid__column" ref={drop}>
      <div className={classNames('l-resize-grid__grid-line', { 'l-resize-grid__grid-line--hovered': isOver })} />
    </div>
  );
};

ContentResizeGridColumn.propTypes = {
  index: PropTypes.number.isRequired,
  canHover: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
};

const ContentResizeGrid = ({ canHover, onHover }) => (
  <div className="l-resize-grid">
    {[...Array(13).keys()].map((key) => (
      <ContentResizeGridColumn key={key} index={key} canHover={canHover} onHover={onHover} />
    ))}
  </div>
);

ContentResizeGrid.propTypes = {
  canHover: PropTypes.func.isRequired,
  onHover: PropTypes.func.isRequired,
};

const useDragDropCollectedProps = (collect) => {
  const [collectedProps, setCollectedProps] = useState({});
  const manager = useDragDropManager();

  useEffect(() => {
    const monitor = manager.getMonitor();

    return monitor.subscribeToStateChange(() => {
      setCollectedProps(collect(monitor));
    });
  }, [manager]);

  return collectedProps;
};

const BrowsboxContentResizableColumnsList = ({
  className, contentId, columns, renderColumn, children,
}) => {
  const dispatch = useDispatch();

  const { isDragging, draggedType, draggedItem } = useDragDropCollectedProps((monitor) => ({
    isDragging: monitor.isDragging(),
    draggedType: monitor.getItemType(),
    draggedItem: monitor.getItem(),
  }));

  const isResizingColumn = useMemo(
    () => isDragging
      && draggedType === DND_BUILDER_RESIZABLE_COLUMN
      && columns.some(({ id }) => id === draggedItem?.id),
    [isDragging, draggedType, draggedItem],
  );

  // Let's resolve the boundaries of two neighboring columns into an array with two open intervals
  // describing their beginnings and ends.
  const resolveColumns = (leftColumnId) => {
    const visibleColumns = columns.filter(({ visible = true }) => visible);
    const defaultColumnSize = 12 / visibleColumns.length;

    const leftColumnIndex = visibleColumns.findIndex(({ id }) => id === leftColumnId);

    if (leftColumnIndex === false) {
      return null;
    }

    const rightColumnIndex = leftColumnIndex + 1;
    const rightColumn = visibleColumns[rightColumnIndex];

    if (rightColumn === false) {
      return null;
    }

    return [
      {
        a: visibleColumns.slice(0, leftColumnIndex)
          .reduce((value, { options: { size = defaultColumnSize } }) => value + parseInt(size, 10), 0),
        b: visibleColumns.slice(0, leftColumnIndex + 1)
          .reduce((value, { options: { size = defaultColumnSize } }) => value + parseInt(size, 10), 0),
      },
      {
        a: visibleColumns.slice(0, rightColumnIndex)
          .reduce((value, { options: { size = defaultColumnSize } }) => value + parseInt(size, 10), 0),
        b: visibleColumns.slice(0, rightColumnIndex + 1)
          .reduce((value, { options: { size = defaultColumnSize } }) => value + parseInt(size, 10), 0),
      },
      rightColumn.id,
    ];
  };

  // Let's check if the left column's new `b` endpoint (targetGridLineIndex) is still between the beginning
  // of the left column, and the end of the right column. We can't resize the left column pass its beginning, or the end
  // of the right column.
  const canHover = (leftColumnId, targetGridLineIndex) => {
    const [leftColumnInterval, rightColumnInterval] = resolveColumns(leftColumnId);

    return targetGridLineIndex > leftColumnInterval.a && targetGridLineIndex < rightColumnInterval.b;
  };

  const [hoveredEvent, setHoveredEvent] = useState([null, null]);

  // Let's determine how much to change the sizes of two neighboring columns, given
  // the left column's new `b` endpoint (targetGridLineIndex).
  useEffect(() => {
    const [leftColumnId, targetGridLineIndex] = hoveredEvent;

    if (!leftColumnId || !targetGridLineIndex) {
      return;
    }

    const [leftColumnInterval, rightColumnInterval, rightColumnId] = resolveColumns(leftColumnId);

    // The difference here can be either positive, or negative.
    // Given the positive sign, the left column's size will increase, while the right column's size will decrease.
    // If the sign is negative, the inverse will be true.
    const delta = targetGridLineIndex - leftColumnInterval.b;
    dispatch(setColumnOption({
      option: 'size',
      value: leftColumnInterval.b - leftColumnInterval.a + delta,
      contentId,
      columnId: leftColumnId,
    }));

    dispatch(setColumnOption({
      option: 'size',
      value: rightColumnInterval.b - rightColumnInterval.a + (-delta),
      contentId,
      columnId: rightColumnId,
    }));
  }, [hoveredEvent]);

  const handleOnHover = (leftColumnId, targetGridLineIndex) => {
    setHoveredEvent([leftColumnId, targetGridLineIndex]);
  };

  return (
    <main className={className}>
      {children || columns.map(renderColumn)}

      {isResizingColumn && (
        <ContentResizeGrid canHover={canHover} onHover={handleOnHover} />
      )}
    </main>
  );
};

BrowsboxContentResizableColumnsList.propTypes = {
  className: PropTypes.string,
  contentId: PropTypes.number.isRequired,
  columns: PropTypes.array.isRequired,
  renderColumn: PropTypes.func.isRequired,
};

BrowsboxContentResizableColumnsList.defaultProps = {
  className: '',
};

export default BrowsboxContentResizableColumnsList;
