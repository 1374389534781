import React, { useRef } from 'react';
import {
  BUTTON_MODE,
  containerClass,
  CONTENT_COMPONENT_TYPE,
  CONTENT_SECTION_TYPE, contentColumnPropType, contentComponentPropType, contentPropType, isVideoUrl,
  ContentComponentSizeListenerContext,
} from '@liswood-tache/browsbox-static';
import PropTypes from 'prop-types';
import { useDrag } from 'react-dnd';
import ContentComponents from './ContentComponents';
import ComponentTools from './BlockBuilderTools/ComponentTools';
import ContentInsertComponent from './BlockBuilderTools/ContentInsertComponent';
import SuperAdminInFooterBuilderOnly from './SuperAdminInFooterBuilderOnly';
import ContentComponentDropArea from './ContentComponentDropArea';
import { DND_BUILDER_COMPONENT } from '../DragAndDrop/dndTypes';
import ContentComponentDragContext from './ContentComponentDragContext';
import useShowUiControls from '../../hooks/use-show-ui-controls';

const shouldRenderComponent = (component, content) => {
  const { type } = component;
  const { options, type: contentType } = content;

  if ([CONTENT_SECTION_TYPE.blockBuilder, CONTENT_SECTION_TYPE.footerBuilder].includes(contentType)) {
    return true;
  }

  if (type === CONTENT_COMPONENT_TYPE.button) {
    return options.primaryButtonsActive && options.buttonDisplay === BUTTON_MODE.BUTTON_PER_COLUMN;
  }

  if (type === CONTENT_COMPONENT_TYPE.buttonSecondary) {
    return options.secondaryButtonsActive && options.buttonDisplay === BUTTON_MODE.BUTTON_PER_COLUMN;
  }

  return true;
};

export default function ContentComponent({
  component,
  content,
  onChange,
  type,
  column,
  renderTarget,
}) {
  const boundingClientRectRef = useRef({ width: 300, height: 100 });
  const showUiControls = useShowUiControls();

  const [, drag] = useDrag(() => ({
    type: DND_BUILDER_COMPONENT,
    item: () => {
      const { width, height } = boundingClientRectRef.current;

      return {
        columnId: column.id, componentId: component.id, componentType: component.type, width, height,
      };
    },
  }), [column, component]);

  if (!shouldRenderComponent(component, content)) {
    return null;
  }

  const handleBoundingClientReactChange = (value) => {
    boundingClientRectRef.current = value;
  };

  let componentTag = type || component.type;
  // Component type image with video url
  if (componentTag === 'image' && isVideoUrl(component.url)) {
    componentTag = 'video';
  }
  if (componentTag === 'buttonSecondary') {
    componentTag = 'button';
  }
  const Tag = ContentComponents[componentTag];

  const containerClassName = containerClass(content.type, componentTag);

  const showControls = showUiControls
    && [CONTENT_SECTION_TYPE.blockBuilder, CONTENT_SECTION_TYPE.footerBuilder].includes(content.type);

  if (Tag) {
    return (
      <>
        <SuperAdminInFooterBuilderOnly contentType={content.type}>
          {showControls && (
            <ContentComponentDropArea
              content={content}
              column={column}
              component={component}
            />
          )}
        </SuperAdminInFooterBuilderOnly>

        <ContentComponentSizeListenerContext.Provider value={handleBoundingClientReactChange}>
          <ContentComponentDragContext.Provider value={drag}>
            <ComponentTools
              key={`${component.id}_${componentTag}`}
              componentId={component.id}
              contentType={content.type}
              componentType={component.type}
              columnId={column.id}
              disabled={!showUiControls}
            >
              <Tag
                key={`${component.id}_${componentTag}`}
                onChange={onChange}
                containerClassName={containerClassName}
                renderTarget={renderTarget}
                {...component}
              />
            </ComponentTools>
          </ContentComponentDragContext.Provider>
        </ContentComponentSizeListenerContext.Provider>

        <SuperAdminInFooterBuilderOnly contentType={content.type}>
          {showControls && (
            <ContentInsertComponent
              contentType={content.type}
              contentId={content.id}
              componentId={component.id}
              columnId={column.id}
            />
          )}
        </SuperAdminInFooterBuilderOnly>
      </>
    );
  }

  return <div key={component.id}>Missing component <b>{component.type}</b></div>;
}

ContentComponent.propTypes = {
  component: contentComponentPropType.isRequired,
  content: contentPropType.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string,
  column: contentColumnPropType.isRequired,
  renderTarget: PropTypes.string.isRequired,
  index: PropTypes.number.isRequired,
};

ContentComponent.defaultProps = {
  type: '',
};
