import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { BbPopup } from '@browsbox-ui';
import { Gateway } from '../Gateway';
import BlockPicker from './BlockMarginsPicker';
import FontAwesomeIcon from '../Icon/FontAwesomeIcon';

const DEFAULT_MARGINS = { top: 100, bottom: 100 };

const paddingClassNamesToMargins = (classNames, defaultMargins = DEFAULT_MARGINS) => {
  const toNumber = (value) => parseInt(value.split('-').pop(), 10);

  return classNames
    .split(' ')
    .reduce(({ top, bottom }, className) => ({
      top: className.includes('-top-') ? toNumber(className) : top,
      bottom: className.includes('-bottom-') ? toNumber(className) : bottom,
    }), defaultMargins);
};

const marginsToPaddingClassNames = ({ top, bottom }) => {
  const classNames = [];

  // Some sections may use other margins than 100 by default
  // So we have to always add classes to avoid this issue:
  // https://browsbox.atlassian.net/browse/BB-1546
  classNames.push(`c-section--padding-top-${top}`);
  classNames.push(`c-section--padding-bottom-${bottom}`);

  return classNames.join(' ');
};

const Popup = ({
  show, marginsValues, onClose, onChange, onReset, tetherTarget,
}) => {
  if (!show) {
    return null;
  }

  return (
    <Gateway into="popup">
      <BbPopup
        placement="right"
        target={tetherTarget}
        onClose={onClose}
        closeOnClickInside={false}
        className="o-bb-popup--marginspicker o-bb-popup--config"
      >
        <BlockPicker
          value={marginsValues}
          onChange={onChange}
          onReset={onReset}
        />
      </BbPopup>
    </Gateway>
  );
};

Popup.propTypes = {
  show: PropTypes.bool.isRequired,
  marginsValues: PropTypes.shape({
    top: PropTypes.number.isRequired,
    bottom: PropTypes.number.isRequired,
  }).isRequired,
  onChange: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onReset: PropTypes.func.isRequired,
  tetherTarget: PropTypes.string.isRequired,
};

const BrowsboxContentMarginsPicker = ({
  className, children, defaultMarginsClasses, marginsClasses, onChange, setShowPopup, showPopup,
}) => {
  const defaultMargins = paddingClassNamesToMargins(defaultMarginsClasses);

  const [marginsValues, setMarginsValues] = useState(paddingClassNamesToMargins(marginsClasses, defaultMargins));

  const tetherTarget = 'content-margins-picker';

  useEffect(() => {
    setMarginsValues(paddingClassNamesToMargins(marginsClasses, defaultMargins));
  }, [marginsClasses]);

  const handleOnClick = (evt) => {
    evt.preventDefault();
    setShowPopup(!showPopup);
  };

  const handleOnChange = (newMarginsValues) => {
    onChange(marginsToPaddingClassNames(newMarginsValues));
    setMarginsValues(newMarginsValues);
  };

  const handleOnReset = () => {
    onChange(marginsToPaddingClassNames(defaultMargins));
    setMarginsValues(defaultMargins);
  };

  const handleOnClose = () => setShowPopup(false);

  return (
    <a href="#" onClick={handleOnClick} className={className} id={tetherTarget}>
      <FontAwesomeIcon name="fak fa-section-margins" />
      {children}
      <Popup
        show={showPopup}
        onChange={handleOnChange}
        onReset={handleOnReset}
        onClose={handleOnClose}
        tetherTarget={tetherTarget}
        marginsValues={marginsValues}
      />
    </a>
  );
};

BrowsboxContentMarginsPicker.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onChange: PropTypes.func.isRequired,
  marginsClasses: PropTypes.string.isRequired,
  defaultMarginsClasses: PropTypes.string,
  showPopup: PropTypes.bool.isRequired,
  setShowPopup: PropTypes.func.isRequired,
};

BrowsboxContentMarginsPicker.defaultProps = {
  className: '',
  children: null,
  defaultMarginsClasses: marginsToPaddingClassNames(DEFAULT_MARGINS),
};

export default BrowsboxContentMarginsPicker;
